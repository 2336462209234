import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import './Falls.css';
import NetworkGraph from '../charts/NetworkGraph';
import { useMediaQuery } from 'react-responsive';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';
export function Falls(props) {
    var _a;
    const [fallData, setFallData] = useState([]);
    const [fallDataLoaded, setFallDataLoaded] = useState(false);
    const [network, setNetwork] = useState(null);
    const [appliances, setAppliances] = useState(null);
    const maxWindow = 500;
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    const [nodeColors, setNodeColors] = useState([]);
    const [pie1, setPie1] = useState(null);
    const [pie2, setPie2] = useState(null);
    const [pie3, setPie3] = useState(null);
    useEffect(() => {
        async function fetchData() {
            if (startDate == null || endDate == null || endDate.diff(startDate, 'days') > maxWindow * 1.1 || endDate <= startDate) {
                return;
            }
            setSliderLocked(true);
            const res = await fetch(`/api/fall?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res
                .json()
                .then((res) => {
                setFallData(res);
            })
                .catch(err => console.error(err));
            const networkRes = await fetch(`/api/network?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceRes = await fetch(`/api/applianceLocation?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                networkRes.json().then((res) => {
                    setNetwork(res);
                }).catch(err => console.error(err)),
                applianceRes.json().then((res) => {
                    setAppliances(res);
                }).catch(err => console.error(err))
            ]);
            setFallDataLoaded(true);
            setSliderLocked(false);
        }
        fetchData();
    }, [startDate, endDate]);
    let locationData = null;
    useEffect(() => {
        if (!fallData || fallData.length === 0) {
            return;
        }
        let times = fallData.reduce((v, d) => {
            let h = moment(d.dateTime).hour().toString() + ':00';
            if (v[h] != null) {
                v[h]++;
            }
            else {
                v[h] = 1;
            }
            return v;
            //}, {} as { [key: number]: number})
        }, {});
        let locations = fallData.reduce((v, d) => {
            if (v[d.location] == null) {
                v[d.location] = 1;
            }
            else {
                v[d.location]++;
            }
            return v;
        }, {});
        locationData = locations;
        let illuminations = fallData.reduce((v, d) => {
            let level = 'Dark';
            if (d.illumination > 50) {
                level = 'Dim';
            }
            if (d.illumination > 200) {
                level = 'Bright';
            }
            if (v[level] == null) {
                v[level] = 1;
            }
            else {
                v[level]++;
            }
            return v;
        }, {});
        let timeArray = Object.keys(times).map(i => {
            return { id: i, label: i, value: times[i] };
        });
        let locationArray = Object.keys(locations).map(i => {
            return { id: i, label: i, value: locations[i] };
        });
        let illuminationArray = Object.keys(illuminations).map(i => {
            return { id: i, label: i, value: illuminations[i] };
        });
        let colourScheme = ["#61cdbb", "#f47560"];
        let illuminanceColorScheme = ['#007D87', '#5ECBF4', '#FF7800'];
        timeArray.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            return 1;
        });
        setPie1(React.createElement(ResponsivePie, { data: timeArray, colors: [
                '#f8b195',
                '#ea9c97',
                '#d58b99',
                '#bb7d9a',
                '#9c7298',
                '#7a6791',
                '#575e86',
                '#355375',
            ], margin: { top: 30, right: 20, bottom: 30, left: 20 }, innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableSlicesLabels: false }));
        setPie2(React.createElement(ResponsivePie, { data: locationArray, margin: { top: 30, right: 20, bottom: 30, left: 20 }, innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableSlicesLabels: false, colors: nodeColors.length === 0 ? undefined : (x) => {
                let index = nodeColors.findIndex((y) => y[0] === x.label);
                if (index !== -1) {
                    return nodeColors[index][1];
                }
                else {
                    return '';
                }
            } }));
        setPie3(React.createElement(ResponsivePie, { data: illuminationArray, colors: (d) => {
                if (d.id === 'Dark') {
                    return '#355375';
                }
                else if (d.id === 'Dim') {
                    return '#c06c84';
                }
                else if (d.id === 'Bright') {
                    return '#f8b195';
                }
                return 'red';
            }, margin: { top: 30, right: 20, bottom: 30, left: 20 }, innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableSlicesLabels: false }));
    }, [fallData, nodeColors]);
    useEffect(() => {
        console.error(nodeColors);
    }, [nodeColors]);
    return (React.createElement("div", { className: "dashboard-falls" },
        React.createElement("div", { className: "dashboardFalls__header shadow corners" },
            React.createElement("div", { className: "dashboardFalls__titleTextContainer" },
                React.createElement("h1", null, "Falls")),
            React.createElement("div", { className: "dashboardFalls__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 20, maximumDays: maxWindow, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardFalls__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardFalls__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "dashboardFalls__content" },
            React.createElement("div", { className: "dashboardFalls__networkContainer corners shadow" },
                React.createElement(NetworkGraph, { acsis: props.acsisID, data: network, applianceData: appliances, width: 451, height: 200, fallData: locationData, setNodeColors: setNodeColors })),
            React.createElement("div", { className: "dashboardFalls__roomPieContainer corners shadow", style: { position: 'relative' } },
                React.createElement("h3", { style: { marginLeft: 15 } }, "Fall timings"),
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', top: 0, display: 'flex', alignItems: 'center' } }, fallData.length === 0 && fallDataLoaded ?
                    React.createElement("h3", { style: { textAnchor: 'middle', marginLeft: 'auto', marginRight: 'auto' } }, "There is no data available for the selected window")
                    :
                        React.createElement(React.Fragment, null, pie1))),
            React.createElement("div", { className: "dashboardFalls__timePieContainer corners shadow", style: { position: 'relative' } },
                React.createElement("h3", { style: { marginLeft: 15 } }, "Fall locations"),
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', top: 0, display: 'flex', alignItems: 'center' } }, fallData.length === 0 && fallDataLoaded ?
                    React.createElement("h3", { style: { textAnchor: 'middle', marginLeft: 'auto', marginRight: 'auto' } }, "There is no data available for the selected window")
                    :
                        React.createElement(React.Fragment, null, pie2))),
            React.createElement("div", { className: "dashboardFalls__lightPieContainer corners shadow", style: { position: 'relative' } },
                React.createElement("h3", { style: { marginLeft: 15 } }, "Fall light levels"),
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', top: 0, display: 'flex', alignItems: 'center' } }, fallData.length === 0 && fallDataLoaded ?
                    React.createElement("h3", { style: { textAnchor: 'middle', marginLeft: 'auto', marginRight: 'auto' } }, "There is no data available for the selected window")
                    :
                        React.createElement(React.Fragment, null, pie3))))));
}
export default Falls;
