import * as React from 'react';
import { ImageCircle } from './ImageCircle';
import './UserTile.css';
import { navigate } from "@reach/router";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useState, useEffect } from 'react';
import { Link } from "@reach/router";
import './ImageCircle.css';
;
function starUser(acsis, star, setStarred) {
    localStorage.setItem("star-" + acsis, JSON.stringify({ star }));
    setStarred(star);
}
function isUserStarred(acsis) {
    let user = localStorage.getItem("star-" + acsis);
    if (user) {
        let userObj = JSON.parse(user);
        return userObj.star;
    }
    return false;
}
function UserTile(props) {
    const [starred, setStarred] = useState();
    useEffect(() => {
        setStarred(isUserStarred(props.acsis));
    });
    function handleClick(e) {
        e.preventDefault();
        navigate(`dashboard/${props.acsis}`);
    }
    return (React.createElement("div", { className: "user-tile shadow", style: { position: 'relative' }, onClick: handleClick },
        starred ?
            React.createElement(StarIcon, { style: { cursor: 'pointer' }, onClickCapture: (e) => { e.preventDefault(); e.stopPropagation(); starUser(props.acsis, false, setStarred); }, className: "user-tile__star-selected" }) :
            React.createElement(StarBorderIcon, { style: { cursor: 'pointer' }, onClickCapture: (e) => { e.preventDefault(); e.stopPropagation(); starUser(props.acsis, true, setStarred); }, className: "user-tile__star-unselected" }),
        React.createElement(ImageCircle, { imageURL: props.imageURL, altText: props.fullName, colour: props.colour }),
        React.createElement("div", { className: "user-tile__details" },
            React.createElement("h1", { className: "user-tile__heading" }, props.fullName),
            React.createElement("p", null,
                React.createElement("span", null, props.gender),
                React.createElement("br", null),
                React.createElement("span", null,
                    props.age,
                    " years old"),
                React.createElement("br", null),
                React.createElement("br", null)),
            React.createElement("p", null,
                React.createElement("span", { className: "user-tile__acsis" }, props.acsis)))));
}
export function AddUserTile(props) {
    return (React.createElement(Link, { to: `/referral`, className: "link-text-decoration" },
        React.createElement("div", { className: "user-tile shadow", style: { position: 'relative' } },
            React.createElement("div", { className: 'image-circle__wrapper' },
                React.createElement("img", { className: `image-circle__image image-circle__wrapper--border-grey`, src: process.env.PUBLIC_URL + "/UserImages/add-user-solid.svg", alt: "add user tile icon" })),
            React.createElement("div", { className: "user-tile__details" },
                React.createElement("h1", { className: "user-tile__heading", style: { textAnchor: 'middle', textAlign: 'center', textDecoration: 'none' } }, `Refer A New Resident`)))));
}
export default UserTile;
