import moment from 'moment';
export function lowerSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, upperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) {
    if (event.x + minimumSpanInPixels >= WIDTH) {
        // If position is at, or beyond, the left bound of the slider frame, do nothing
        return;
    }
    else if (event.x <= 0) {
        // If position is at, or beyond, the right bound of the slider frame, do nothing
        return;
    }
    let upperSliderPosPixels = x(upperSliderPos.toDate());
    // Resize and re-center the selected window indicator bar
    selectedIndicator.attr('width', upperSliderPosPixels - event.x);
    selectedIndicator.attr('x', event.x);
    if (event.x > upperSliderPosPixels - minimumSpanInPixels) {
        // If we are within the minimum span, then push the upper slider
        selectedIndicator.attr('width', minimumSpanInPixels);
        selectedIndicator.attr('x', event.x);
        lowerSlider.attr('cx', event.x);
        upperSlider.attr('cx', event.x + minimumSpanInPixels);
        return;
    }
    else if (event.x < upperSliderPosPixels - maximumSpanInPixels) {
        // If we are outisde the maximum span, then pull the upper slider
        selectedIndicator.attr('width', maximumSpanInPixels);
        selectedIndicator.attr('x', event.x);
        lowerSlider.attr('cx', event.x);
        upperSlider.attr('cx', event.x + maximumSpanInPixels);
        return;
    }
    else {
        // Just move the slider
        lowerSlider.attr('cx', event.x);
        return;
    }
}
export function lowerSliderDragEnd(event, x, lowerSlider, setLowerSliderPos, upperSliderPos, setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) {
    if (event.x + minimumSpanInPixels >= WIDTH) {
        // Prevent spilling off the right
        setLowerSliderPos(moment(x.invert(WIDTH - minimumSpanInPixels)));
        setUpperSliderPos(moment(x.invert(WIDTH)));
        return;
    }
    else if (event.x <= 0) {
        // Prevent spilling off the right
        setLowerSliderPos(moment(x.invert(0)));
        if (maximumSpanInPixels < x(upperSliderPos.toDate())) {
            // If the upper slider has been dragged at all, set its value
            setUpperSliderPos(moment(x.invert(maximumSpanInPixels)));
            ;
        }
        return;
    }
    else if (event.x < x(upperSliderPos.toDate()) - maximumSpanInPixels) {
        // If the upper slider has been dragged and event.x is not 0, set its value
        setLowerSliderPos(moment(x.invert(event.x)));
        setUpperSliderPos(moment(x.invert(event.x + maximumSpanInPixels)));
        return;
    }
    else if (event.x > x(upperSliderPos.toDate()) - minimumSpanInPixels) {
        // Push the upper slider if required by the defined minimum span
        setLowerSliderPos(moment(x.invert(event.x)));
        setUpperSliderPos(moment(x.invert(event.x + minimumSpanInPixels)));
        return;
    }
    else {
        // Just move the slider
        setLowerSliderPos(moment(x.invert(event.x)));
        return;
    }
}
export function upperSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, lowerSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) {
    if (event.x - minimumSpanInPixels <= 0) {
        // If position is at, or beyond, the left bound of the slider frame, do nothing
        return;
    }
    else if (event.x >= WIDTH) {
        // If position is at, or beyond, the right bound of the slider frame, do nothing
        return;
    }
    // Resize and re-center the selected window indicator bar
    selectedIndicator.attr('width', event.x - x(lowerSliderPos.toDate()));
    if (event.x - minimumSpanInPixels < x(lowerSliderPos.toDate())) {
        // If we are within the minimum span, then push the upper slider
        selectedIndicator.attr('width', minimumSpanInPixels);
        selectedIndicator.attr('x', event.x - minimumSpanInPixels);
        upperSlider.attr('cx', event.x);
        lowerSlider.attr('cx', event.x - minimumSpanInPixels);
    }
    else if (event.x - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
        // If we are outisde the maximum span, then pull the upper slider
        selectedIndicator.attr('width', maximumSpanInPixels);
        selectedIndicator.attr('x', event.x - maximumSpanInPixels);
        lowerSlider.attr('cx', event.x - maximumSpanInPixels);
        upperSlider.attr('cx', event.x);
        return;
    }
    else {
        // Just move the slider
        upperSlider.attr('cx', event.x);
    }
}
export function upperSliderDragEnd(event, x, upperSlider, setLowerSliderPos, lowerSliderPos, setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) {
    if (event.x < minimumSpanInPixels) {
        // Prevent the slider form falling off the edge of the slider after release
        setLowerSliderPos(moment(x.invert(0)));
        setUpperSliderPos(moment(x.invert(minimumSpanInPixels)));
        return;
    }
    else if (event.x >= WIDTH) {
        // Prevent spilling off the right
        setUpperSliderPos(moment(x.invert(WIDTH)));
        if (WIDTH - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
            // If the lower slider has been dragged at all, set its value
            setLowerSliderPos(moment(x.invert(WIDTH - maximumSpanInPixels)));
        }
        return;
    }
    else if (event.x - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
        // If the lower slider has been dragged and event.x is not WIDTH, set its value
        setLowerSliderPos(moment(x.invert(event.x - maximumSpanInPixels)));
        setUpperSliderPos(moment(x.invert(event.x)));
    }
    else if (event.x - minimumSpanInPixels < x(lowerSliderPos)) {
        // Push the lower slider if required by the defined minimum span
        setLowerSliderPos(moment(x.invert(event.x - minimumSpanInPixels)));
        // setLowerSliderPos(moment(x.invert(-100)))
        setUpperSliderPos(moment(x.invert(event.x)));
        return;
    }
    else {
        // Just move the slider
        setUpperSliderPos(moment(x.invert(event.x)));
    }
}
