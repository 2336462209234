import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveStream } from '@nivo/stream';
import moment from "moment";
import './Sleep.css';
import '../charts/GanttChart.css';
import "../charts/CommonGraph.css";
import Modal from 'react-modal';
import { GanttChart } from '../charts/GanttChart';
import { useMediaQuery } from 'react-responsive';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';
const maximumDays = 30;
export function GanttWrapper(props) {
    const [hasError, setErrors] = useState(false);
    const [mobility, setMobility] = useState(null);
    const [light, setLight] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [appliance, setAppliance] = useState(null);
    const [door, setDoor] = useState(null);
    const [network, setNetwork] = useState(null);
    const [toggles, setToggles] = useState({});
    const [applianceLocations, setApplianceLocations] = useState(null);
    const [timeFrame, setTimeFrame] = useState({ startDate: moment(props.target).add(1, 'day').subtract(12, 'hours'), endDate: moment(props.target).add(1, 'day').add(12, 'hours') });
    useEffect(() => {
        async function fetchData() {
            let fixedStart = moment(timeFrame.startDate);
            let fixedEnd = moment(timeFrame.endDate);
            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const lightRes = fetch(`/api/light?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}}`);
            const temperatureRes = fetch(`/api/temperature?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const doorRes = fetch(`/api/door?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`);
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`);
            Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes) => {
                    setMobility(mobilityRes);
                }).catch(err => setErrors(err)),
                lightRes.then(res => res.json()).then((lightRes) => {
                    setLight(lightRes);
                }).catch(err => setErrors(err)),
                temperatureRes.then(res => res.json()).then((temperatureRes) => {
                    setTemperature(temperatureRes);
                }).catch(err => setErrors(err)),
                applianceRes.then(res => res.json()).then((applianceRes) => {
                    setAppliance(applianceRes);
                }).catch(err => setErrors(err)),
                doorRes.then(res => res.json()).then((doorRes) => {
                    setDoor(doorRes);
                }).catch(err => setErrors(err)),
                networkRes.then(res => res.json()).then((res) => {
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceLocRes.then(res => res.json()).then((res) => {
                    setApplianceLocations(res);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, [timeFrame]);
    return React.createElement(GanttChart, { live: false, setLive: () => { }, toggles: toggles, timeFrame: timeFrame, setTimeFrame: setTimeFrame, data: mobility, lightData: light, temperatureData: temperature, applianceData: appliance, doorData: door, network: network, applianceLocations: applianceLocations, width: 1600, height: 800 });
}
export function ContextModal(props) {
    return (React.createElement(Modal, { style: { overlay: { backgroundColor: 'rgba(200, 200, 200, 0.75)' }, content: { inset: '100px', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset' } }, isOpen: props.target != null, contentLabel: "Event context", onRequestClose: () => { props.setTarget(null); } },
        React.createElement("h1", null, "Sleep data for selected day"),
        React.createElement("h2", null, "Surrounding activity"),
        props.target != null && React.createElement(GanttWrapper, { acsisID: props.acsis, target: props.target })));
}
export function Sleep(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [sleep, setSleep] = useState([]);
    const [dayAndNight, setDayAndNight] = useState(null);
    const [modalTarget, setModalTarget] = useState(null);
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    useEffect(() => {
        async function fetchData() {
            if (startDate === null || endDate === null || endDate.diff(startDate, 'days') > maximumDays * 1.1 || startDate >= endDate) {
                console.warn('CAUGHT');
                return;
            }
            setSliderLocked(true);
            const res = await fetch(`/api/sleep?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } }); //&acsis=${props.acsisID}`);
            res
                .json()
                .then((res) => {
                for (var i = 0; i < res.length; i++) {
                    res[i].sleepDay = Math.round((res[i].sleepDay / 60) / 6) / 10;
                    res[i].sleepNight = Math.round((res[i].sleepNight / 60) / 6) / 10;
                }
                setSleep(res);
            })
                .catch(err => setErrors(err));
            const res2 = await fetch(`/api/dayAndNightActivity?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } }); //&acsis=${props.acsisID}`);
            res2
                .json()
                .then((res) => {
                let mappedRes = res[0].data.map((d, i) => {
                    return {
                        "Time spent active": d[0].value,
                        "Room transitions": d[1].value,
                        "Span of rooms visited": d[2].value,
                        "Door usage": d[3].value
                    };
                });
                setDayAndNight(mappedRes);
            })
                .catch(err => setErrors(err));
            setSliderLocked(false);
        }
        fetchData();
    }, [startDate, endDate]);
    let graphData = null;
    let pieChart = null;
    let areaChart = null;
    let total = [];
    let min, max, avg;
    if (sleep && sleep.length > 0) {
        let pieData = [];
        let keys = [];
        if (sleep[0].sleepDay != null && sleep[0].sleepNight != null) {
            keys.push("sleepNight", "sleepDay");
            let awakeCount = sleep.reduce((v, d) => v + d.sleepDay, 0);
            if (awakeCount > 0) {
                pieData.push({
                    id: "Day",
                    label: "Day",
                    value: Math.round(awakeCount),
                    color: "hsl(119, 70%, 50%)"
                });
            }
            let asleepCount = sleep.reduce((v, d) => v + d.sleepNight, 0);
            if (asleepCount > 0) {
                pieData.push({
                    id: "Night",
                    label: "Night",
                    value: Math.round(asleepCount),
                    color: "hsl(193, 70%, 50%)"
                });
            }
            total = sleep.map(d => d.sleepDay + d.sleepNight);
        }
        min = Math.round(total.reduce((v, d) => d < v ? d : v, total[0]));
        max = Math.round(total.reduce((v, d) => d > v ? d : v, total[0]));
        avg = Math.round(total.reduce((v, d) => d + v, 0) / total.length * 10) / 10;
        let colourScheme = ['#355375', "#f8b195"];
        pieChart = React.createElement(ResponsivePie, { data: pieData, margin: { top: 50, right: 30, bottom: 50, left: 30 }, colors: [colourScheme[1], colourScheme[0]], innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableRadialLabels: false, enableSlicesLabels: false, legends: [{
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                },
                {
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                }] });
        graphData = React.createElement(ResponsiveBar, { data: sleep, indexBy: "dateTime", keys: keys, labelTextColor: (d) => {
                if (d.color !== "#f8b195") {
                    return 'white';
                }
                else {
                    return 'black';
                }
            }, margin: { top: 20, right: 20, bottom: 50, left: 50 }, axisBottom: { legend: "Date", legendOffset: 40, format: d => moment(d).format('DD/MM'), tickRotation: -50, legendPosition: "middle" }, axisLeft: { legend: "Hours slept", legendOffset: -30 }, colors: colourScheme, onClick: d => setModalTarget(d.data.dateTime), tooltip: d => (React.createElement("span", null, (d.id == 'sleepDay' ? 'Day' : 'Night') + ': ' + d.value + ' hours')) });
    }
    if (dayAndNight != null && dayAndNight != []) {
        areaChart = React.createElement(ResponsiveStream, { data: dayAndNight, keys: ['Time spent active', 'Room transitions', 'Span of rooms visited', 'Door usage'], axisBottom: {
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                format: (d) => ((d % 3) == 0 ? moment(startDate).set("hour", 0).set("minute", 0).set("second", 0).add(d * 20, 'minute').format('HH:mm') : '')
            }, offsetType: "none", axisLeft: { orient: 'left', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: -40 }, legends: [
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 100,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#999999',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000000'
                            }
                        }
                    ]
                }
            ], margin: { top: 10, right: 150, bottom: 20, left: 25 } });
    }
    return (React.createElement("div", { className: "dashboard-toilet-frequency" },
        React.createElement("div", { className: "dashboardSleep__header shadow corners" },
            React.createElement("div", { className: "dashboardSleep__titleTextContainer" },
                React.createElement("h1", null, "Sleep")),
            React.createElement("div", { className: "dashboardSleep__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 15, maximumDays: maximumDays, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardSleep__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardSleep__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "dashboard-toilet-frequency__content", style: { height: "500px" } },
            React.createElement("div", { className: "dashboard-toilet-frequency__barchart shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, sleep.length > 0 ?
                        React.createElement(React.Fragment, null, graphData)
                        :
                            React.createElement("span", null, "There is no data available for the selected time period")))),
            React.createElement("div", { className: "dashboard-toilet-frequency__piechart shadow corners" },
                React.createElement("span", { style: { fontSize: '120%' } }, "Sleep distrubution"),
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, sleep.length > 0 ?
                        React.createElement(React.Fragment, null, pieChart)
                        :
                            React.createElement("span", null, "There is no data available for the selected time period")))),
            React.createElement("div", { className: "dashboard-sleep__stats-panel shadow corners" }, sleep.length > 0 ?
                React.createElement("span", null,
                    "Least hours slept: ",
                    min,
                    React.createElement("br", null),
                    "Average hours slept: ",
                    avg,
                    React.createElement("br", null),
                    "Most hours slept: ",
                    max)
                : null)),
        React.createElement(ContextModal, { setTarget: setModalTarget, target: modalTarget, acsis: props.acsisID })));
}
export default Sleep;
