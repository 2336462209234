import { Router, navigate, Link } from "@reach/router";
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Coronavirus } from './Coronavirus';
import { DashboardHome } from './dashboardPages/DashboardHome';
import { ImageCircle } from './ImageCircle';
import { Mobility } from './dashboardPages/Mobility';
import { Network } from './dashboardPages/Network';
import { Sleep } from './dashboardPages/Sleep';
import { Medication } from './dashboardPages/Medication';
import './ServiceUserDashboard.css';
import { MobilityIndex } from "./dashboardPages/MobilityIndex";
import ToiletFrequency from "./dashboardPages/ToiletFrequency";
import WalkingWithPurpose from "./dashboardPages/WalkingWithPurpose";
import Falls from "./dashboardPages/Falls";
import { Temperature } from "./dashboardPages/Temperature";
import { Light } from "./dashboardPages/Light";
import { ServiceUserSettings } from "./dashboardPages/ServiceUserSettings";
import moment from "moment";
import { authProvider } from '../authProvider';
import { HubConnectionBuilder } from '@aspnet/signalr';
import ServiceUserSummary from './ServiceUserSummary/summary';
export function ServiceUserDashboard(props) {
    const [hubConnection, setHubConnection] = useState();
    const [tiles, setTiles] = useState(null);
    const [hasError, setErrors] = useState(false);
    const [serviceUserDetails, setServiceUserDetails] = useState({});
    const [sliderDataMobilityIndex, setSliderDataMobilityIndex] = useState(null);
    const [sliderDataSleep, setSliderDataSleep] = useState(null);
    const [sliderDataWalkingWithPurpose, setSliderDataWalkingwithpurpose] = useState(null);
    const [sliderDataFalls, setSliderDataFalls] = useState(null);
    const [sliderDataToilet, setSliderDataToilet] = useState(null);
    const [sliderDataMobility, setSliderDataMobility] = useState(null);
    const [sliderDataMedication, setSliderDataMedication] = useState(null);
    const [riskScoreInfo, setRiskScoreInfo] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/serviceUsers/` + props.acsisID, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res
                .json()
                .then((res) => {
                setServiceUserDetails(res);
            })
                .catch(err => setErrors(err));
            const res2 = await fetch(`/api/serviceUser?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res2
                .json()
                .then((res) => {
                setTiles(res);
            })
                .catch(err => setErrors(err));
        }
        fetchData();
        const createHubConnection = async () => {
            const accessToken = (await authProvider.getAccessToken()).accessToken;
            const hubConnect = new HubConnectionBuilder()
                .withUrl('/event', {
                accessTokenFactory: () => accessToken
            })
                .build();
            try {
                await hubConnect.start();
                console.log("Connected to hub");
                if (props.acsisID == null) {
                    console.warn('Acsis ID is null :(');
                    return;
                }
                //await hubConnect.invoke('AddToGroup', 'raw');
                //                await hubConnect.invoke('AddToGroup', '4334943');
                await hubConnect.invoke('AddToGroup', props.acsisID.toString());
                console.log(`Joined group "${props.acsisID.toString()}"`);
                hubConnect.on('Send', (name, message) => {
                    let mess = JSON.parse(message);
                    if (mess.RecordType == 'TileDataRecord') {
                        // setTiles(mess);
                        // todo: sort this - there is a casing issue between data received from the API su history and the signal R message
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
            setHubConnection(hubConnect);
        };
        createHubConnection();
    }, []);
    useEffect(() => {
        async function fetchData() {
            const riskScoreRes = await fetch(`/api/serviceUser/${props.acsisID}/riskScore`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                riskScoreRes.json().then((riskScoreRes) => {
                    if (riskScoreRes.scores == null) {
                        console.log("Editable Risk Score");
                    }
                    else {
                        console.log("Risk scores have been found, not editable");
                        setRiskScoreInfo({ scores: riskScoreRes.scores, totalScore: riskScoreRes.riskScore });
                    }
                }).catch(err => {
                    setErrors(err);
                })
            ]);
        }
        fetchData();
    }, []);
    useEffect(() => {
        async function fetchSliderData() {
            console.log('Fetching slider data');
            const res = await fetch('/api/SliderDataAvailability?acsis=' + props.acsisID, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res.json().then((res) => {
                console.log('DataAvailability res unmarhsalled, setting state for each viz');
                let index = res.availabilityRecords.findIndex(x => x.key === 'MobilityIndex');
                if (index !== -1) {
                    setSliderDataMobilityIndex({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Sleep');
                if (index !== -1) {
                    setSliderDataSleep({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'WalkingWithPurpose');
                if (index !== -1) {
                    setSliderDataWalkingwithpurpose({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Falls');
                if (index !== -1) {
                    setSliderDataFalls({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Toilet');
                if (index !== -1) {
                    setSliderDataToilet({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Mobility');
                if (index !== -1) {
                    setSliderDataMobility({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Medication');
                if (index !== -1) {
                    setSliderDataMedication({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        fetchSliderData();
    }, []);
    if (props.acsisID == null) {
        return (React.createElement("h2", null, "Sorry, but you need to provide an ACSIS ID to view a dashboard."));
    }
    let address1 = '';
    address1 += serviceUserDetails.streetNumber != null ? serviceUserDetails.streetNumber + ' ' : '';
    address1 += serviceUserDetails.street != null ? serviceUserDetails.street : '';
    address1 += address1 != '' ? ',' : '';
    let address2 = '';
    address2 += serviceUserDetails.town != null ? serviceUserDetails.town + ' ' : '';
    address2 += address2 != '' ? ',' : '';
    let address3 = '';
    address3 += serviceUserDetails.postcode != null ? serviceUserDetails.postcode : '';
    function navigateToSettings() {
        navigate(`/dashboard/${props.acsisID}/settings`);
    }
    return (React.createElement("div", { className: "service-user-dashboard" },
        React.createElement("div", { className: "service-user-dashboard__sidebar shadow corners" },
            React.createElement("div", { className: "service-user-dashboard__sidebarHeader" },
                React.createElement("div", { className: "service-user-dashboard__sidebarHeaderImageContainer" },
                    React.createElement(ImageCircle, { acsisID: props.acsisID, imageURL: process.env.PUBLIC_URL + "/UserImages/" + (serviceUserDetails.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg'), altText: serviceUserDetails.firstName + ' ' + serviceUserDetails.LastName, colour: tiles != null && tiles.tiles ? tiles.tiles.serviceUser.colour : null, size: 75 })),
                React.createElement("div", { className: "service-user-dashboard__sidebarHeaderTextContainer" },
                    React.createElement("p", null,
                        React.createElement("span", { style: { marginBottom: 5 }, className: "service-user-dashboard__sidebarTextHeader" }, serviceUserDetails.firstName + ' ' + serviceUserDetails.lastName),
                        React.createElement("br", null),
                        React.createElement("span", { className: "service-user-dashboard__sidebarTextBody" }, (serviceUserDetails.gender == 'F' ? 'Female' : (serviceUserDetails.gender == 'M' ? 'Male' : 'Unspecified'))),
                        React.createElement("br", null),
                        React.createElement("span", { className: "service-user-dashboard__sidebarTextBody" },
                            moment().diff(serviceUserDetails.dateOfBirth, 'years'),
                            " years old"),
                        React.createElement("br", null),
                        React.createElement("span", { className: "service-user-dashboard__sidebarTextBody" }, serviceUserDetails.phoneNumber != null ? handlePhoneNumber(serviceUserDetails.phoneNumber) : 'Phone number not provided')))),
            React.createElement("div", { className: "service-user-dashboard__sidebarBody" },
                React.createElement("div", { className: "service-user-dashboard__sidebarSpacer" }),
                React.createElement(ServiceUserSummary, { data: riskScoreInfo }),
                React.createElement("div", { className: "service-user-dashboard__sidebarSpacer" })),
            React.createElement("div", { className: "service-user-dashboard__sidebarFooter" },
                React.createElement("p", { style: { paddingLeft: '7.5%' } },
                    React.createElement("span", { className: "service-user-dashboard__sidebarTextSubHeader" }, "Acsis ID: "),
                    React.createElement("span", { className: "service-user-dashboard__sidebarTextBody" }, props.acsisID),
                    " ",
                    React.createElement("br", null),
                    React.createElement(Link, { to: `/dashboard/${props.acsisID}/settings`, style: { textDecoration: 'none', color: 'black' } },
                        React.createElement("span", { className: "service-user-dashboard__sidebarTextSubHeader", style: { cursor: 'pointer' } }, "Settings"))))),
        React.createElement("div", { className: "service-user-dashboard__content" },
            React.createElement(Router, null,
                React.createElement(DashboardHome, { path: "/", acsis: props.acsisID, tiles: tiles, name: serviceUserDetails.firstName + ' ' + serviceUserDetails.lastName, gender: (serviceUserDetails.gender == 'F' ? 'Female' : 'Male') }),
                React.createElement(Mobility, { path: "mobility", acsisID: props.acsisID, sliderData: sliderDataMobility }),
                React.createElement(Network, { path: "network", acsisID: props.acsisID }),
                React.createElement(Sleep, { path: "sleep", acsisID: props.acsisID, sliderData: sliderDataSleep }),
                React.createElement(MobilityIndex, { path: "mobilityIndex", acsisID: props.acsisID, sliderData: sliderDataMobilityIndex }),
                React.createElement(ToiletFrequency, { path: "toiletFrequency", acsisID: props.acsisID, sliderData: sliderDataToilet }),
                React.createElement(WalkingWithPurpose, { path: "walk", acsisID: props.acsisID, sliderData: sliderDataWalkingWithPurpose }),
                React.createElement(Falls, { path: "falls", acsisID: props.acsisID, sliderData: sliderDataFalls }),
                React.createElement(Medication, { path: "medication", acsisID: props.acsisID, sliderData: sliderDataMedication }),
                React.createElement(Temperature, { path: "temperature", acsisID: props.acsisID, sliderData: sliderDataMobility }),
                React.createElement(Light, { path: "light", acsisID: props.acsisID, sliderData: sliderDataMobility }),
                React.createElement(ServiceUserSettings, { path: "settings/*", acsisID: props.acsisID }),
                React.createElement(Coronavirus, { path: "coronavirus" })))));
}
function handlePhoneNumber(number) {
    let x = String(number);
    let xSplit = x.split('');
    if (xSplit[0] === '0') {
        xSplit[0] = '+44 ';
    }
    return xSplit.join('');
}
export default ServiceUserDashboard;
