import * as React from 'react';
import UserTile, { AddUserTile } from './UserTile';
import './ServiceUsers.css';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { authProvider } from '../authProvider';
export function ServiceUsers(props) {
    const [hasError, setErrors] = useState(false);
    const [userItems, setUserItems] = useState(null);
    const [showWatchlist, setShowWatchlist] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/serviceUsers`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res
                .json()
                .then(async (res) => {
                // todo: segregate after faker is gone - this is butters
                let serviceUserColours = await (await fetch(`/api/serviceUsersColour`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } })).json();
                let mappedRes = res.filter(v => {
                    let search = v.acsis.toString().includes(searchTerm) || (v.firstName + ' ' + v.lastName).toLowerCase().includes(searchTerm.toLowerCase());
                    let watched = false;
                    let watchStore = localStorage.getItem('star-' + v.acsis);
                    if (watchStore != null) {
                        let watch = JSON.parse(watchStore);
                        watched = watch.star;
                    }
                    return search && (!showWatchlist || watched);
                }).map((value, i) => {
                    let index = serviceUserColours.findIndex((x) => x.acsis === value.acsis);
                    let colour = null;
                    if (index != -1) {
                        colour = serviceUserColours[index].serviceUser.colour;
                    }
                    //return <UserTile key={value.acsis} imageURL={process.env.PUBLIC_URL + "/UserImages/" + (value.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg')} fullName={value.firstName.split(' ')[0] + ' ' + value.lastName} gender={(value.gender == 'F' ? 'Female' : (value.gender == 'M' ? 'Male' : 'Unspecified'))} age={moment().diff(value.dateOfBirth, 'years')} acsis={value.acsis} status={1} />
                    return React.createElement(UserTile, { key: value.acsis, imageURL: process.env.PUBLIC_URL + "/UserImages/" + (value.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg'), fullName: value.firstName + ' ' + value.lastName, gender: (value.gender == 'F' ? 'Female' : (value.gender == 'M' ? 'Male' : 'Unspecified')), age: moment().diff(value.dateOfBirth, 'years'), acsis: value.acsis, colour: colour });
                    // return <UserTile key={intVal} imageURL={process.env.PUBLIC_URL + "/UserImages/user000" + Math.floor(10 + Math.random() * 89).toString() + ".256.jpeg"} fullName="John Smith" gender={(Math.random() > 0.5 ? "Male" : "Female")} age={Math.floor(60 + Math.random() * 40)} acsis={intVal} status={Math.floor(Math.random() * 4)} />
                });
                mappedRes.unshift(React.createElement(AddUserTile, null));
                setUserItems(mappedRes);
            })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [searchTerm, showWatchlist]);
    let updateSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    return (React.createElement("div", { className: "service-users" },
        React.createElement("div", { className: "service-users__filters" },
            React.createElement("h2", null,
                React.createElement("span", { style: { cursor: 'pointer' }, className: showWatchlist ? '' : 'selected', onClick: () => setShowWatchlist(false) }, "All"),
                " | ",
                React.createElement("span", { style: { cursor: 'pointer' }, className: showWatchlist ? 'selected' : '', onClick: () => setShowWatchlist(true) }, "Watchlist")),
            React.createElement("div", null,
                React.createElement("h2", { style: { display: "inline" } }, "Search "),
                React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement("input", { className: "service-users__search-input shadow", value: searchTerm, onInput: updateSearch })))),
        React.createElement("div", { className: "service-users__grid" }, userItems)));
}
export default ServiceUsers;
// TODO: Look at making the search div a label
