import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './DateSelectionSlider.css';
export function DateSelector(props) {
    const invalidColor = 'red';
    const validColor = '#333333';
    let [year, setYear] = useState('YYYY');
    let [yearValidity, setYearValidity] = useState(true);
    let [month, setMonth] = useState('MM');
    let [monthValidity, setMonthValidity] = useState(true);
    let [day, setDay] = useState('DD');
    let [dayValidity, setDayValidity] = useState(true);
    let [hour, setHour] = useState('hh');
    let [hourValidity, setHourValidity] = useState(true);
    let [minute, setMinute] = useState('mm');
    let [minuteValidity, setMinuteValidity] = useState(true);
    useEffect(() => {
        // '2019-12-05T08:17:52.830Z'
        let dateTimeString = props.sliderPos.toISOString();
        setYear(dateTimeString.slice(0, 4));
        setMonth(dateTimeString.slice(5, 7));
        setDay(dateTimeString.slice(8, 10));
        setHour(dateTimeString.slice(11, 13));
        setMinute(dateTimeString.slice(14, 16));
    }, [props.sliderPos]);
    return (React.createElement(React.Fragment, null, props.dataInputObject !== null ?
        React.createElement("form", { className: "dateSelectorContainer" },
            React.createElement("div", { className: "dateSelector__internalFrame", style: { marginRight: '15px' } }, props.label),
            React.createElement("div", { className: "dateSelector__internalFrame" },
                React.createElement("input", { className: "dateSelector__entryBox dateSelector__4Wide", type: "text", name: "year", value: year, onKeyUp: (event) => { enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, props.dataInputObject, props.setSliderPos, props.pingUpdate, props.pingCounter); }, style: {
                        borderColor: (yearValidity === true ? validColor : invalidColor)
                    }, onChange: (event) => { setYear(event.target.value); } }),
                React.createElement("label", { className: "dateSelector__label" }, "YYYY")),
            React.createElement("div", { className: "dateSelector__internalFrame" },
                React.createElement("input", { className: "dateSelector__entryBox dateSelector__2Wide", type: "text", name: "month", value: month, onKeyUp: (event) => { enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, props.dataInputObject, props.setSliderPos, props.pingUpdate, props.pingCounter); }, style: {
                        borderColor: (monthValidity === true ? validColor : invalidColor)
                    }, onChange: (event) => { setMonth(event.target.value); } }),
                React.createElement("label", { className: "dateSelector__label" }, "MM")),
            React.createElement("div", { className: "dateSelector__internalFrame", style: { marginRight: '15px' } },
                React.createElement("input", { className: "dateSelector__entryBox dateSelector__2Wide", type: "text", name: "day", value: day, onKeyUp: (event) => { enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, props.dataInputObject, props.setSliderPos, props.pingUpdate, props.pingCounter); }, style: {
                        borderColor: (dayValidity === true ? validColor : invalidColor)
                    }, onChange: (event) => { setDay(event.target.value); } }),
                React.createElement("label", { className: "dateSelector__label" }, "DD")),
            React.createElement("div", { className: "dateSelector__internalFrame" },
                React.createElement("input", { className: "dateSelector__entryBox dateSelector__2Wide", type: "text", name: "hour", value: hour, onKeyUp: (event) => { enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, props.dataInputObject, props.setSliderPos, props.pingUpdate, props.pingCounter); }, style: {
                        borderColor: (hourValidity === true ? validColor : invalidColor)
                    }, onChange: (event) => { setHour(event.target.value); } }),
                React.createElement("label", { className: "dateSelector__label" }, "hh")),
            React.createElement("div", { className: "dateSelector__internalFrame" },
                React.createElement("input", { className: "dateSelector__entryBox dateSelector__2Wide", type: "text", name: "minute", value: minute, onKeyUp: (event) => { enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, props.dataInputObject, props.setSliderPos, props.pingUpdate, props.pingCounter); }, style: {
                        borderColor: (minuteValidity === true ? validColor : invalidColor)
                    }, onChange: (event) => { setMinute(event.target.value); } }),
                React.createElement("label", { className: "dateSelector__label" }, "mm")))
        :
            null));
}
function enterHandler(event, year, month, day, hour, minute, setYearValidity, setMonthValidity, setDayValidity, setHourValidity, setMinuteValidity, dataInputObject, setSliderPos, pingSlider, pingCounter) {
    // '2019-12-05T08:17:52.830Z'
    if (event.keyCode === 13) {
        let bork = false;
        if (year.length < 4) {
            setYearValidity(false);
            bork = true;
        }
        else {
            setYearValidity(true);
        }
        ;
        if (month.length < 2) {
            setMonthValidity(false);
            bork = true;
        }
        else {
            setMonthValidity(true);
        }
        ;
        if (day.length < 2) {
            setDayValidity(false);
            bork = true;
        }
        else {
            setDayValidity(true);
        }
        ;
        if (hour.length < 2) {
            setHourValidity(false);
            bork = true;
        }
        else {
            setHourValidity(true);
        }
        ;
        if (minute.length < 2) {
            setMinuteValidity(false);
            bork = true;
        }
        else {
            setMinuteValidity(true);
        }
        ;
        if (bork === true) {
            return;
        }
        let datestring = `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;
        let dateMoment;
        try {
            dateMoment = moment(datestring);
        }
        catch (_a) {
            setYearValidity(false);
            setMonthValidity(false);
            setDayValidity(false);
            setHourValidity(false);
            setMinuteValidity(false);
            console.log('ERROR CONVERTING TO MOMENT OBJECT');
            return;
        }
        if (dateMoment.toISOString() === null) {
            setYearValidity(false);
            setMonthValidity(false);
            setDayValidity(false);
            setHourValidity(false);
            setMinuteValidity(false);
            console.log('MOMENT IS NULL');
            return;
        }
        let startMoment = moment(dataInputObject.globalMinDate);
        let endMoment;
        if (dataInputObject.globalMaxDate === null) {
            endMoment = moment();
        }
        else {
            endMoment = moment(dataInputObject.globalMaxDate);
        }
        ;
        if (dateMoment < startMoment || dateMoment > endMoment) {
            setYearValidity(false);
            setMonthValidity(false);
            setDayValidity(false);
            setHourValidity(false);
            setMinuteValidity(false);
            console.log('MOMENT IS OUT OF BOUNDS');
            return;
        }
        setSliderPos(dateMoment);
        pingSlider(pingCounter + 1);
    }
}
export default DateSelector;
