import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authProvider } from '../../../authProvider';
import './ruleConfigWindow.css';
import { DropDownOpenArrow, DropDownCloseCross } from './svg';
import moment from 'moment';
export function RuleConfigWindow(props) {
    const [isLoaded, setLoaded] = useState(false);
    const [sendChanges, setSendChanges] = useState(false);
    const [medicationTabs, setMedicationTabs] = useState([]);
    const [fallTabs, setFallTabs] = useState([]);
    const [sleepTabs, setSleepTabs] = useState([]);
    const [toiletTabs, setToiletTabs] = useState([]);
    const [mobilityTabs, setMobilityTabs] = useState([]);
    const [ruleCatalogue, setRuleCatalogue] = useState([]);
    const [ruleArray, setRuleArray] = useState([]);
    const [medicationWindows, setMedicationWindowArray] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);
    const [sandboxRule, setSandboxRule] = useState(null);
    const [password, setPassword] = useState('');
    const [confimModalVisible, setConfirmModalVisible] = useState(false);
    useEffect(() => {
        async function updateRule() {
            if (sendChanges === false) {
                return;
            }
            setSelectedRule(null);
            setSandboxRule(null);
            await fetch('/api/rulerecord', {
                method: 'POST',
                body: JSON.stringify({
                    ACSIS: parseInt('' + props.acsis),
                    rulePayload: sandboxRule
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken
                }
            }).then(res => {
                if (res.status === 403) {
                    toast.error("You are not authorised to perform a rule update");
                }
            });
            setLoaded(false);
            setSendChanges(false);
        }
        updateRule();
    }, [sendChanges]);
    useEffect(() => {
        async function loadData() {
            if (isLoaded) {
                return;
            }
            let temp;
            const catalogueFetch = fetch(`/api/rulecatalogue?acsis=${props.acsis}`);
            const ruleFetch = fetch(`/api/rulerecord?acsis=${props.acsis}`);
            await ruleFetch.then((res) => res.json()).then((objTemp) => {
                if (objTemp && objTemp.ruleArray) {
                    setRuleArray(objTemp.ruleArray);
                    temp = objTemp.ruleArray;
                }
            });
            await catalogueFetch.then((res) => res.json()).then((objTemp) => {
                if (objTemp && objTemp.ruleArray) {
                    setRuleCatalogue(objTemp.ruleArray);
                    let ruleCatalogueTemp = objTemp.ruleArray;
                    let medicationTemp = [];
                    let medicationArray = [];
                    let fallTemp = [];
                    let sleepTemp = [];
                    let toiletTemp = [];
                    let mobilityTemp = [];
                    ruleCatalogueTemp.forEach((record) => {
                        if (record.ruleCategory && record.ruleCategory == "Medication" && record.ruleID && record.ruleID > 4010) {
                            medicationTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        if (record.ruleCategory && record.ruleCategory == "Medication" && record.ruleID && record.ruleID <= 4010) {
                            medicationArray.push(record);
                        }
                        if (record.ruleID === 4001) {
                            medicationTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        if (record.ruleCategory && record.ruleCategory == "Toilet") {
                            toiletTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        if (record.ruleCategory && record.ruleCategory == "Fall") {
                            fallTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        if (record.ruleCategory && record.ruleCategory == "Sleep") {
                            sleepTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        if (record.ruleCategory && record.ruleCategory == "Mobility") {
                            mobilityTemp.push(React.createElement(RuleOption, { rule: record, ruleArray: temp ? temp : [], setSelectedRule: setSelectedRule, setSandboxRule: setSandboxRule, selectedRule: selectedRule }));
                        }
                        setMedicationTabs(medicationTemp);
                        setFallTabs(fallTemp);
                        setSleepTabs(sleepTemp);
                        setToiletTabs(toiletTemp);
                        setMedicationWindowArray(medicationArray);
                        setMobilityTabs(mobilityTemp);
                    });
                }
            });
            setLoaded(true);
        }
        loadData();
    }, [isLoaded]);
    function RuleMenu() {
        const [isAltered, setIsAltered] = useState(false);
        const [startValidity, setStartValidity] = useState(true);
        const [endValidity, setEndValidity] = useState(true);
        useEffect(() => {
            if (JSON.stringify(sandboxRule) !== JSON.stringify(selectedRule)) {
                setIsAltered(true);
            }
            else {
                setIsAltered(false);
            }
        }, [sandboxRule, selectedRule]);
        if (selectedRule === null || sandboxRule === null) {
            return (React.createElement("div", null));
        }
        let buttonStyleSave = isAltered === true ? { opacity: 1, cursor: 'pointer' } : { opacity: 0.5 };
        let buttonStyleReset = isAltered === true ? { opacity: 1, cursor: 'pointer' } : { opacity: 0.5 };
        let reqArray = [];
        sandboxRule.ruleRequirements.forEach(req => {
            reqArray.push(React.createElement("li", null, req));
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "ruleConfig__ruleMenuLeft" },
                React.createElement("div", null,
                    React.createElement("h2", null, sandboxRule.ruleName),
                    React.createElement("h3", null, sandboxRule.ruleDescription)),
                React.createElement("div", null,
                    React.createElement("h3", null,
                        React.createElement("b", null, "Rule requirements:"),
                        React.createElement("br", null),
                        React.createElement("ul", null, reqArray)))),
            React.createElement("div", { className: "ruleConfig__ruleMenuRight" },
                React.createElement("div", { className: "ruleConfig__rowToggle" },
                    React.createElement("h3", null,
                        React.createElement("b", null, "Rule Active")),
                    React.createElement("div", { className: "ruleConfig__slider", style: sandboxRule.active === true ? { backgroundColor: '#0a6ebd' } : {}, onMouseUp: () => {
                            let temp = JSON.parse(JSON.stringify(sandboxRule)); //'#ce2029'
                            temp.active = !temp.active;
                            setSandboxRule(temp);
                        } },
                        React.createElement("div", { className: "ruleConfig__sliderTab", style: sandboxRule.active === true ? { marginLeft: 'auto' } : {} }))),
                React.createElement("div", { className: "ruleConfig__rowToggle" },
                    React.createElement("h3", null,
                        React.createElement("b", null, "Alert CBU")),
                    React.createElement("div", { className: "ruleConfig__slider", style: JSON.stringify(sandboxRule.response).includes('alertCBU') ? { backgroundColor: '#0a6ebd' } : {}, onMouseUp: () => {
                            let temp = JSON.parse(JSON.stringify(sandboxRule));
                            if (JSON.stringify(sandboxRule.response).includes('alertCBU')) {
                                temp.response = temp.response.filter((x) => x != 'alertCBU');
                            }
                            else {
                                temp.response.push('alertCBU');
                            }
                            setSandboxRule(temp);
                        } },
                        React.createElement("div", { className: "ruleConfig__sliderTab", style: JSON.stringify(sandboxRule.response).includes('alertCBU') ? { marginLeft: 'auto' } : {} }))),
                React.createElement("div", { className: "ruleConfig__activateBarButton", style: buttonStyleReset, onMouseUp: () => {
                        setSandboxRule(selectedRule);
                    } },
                    React.createElement("h2", null, "Undo")),
                React.createElement("div", { className: "ruleConfig__activateBarButton", style: buttonStyleSave, onMouseUp: () => {
                        if (isAltered) {
                            setSendChanges(true);
                        }
                    } },
                    React.createElement("h2", null, "Confirm")))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ruleConfig__container" },
            React.createElement("div", { className: "ruleConfig__ruleSelectionContainer corners shadow" }, isLoaded ? (React.createElement(React.Fragment, null,
                React.createElement(RuleCollapse, { name: "Medication", closeMenu: sendChanges }, medicationTabs),
                React.createElement(RuleCollapse, { name: "Toilet", closeMenu: sendChanges }, toiletTabs),
                React.createElement(RuleCollapse, { name: "Fall", closeMenu: sendChanges }, fallTabs),
                React.createElement(RuleCollapse, { name: "Sleep", closeMenu: sendChanges }, sleepTabs),
                React.createElement(RuleCollapse, { name: "Mobility", closeMenu: sendChanges }, mobilityTabs))) : React.createElement("h3", { className: "ruleConfig__centralTextLoading" }, "Loading")),
            React.createElement("div", { className: "ruleConfig__ruleMenu corners shadow" }, selectedRule === null ? (React.createElement("h3", { className: "ruleConfig__centralText" }, "To configure this service users active alerts, select a rule from the menu on the left")) : (React.createElement(React.Fragment, null, selectedRule != null && selectedRule.ruleID != 4001 ? (React.createElement(RuleMenu, null)) : (React.createElement(MedicationWindowConfiguration, { setSandboxRule: setSandboxRule, medicationWindowArray: medicationWindows, setMasterSandbox: setSandboxRule, sendChanges: setSendChanges, ruleArray: ruleArray }))))))));
}
function MedicationWindowConfiguration(props) {
    const [mw4010, setmw4010] = useState(null);
    const [mw4001, setmw4001] = useState(null);
    const [mw4002, setmw4002] = useState(null);
    const [mw4003, setmw4003] = useState(null);
    const [mw4004, setmw4004] = useState(null);
    const [mw4005, setmw4005] = useState(null);
    const [mw4006, setmw4006] = useState(null);
    const [mw4007, setmw4007] = useState(null);
    const [mw4008, setmw4008] = useState(null);
    const [mw4009, setmw4009] = useState(null);
    const [sb4010, setsb4010] = useState(null);
    const [sb4001, setsb4001] = useState(null);
    const [sb4002, setsb4002] = useState(null);
    const [sb4003, setsb4003] = useState(null);
    const [sb4004, setsb4004] = useState(null);
    const [sb4005, setsb4005] = useState(null);
    const [sb4006, setsb4006] = useState(null);
    const [sb4007, setsb4007] = useState(null);
    const [sb4008, setsb4008] = useState(null);
    const [sb4009, setsb4009] = useState(null);
    useEffect(() => {
        if (!props.medicationWindowArray) {
            return;
        }
        let i = props.medicationWindowArray.findIndex(x => x.ruleID === 4010);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4010);
            if (i2 == -1) {
                setsb4010(props.medicationWindowArray[i]);
                setmw4010(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4010(temp);
                setmw4010(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4001);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4001);
            if (i2 == -1) {
                setsb4001(props.medicationWindowArray[i]);
                setmw4001(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4001(temp);
                setmw4001(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4002);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4002);
            if (i2 == -1) {
                setsb4002(props.medicationWindowArray[i]);
                setmw4002(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4002(temp);
                setmw4002(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4003);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4003);
            if (i2 == -1) {
                setsb4003(props.medicationWindowArray[i]);
                setmw4003(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4003(temp);
                setmw4003(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4004);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4004);
            if (i2 == -1) {
                setsb4004(props.medicationWindowArray[i]);
                setmw4004(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4004(temp);
                setmw4004(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4005);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4005);
            if (i2 == -1) {
                setsb4005(props.medicationWindowArray[i]);
                setmw4005(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4005(temp);
                setmw4005(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4006);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4006);
            if (i2 == -1) {
                setsb4006(props.medicationWindowArray[i]);
                setmw4006(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4006(temp);
                setmw4006(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4007);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4007);
            if (i2 == -1) {
                setsb4007(props.medicationWindowArray[i]);
                setmw4007(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4007(temp);
                setmw4007(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4008);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4008);
            if (i2 == -1) {
                setsb4008(props.medicationWindowArray[i]);
                setmw4008(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4008(temp);
                setmw4008(temp);
            }
        }
        i = props.medicationWindowArray.findIndex(x => x.ruleID === 4009);
        if (i != -1) {
            let i2 = props.ruleArray.findIndex(x => x.ruleID === 4009);
            if (i2 == -1) {
                setsb4009(props.medicationWindowArray[i]);
                setmw4009(props.medicationWindowArray[i]);
            }
            else {
                let temp = props.ruleArray[i2];
                setsb4009(temp);
                setmw4009(temp);
            }
        }
    }, [props.medicationWindowArray, props.ruleArray]);
    let timingArray = [mw4001, mw4002, mw4003, mw4004, mw4005, mw4006, mw4007, mw4008, mw4009, mw4010];
    return (React.createElement("div", { className: "ruleConfig__medicationConfigWindowContainer" },
        React.createElement(MedicationWindowChild, { setSandbox: setsb4001, sandbox: sb4001, mwStatic: mw4001, index: 1, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4002, sandbox: sb4002, mwStatic: mw4002, index: 2, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4003, sandbox: sb4003, mwStatic: mw4003, index: 3, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4004, sandbox: sb4004, mwStatic: mw4004, index: 4, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4005, sandbox: sb4005, mwStatic: mw4005, index: 5, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4006, sandbox: sb4006, mwStatic: mw4006, index: 6, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4007, sandbox: sb4007, mwStatic: mw4007, index: 7, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4008, sandbox: sb4008, mwStatic: mw4008, index: 8, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4009, sandbox: sb4009, mwStatic: mw4009, index: 9, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray }),
        React.createElement("div", { className: "ruleConfig__SpacerLine" }),
        React.createElement(MedicationWindowChild, { setSandbox: setsb4010, sandbox: sb4010, mwStatic: mw4010, index: 10, setMasterSandbox: props.setMasterSandbox, sendChanges: props.sendChanges, timingArray: timingArray })));
}
function MedicationWindowChild(props) {
    var _a, _b, _c, _d;
    const [startTime, setStartTime] = useState('00:00:00');
    const [endTime, setEndTime] = useState('00:00:00');
    const [isInitial, setIsInitial] = useState(true);
    const [validity, setValidity] = useState(true);
    const [isAltered, setAltered] = useState(false);
    const [timeInitiated, setTimeInitiated] = useState(false);
    useEffect(() => {
        if (props.sandbox && !timeInitiated) {
            setStartTime(props.sandbox.startTime);
            setEndTime(props.sandbox.endTime);
            setTimeInitiated(true);
        }
        else {
            console.log('Unable to set default times for rules');
        }
    }, [props.sandbox]);
    useEffect(() => {
        if (props.sandbox == null) {
            return;
        }
        if (props.sandbox.startTime == null || props.sandbox.endTime == null || isInitial) {
            return;
        }
        let temp = JSON.parse(JSON.stringify(props.sandbox));
        temp.startTime = startTime;
        temp.endTime = endTime;
        props.setSandbox(temp);
    }, [startTime, endTime]);
    useEffect(() => {
        if (JSON.stringify(props.sandbox) != JSON.stringify(props.mwStatic)) {
            setAltered(true);
        }
        else {
            setAltered(false);
        }
    }, [props.sandbox, props.mwStatic]);
    if (props.mwStatic != null) {
        return (React.createElement("div", { className: "ruleConfig__medicationConfigOptionContainer" },
            React.createElement("div", { className: "ruleConfig__medicationConfigOptionTitle" },
                React.createElement("h3", { style: ((_a = props.sandbox) === null || _a === void 0 ? void 0 : _a.active) ? { opacity: 1 } : { opacity: 0.5 } },
                    React.createElement("b", null, `Medication Window ${props.index}`))),
            React.createElement("div", { className: "ruleConfig__medicationConfigOptionInputs", style: ((_b = props.sandbox) === null || _b === void 0 ? void 0 : _b.active) ? { opacity: 1 } : { opacity: 0.5 } },
                React.createElement("div", { className: "ruleConfig__dateSelectorRow" },
                    React.createElement("h3", null, "Start time:"),
                    React.createElement("select", { className: "ruleConfig__dateSelectorEntryBox", style: validity ? {} : { backgroundColor: 'red' }, onChange: (event) => {
                            let temp = startTime.split(':');
                            if (temp[0]) {
                                temp[0] = event.target.value;
                            }
                            setIsInitial(false);
                            setStartTime(temp.join(':'));
                        }, value: startTime.split(':')[0] }, function fillOptions() {
                        let options = [];
                        for (let i = 0; i < 24; i++) {
                            let key = '' + i;
                            if (key.length === 1) {
                                key = '0' + key;
                            }
                            options.push(React.createElement("option", { value: key }, key));
                        }
                        return options;
                    }()),
                    React.createElement("h3", null, ":"),
                    React.createElement("select", { className: "ruleConfig__dateSelectorEntryBox", style: validity ? {} : { backgroundColor: 'red' }, onChange: (event) => {
                            let temp = startTime.split(':');
                            if (temp[1]) {
                                temp[1] = event.target.value;
                            }
                            setStartTime(temp.join(':'));
                            setIsInitial(false);
                        }, value: startTime.split(':')[1] }, function fillOptions() {
                        let options = [];
                        for (let i = 0; i < 60; i++) {
                            let key = '' + i;
                            if (key.length === 1) {
                                key = '0' + key;
                            }
                            options.push(React.createElement("option", { value: key }, key));
                        }
                        return options;
                    }())),
                React.createElement("div", { className: "ruleConfig__dateSelectorRow" },
                    React.createElement("h3", null, "End time:"),
                    React.createElement("select", { className: "ruleConfig__dateSelectorEntryBox", style: validity ? {} : { backgroundColor: 'red' }, onChange: (event) => {
                            let temp = endTime.split(':');
                            if (temp[0]) {
                                temp[0] = event.target.value;
                            }
                            setEndTime(temp.join(':'));
                            setIsInitial(false);
                        }, value: endTime.split(':')[0] }, function fillOptions() {
                        let options = [];
                        for (let i = 0; i < 24; i++) {
                            let key = '' + i;
                            if (key.length === 1) {
                                key = '0' + key;
                            }
                            options.push(React.createElement("option", { value: key }, key));
                        }
                        return options;
                    }()),
                    React.createElement("h3", null, ":"),
                    React.createElement("select", { className: "ruleConfig__dateSelectorEntryBox", style: validity ? {} : { backgroundColor: 'red' }, onChange: (event) => {
                            let temp = endTime.split(':');
                            if (temp[1]) {
                                temp[1] = event.target.value;
                            }
                            setEndTime(temp.join(':'));
                            setIsInitial(false);
                        }, value: endTime.split(':')[1] }, function fillOptions() {
                        let options = [];
                        for (let i = 0; i < 60; i++) {
                            let key = '' + i;
                            if (key.length === 1) {
                                key = '0' + key;
                            }
                            options.push(React.createElement("option", { value: key }, key));
                        }
                        return options;
                    }()))),
            React.createElement("div", { className: "ruleConfig__medicationConfigOptionButtonContainer" },
                React.createElement("div", { className: "ruleConfig__medicationConfigButton", style: ((_c = props.sandbox) === null || _c === void 0 ? void 0 : _c.active) ? { cursor: 'pointer', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' } : { cursor: 'pointer', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'flex-start' }, onMouseUp: () => {
                        if (props.sandbox == null) {
                            return;
                        }
                        let temp = JSON.parse(JSON.stringify(props.sandbox));
                        temp.active = !temp.active;
                        props.setSandbox(temp);
                    } },
                    React.createElement("div", { className: "ruleConfig__activateSlider", style: ((_d = props.sandbox) === null || _d === void 0 ? void 0 : _d.active) ? { backgroundColor: 'green' } : { backgroundColor: '#ce2029' } })),
                React.createElement("div", { className: "ruleConfig__medicationConfigButton", style: isAltered ? { cursor: 'pointer' } : { opacity: 0.2 }, onMouseUp: () => {
                        var _a, _b;
                        if (isAltered) {
                            if (props.mwStatic) {
                                props.setSandbox(props.mwStatic);
                                setStartTime((_a = props.mwStatic) === null || _a === void 0 ? void 0 : _a.startTime);
                                setEndTime((_b = props.mwStatic) === null || _b === void 0 ? void 0 : _b.endTime);
                            }
                        }
                    } },
                    React.createElement("h3", null, "Undo")),
                React.createElement("div", { className: "ruleConfig__medicationConfigButton", style: isAltered ? { cursor: 'pointer' } : { opacity: 0.2 }, onMouseUp: () => {
                        const format = 'HH:mm:ss';
                        if (props.sandbox && props.sandbox.startTime && props.sandbox.endTime) {
                            if (moment(props.sandbox.startTime, format) >= moment(props.sandbox.endTime, format)) {
                                console.log('INVALID');
                                toast.error('Medication timings are in the wrong order');
                                return;
                            }
                            let valid = true;
                            for (let rule of props.timingArray) {
                                if (!rule || rule.ruleID === props.sandbox.ruleID || !rule.active) {
                                    continue;
                                }
                                if (((moment(props.sandbox.startTime, format)) >= moment(rule.startTime, format) && moment(props.sandbox.startTime, format) <= moment(rule.endTime, format))
                                    || ((moment(props.sandbox.endTime, format)) >= moment(rule.startTime, format) && moment(props.sandbox.endTime, format) <= moment(rule.endTime, format))) {
                                    console.log('This medicationm window sits within another');
                                    toast.error('This medication window overlaps an existing window');
                                    valid = false;
                                }
                            }
                            if (!valid) {
                                return;
                            }
                            console.log('Rule change initiated');
                            toast.success('Rule submitted');
                            props.setMasterSandbox(props.sandbox);
                            props.sendChanges(true);
                        }
                    } },
                    React.createElement("h3", null, "Confirm")))));
    }
    else {
        return (React.createElement("div", null, JSON.stringify(props.mwStatic)));
    }
}
function RuleCollapse(props) {
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        if (props.closeMenu === true) {
            setOpen(false);
        }
    }, [props.closeMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ruleConfig__collapseMenuTile", onMouseUp: () => { setOpen(!isOpen); } },
            React.createElement("h3", { className: "ruleConfig__collapseMenuText" }, props.name),
            isOpen ? React.createElement(DropDownCloseCross, { className: "ruleConfig__collapseMenuIcon" }) : React.createElement(DropDownOpenArrow, { className: "ruleConfig__collapseMenuIcon" })),
        isOpen ? React.createElement(React.Fragment, null,
            " ",
            props.children,
            " ") : null));
}
function RuleOption(props) {
    const [isActive, setActive] = useState(false);
    useEffect(() => {
        let index = props.ruleArray.findIndex(rule => rule.ruleID === props.rule.ruleID);
        if (index != -1) {
            setActive(props.ruleArray[index].active);
        }
        ;
        if (props.rule.ruleID === 4001) {
            for (let rule of props.ruleArray) {
                if (rule.ruleID > 4000 && rule.ruleID <= 4010 && rule.active) {
                    setActive(true);
                }
            }
        }
    }, [props.ruleArray, props.rule]);
    return (React.createElement("div", { className: "ruleConfig__optionTile", onMouseUp: () => {
            let index = props.ruleArray.findIndex(rule => rule.ruleID === props.rule.ruleID);
            if (index === -1) {
                props.setSandboxRule(props.rule);
                props.setSelectedRule(props.rule);
            }
            else {
                props.setSandboxRule(props.ruleArray[index]);
                props.setSelectedRule(props.ruleArray[index]);
            }
        } },
        React.createElement("div", { className: "ruleConfig__optionIndicator", style: isActive ? { backgroundColor: 'green' } : { backgroundColor: '#ce2029' } }),
        React.createElement("span", { className: "ruleConfig__optionText" }, props.rule.ruleName),
        React.createElement("div", { className: "ruleConfig__selectionIndicator" })));
}
export default RuleConfigWindow;
