import * as React from 'react';
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { Tableau10 } from "../../utils/colours";
import './GanttChart.css';
import "./CommonGraph.css";
export function StackedAreaChart(props) {
    const d3Container = useRef(null);
    useEffect(() => {
        if (props.data && d3Container.current) {
            const svg = d3.select(d3Container.current);
            const margin = { left: 100, top: 10, right: 10, bottom: 20 };
            const width = 1600;
            const height = 800;
            let series = d3.stack().keys(props.locations)(props.data);
            let colour = d3.scaleOrdinal()
                .domain(props.locations)
                .range(Tableau10);
            let x = d3.scaleUtc()
                .domain(d3.extent(props.dates.map(d => new Date(d)), d => d))
                .range([margin.left, width - margin.right]);
            let y = d3.scaleLinear()
                .domain([0, d3.max(series, d => d3.max(d, d => d[1]))]).nice()
                .range([height - margin.bottom, margin.top]);
            let area = d3.area()
                .x((d) => x(new Date(d.data.date)))
                .y0(d => y(d[0]))
                .y1(d => y(d[1]));
            let xAxis = (g) => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
            let yAxis = (g) => g
                .attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y))
                .call((g) => g.select(".domain").remove())
                .call((g) => g.select(".tick:last-of-type text").clone()
                .attr("x", 3)
                .attr("text-anchor", "start")
                .attr("font-weight", "bold")
                .text("Confirmed cases"));
            let tip = d3.select(".graph-tooltip");
            svg.append("g")
                .selectAll("path")
                .data(series)
                .join("path")
                .attr("fill", ({ key }) => colour(key))
                .attr("d", area)
                .on("mouseover", (event, d) => {
            })
                .on("mousemove", (event, d) => {
                tip.html(JSON.stringify(d, null, "  "));
            })
                .on("mouseleave", (event, d) => {
            })
                .append("title")
                .text(({ key }) => key);
            svg.append("g")
                .call(xAxis);
            svg.append("g")
                .call(yAxis);
        }
    }, [props.data, d3Container.current]);
    if (props.data == null) {
        return (React.createElement("div", { className: "stacked-area-chart-info" },
            React.createElement("p", null, "Loading...")));
    }
    else if (props.data == []) {
        return (React.createElement("div", { className: "stacked-area-chart-info" },
            React.createElement("p", null, "No data found for the provided timeframe.")));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "graph-tooltip" }),
            React.createElement("svg", { className: "stacked-area-chart", viewBox: "0 0 1600 800", ref: d3Container })));
    }
}
export default StackedAreaChart;
