import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { rollup } from "d3-array";
import moment from "moment";
import { MedicationChart } from '../charts/MedicationChart';
import './Medication.css';
import { useMediaQuery } from 'react-responsive';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';
export function Medication(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [medication, setMedication] = useState([]);
    const [medicationWindow, setMedicationWindow] = useState({ windows: [] });
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    const maxWindow = 30;
    useEffect(() => {
        async function fetchData() {
            if (startDate == null || endDate == null || endDate.diff(startDate, 'days') > maxWindow * 1.1 || endDate <= startDate) {
                return;
            }
            const medicationRes = await fetch(`/api/medication?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const medicationWindowRes = await fetch(`/api/serviceUser/${props.acsisID}/medicationWindow?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                medicationRes.json().then((medicationRes) => {
                    setMedication(medicationRes);
                }).catch(err => setErrors(err)),
                medicationWindowRes.json().then((medicationWindowRes) => {
                    setMedicationWindow(medicationWindowRes);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, [startDate, endDate]);
    let colourScheme = ["#80b918", "#faa307", "#dc2f02"];
    let graphData = null;
    let pieChart = null;
    let barChart = null;
    if (medication && medication.length > 0) {
        // TODO: Look into why this could have happened that we got a null, we may need to look at DateTime instead or filter the SQL more strictly
        let processedMedication = medication.filter(d => d.startDateTime != null).map(d => {
            d.date = d.startDateTime.split('T')[0];
            d.time = d.startDateTime.split('T')[1].replace('Z', '');
            d.inWindow = false;
            for (let window of medicationWindow.windows) {
                if (d.time >= window.startTime && d.time <= window.endTime) {
                    d.inWindow = true;
                }
            }
            return d;
        });
        //@ts-ignore
        let barDataMap = rollup(processedMedication, v => v.length, d => d.date, d => d.inWindow);
        let barData = [];
        let tickCount = 0;
        barDataMap.forEach((d, k) => {
            let inWindow = d.has(true) ? d.get(true) : 0;
            let outOfWindow = d.has(false) ? d.get(false) : 0;
            //  Get the number of not taken medications using the previous values, clamped at 0
            let notTaken = Math.max(0, (medicationWindow.windows.length - inWindow - outOfWindow));
            barData.push({
                date: k,
                "Accessed during window": inWindow,
                "Accessed outside window": outOfWindow,
                "Not accessed": notTaken
            });
            tickCount = Math.max(tickCount, inWindow + outOfWindow + notTaken + 1);
        });
        let pieData = processedMedication.reduce((acc, d) => {
            if (d.inWindow) {
                acc.inWindow++;
            }
            else {
                acc.outOfWindow++;
            }
            return acc;
        }, {
            inWindow: 0,
            outOfWindow: 0,
            notTaken: 0
        });
        let days = endDate.diff(startDate, 'days');
        let totalWindows = days * medicationWindow.windows.length;
        pieData.notTaken = Math.max(0, (totalWindows - pieData.inWindow - pieData.outOfWindow));
        graphData = React.createElement(MedicationChart, { data: processedMedication, windowData: medicationWindow.windows, height: 100, width: 200, acsis: props.acsisID });
        let pieDataArr = [
            {
                id: "Accessed during window",
                label: "Accessed during window",
                value: pieData.inWindow,
                color: "#00FF00"
            }, {
                id: "Accessed outside window",
                label: "Accessed outside window",
                value: pieData.outOfWindow,
                color: "#FFFF00"
            }, {
                id: "Not accessed",
                label: "Not accessed",
                value: pieData.notTaken,
                color: "#FF0000"
            },
        ];
        let colors = {
            "Accessed during window": "#3A7D44",
            "Accessed outside window": "#ff9900",
            "Not accessed": "#A22C29",
        };
        pieChart = React.createElement(ResponsivePie, { data: pieDataArr, margin: { top: 20, right: 20, bottom: 50, left: 20 }, colors: d => colors[d.id], innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableRadialLabels: true, enableSlicesLabels: false });
        barChart = React.createElement(ResponsiveBar, { data: barData, indexBy: "date", keys: ["Accessed during window", "Accessed outside window", "Not accessed"], margin: { top: 20, right: 20, bottom: 40, left: 50 }, axisBottom: { legend: "Date", legendOffset: 30, format: d => moment(d).format('DD/MM'), tickRotation: 0, legendPosition: "middle", tickPadding: 0 }, axisLeft: { legend: "Number of events", legendOffset: -30, tickRotation: 0, tickValues: tickCount }, colors: d => {
                return colors[d.id];
            } });
    }
    return (React.createElement("div", { className: "dashboard-medication" },
        React.createElement("div", { className: "dashboard-medication__content", style: { height: "500px" } },
            React.createElement("div", { className: "dashboardMedication__header shadow corners" },
                React.createElement("div", { className: "dashboardMedication__titleTextContainer" },
                    React.createElement("h1", { style: { textAlign: 'center' } },
                        "Medication",
                        React.createElement("br", null),
                        "adherence")),
                React.createElement("div", { className: "dashboardMedication__sliderContainer", id: "sliderContainer" },
                    useMediaQuery({
                        query: '(max-width: 1050px)'
                    }) === false ?
                        React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 10, maximumDays: maxWindow, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                        :
                            null,
                    React.createElement("div", { className: "dashboardMedication__entryContainer" },
                        React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                        React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                    isSliderLocked === true ?
                        React.createElement("div", { className: "dashboardMedication__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                        :
                            null)),
            React.createElement("div", { className: "dashboard-medication__graph shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("h3", { style: { position: 'absolute', marginTop: '-0.5em' } }, "Medication timings"),
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center' } }, medication != null && medication.length == 0 ?
                        React.createElement("h3", null, "No data was found for the provided time period")
                        : React.createElement(React.Fragment, null, graphData)))),
            React.createElement("div", { className: "dashboard-medication__piechart shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center' } }, medicationWindow.windows != null && medicationWindow.windows.length > 0 ?
                        medication != null && medication.length == 0 ?
                            React.createElement("h3", null, "No data was found for the provided time period")
                            : React.createElement(React.Fragment, null, pieChart)
                        : React.createElement("h3", null,
                            "You must provide a medication window to view this visualization ",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "You can provide a medication window through the \"Medication Windows\" tab of the settings page")))),
            React.createElement("div", { className: "dashboard-medication__barchart shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center' } }, medicationWindow.windows != null && medicationWindow.windows.length > 0 ?
                        medication != null && medication.length == 0 ?
                            React.createElement("h3", null, "No data was found for the provided time period")
                            : React.createElement(React.Fragment, null, barChart)
                        : React.createElement("h3", null,
                            "You must provide a medication window to view this visualization ",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "You can provide a medication window through the \"Medication Windows\" tab of the settings page")))))));
}
export default Medication;
