import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import moment from "moment";
// import './Sleep.css';
import './ServiceUserHistory.css';
import { authProvider } from '../../authProvider';
export function ServiceUserHistory(props) {
    const [hasError, setErrors] = useState(false);
    const [history, setHistory] = useState([]);
    //const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    const [timeFrame, setTimeFrame] = useState({ startDate: moment('2019-01-01'), endDate: moment() });
    //const [timeFrame, setTimeFrame] = useState(props.acsisID == 4026983 ? { startDate: moment('2019-01-01'), endDate: moment('2019-12-31') } : { startDate: moment('2020-01-01'), endDate: moment('2020-12-31') });
    const [visibleTimeFrame, setVisibleTimeFrame] = useState({ startDate: moment().startOf('year'), endDate: moment().endOf('year') });
    const [recentEventsBody, setRecentEventsBody] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/serviceUserHistory?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } }); //&acsis=${props.acsisID}`);
            res
                .json()
                .then((res) => {
                setHistory(res);
            })
                .catch(err => setErrors(err));
            const res2 = await fetch(`/api/RuleAlert?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res2
                .json()
                .then((res) => {
                let temp = [];
                res.forEach((x, i) => {
                    if (x.ruleTriggerMessage && x.dateTime && x.ruleCategory) {
                        temp.push(React.createElement(React.Fragment, null,
                            React.createElement("span", { key: i },
                                React.createElement("b", null, x.ruleCategory),
                                " - ",
                                moment(x.dateTime).format("DD/MM/YY"),
                                " - ",
                                x.ruleTriggerMessage),
                            React.createElement("br", null)));
                    }
                });
                setRecentEventsBody(temp);
            })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [timeFrame]);
    let data = history.map(d => {
        switch (d.tiles.serviceUser.colour) {
            case 'green':
                return { day: d.dateTime.split('T')[0], value: 0 };
            case 'amber':
                return { day: d.dateTime.split('T')[0], value: 1 };
            case 'orange':
                return { day: d.dateTime.split('T')[0], value: 2 };
            case 'red':
                return { day: d.dateTime.split('T')[0], value: 3 };
            default:
                return { day: d.dateTime.split('T')[0], value: 4 };
        }
    });
    return (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '3fr 2fr', gridGap: '20px', height: '100%' } },
        React.createElement("div", { className: 'shadow corners', style: { position: 'relative', width: '100%', height: '100%', backgroundColor: '#FFFFFF' } },
            React.createElement("div", { style: { position: 'absolute', width: '100%', height: '200px', } },
                React.createElement("div", { className: "serviceUserHistory__selectionContainer" },
                    React.createElement("div", { className: "serviceUserHistory__selectionText" }, `Event history: ${visibleTimeFrame.startDate.format('YYYY')}`),
                    React.createElement("div", { className: "serviceUserHistory__selectionButtons" },
                        React.createElement(BackArrow, { className: "serviceUserHistory__selectionButton", onMouseUp: () => {
                                setVisibleTimeFrame({
                                    startDate: visibleTimeFrame.startDate.subtract(1, 'year'),
                                    endDate: visibleTimeFrame.endDate.subtract(1, 'year'),
                                });
                            } }),
                        React.createElement(ForwardsArrow, { className: "serviceUserHistory__selectionButton", onMouseUp: () => {
                                setVisibleTimeFrame({
                                    startDate: visibleTimeFrame.startDate.add(1, 'year'),
                                    endDate: visibleTimeFrame.endDate.add(1, 'year'),
                                });
                            } }))),
                React.createElement(ResponsiveCalendar, { data: data, 
                    // from={props.acsisID == 4026983 ? '2019-01-01' : '2020-01-01'}
                    // to={props.acsisID == 4026983 ? '2019-12-31' : '2020-12-31'}
                    from: visibleTimeFrame.startDate.format('YYYY-MM-DD'), to: visibleTimeFrame.endDate.format('YYYY-MM-DD'), minValue: 0, maxValue: 4, emptyColor: "#eeeeee", colors: ['#3A7D44', '#FFD439', '#FF7A36', '#A22C29', '#EEEEEE'], margin: { top: -5, right: 40, bottom: 40, left: 40 }, yearSpacing: 40, monthBorderColor: "#ffffff", dayBorderWidth: 2, dayBorderColor: "#ffffff", tooltip: e => {
                        let colour = 'Grey';
                        switch (e.value) {
                            case 0:
                                colour = 'Green';
                                break;
                            case 1:
                                colour = 'Amber';
                                break;
                            case 2:
                                colour = 'Orange';
                                break;
                            case 3:
                                colour = 'Red';
                                break;
                            default:
                                colour = 'Grey';
                                break;
                        }
                        return React.createElement("span", null,
                            e.day,
                            ": ",
                            colour);
                    } }))),
        React.createElement("div", { className: 'shadow corners', style: { position: 'relative', width: '100%', backgroundColor: '#FFFFFF', padding: '20px', minHeight: '150px', maxHeight: '180px', overflowY: 'auto', paddingBottom: '10px' } },
            React.createElement("h3", { style: { marginTop: '0px' } }, "Recent events"),
            recentEventsBody)));
}
function BackArrow(props) {
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 25, height: 25, viewBox: "0 0 25 25" }, props),
        React.createElement("g", { "data-name": "Group 1", stroke: "#707070" },
            React.createElement("g", { "data-name": "Rectangle 1", fill: "#fff" },
                React.createElement("rect", { width: 25, height: 25, rx: 1, stroke: "none" }),
                React.createElement("rect", { x: 0.5, y: 0.5, width: 24, height: 24, rx: 0.5, fill: "none" })),
            React.createElement("path", { "data-name": "Line 1", fill: "none", d: "M6.5 12.5h13" }),
            React.createElement("path", { "data-name": "Line 2", fill: "none", d: "M6.5 12.5l4-3" }),
            React.createElement("path", { "data-name": "Line 3", fill: "none", d: "M6.5 12.5l4 3" }))));
}
function ForwardsArrow(props) {
    return (React.createElement("svg", Object.assign({ "data-name": "Group 2", xmlns: "http://www.w3.org/2000/svg", width: 25, height: 25, viewBox: "0 0 25 25" }, props),
        React.createElement("g", { "data-name": "Rectangle 1", fill: "#fff", stroke: "#707070" },
            React.createElement("rect", { width: 25, height: 25, rx: 1, stroke: "none" }),
            React.createElement("rect", { x: 0.5, y: 0.5, width: 24, height: 24, rx: 0.5, fill: "none" })),
        React.createElement("path", { "data-name": "Line 1", fill: "none", stroke: "#707070", d: "M18.5 12.5h-13" }),
        React.createElement("path", { "data-name": "Line 2", fill: "none", stroke: "#707070", d: "M18.5 12.5l-4-3" }),
        React.createElement("path", { "data-name": "Line 3", fill: "none", stroke: "#707070", d: "M18.5 12.5l-4 3" })));
}
