import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
// import { ContextModal } from './ContextModal';
import './GanttChart.css';
import "./CommonGraph.css";
import moment from 'moment';
import Modal from 'react-modal';
import { authProvider } from '../../authProvider';
import Gantt from './ActivityChart/ganttMain';
export function GanttWrapper(props) {
    const [mobility, setMobility] = useState([]);
    const [appliance, setAppliance] = useState(null);
    const [network, setNetwork] = useState(null);
    const [applianceLocations, setApplianceLocations] = useState(null);
    const [domainArray, setDomainArray] = useState(null);
    const [applianceLoc, setApplianceLoc] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [timeFrame, setTimeFrame] = useState({ startDate: moment(props.target.startDateTime).subtract(6, 'hours'), endDate: moment(props.target.startDateTime).add(6, 'hours') });
    useEffect(() => {
        async function fetchData() {
            let fixedStart = moment(timeFrame.startDate);
            let fixedEnd = moment(timeFrame.endDate);
            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes) => {
                    setMobility(mobilityRes);
                }).catch(err => console.error(err)),
                applianceRes.then(res => res.json()).then((applianceRes) => {
                    setAppliance(applianceRes);
                }).catch(err => console.error(err)),
                networkRes.then(res => res.json()).then((res) => {
                    setNetwork(res);
                }).catch(err => console.error(err)),
                applianceLocRes.then(res => res.json()).then((res) => {
                    setApplianceLocations(res);
                }).catch(err => console.error(err))
            ]);
            setBounds([fixedStart.toDate(), fixedEnd.toDate()]);
        }
        fetchData();
    }, [timeFrame]);
    useEffect(() => {
        if (network != null) {
            let quickLinkedList = [];
            let net2 = network;
            for (let i = 0; i < net2.nodes.length; i++) {
                quickLinkedList.push({
                    index: i,
                    value: net2.nodes[i].id,
                    links: []
                });
            }
            for (let i = 0; i < net2.links.length; i++) {
                let i1 = quickLinkedList.findIndex(x => x.value === net2.links[i].source);
                let i2 = quickLinkedList.findIndex(x => x.value === net2.links[i].target);
                if (i1 === -1 || i2 === -1) {
                    continue;
                }
                quickLinkedList[i1].links.push(i2);
                quickLinkedList[i2].links.push(i1);
            }
            setDomainArray(colterStevens(quickLinkedList));
        }
        if (applianceLocations != null) {
            // Sort the appliance location record
            let applianceKeys = Object.keys(applianceLocations.appliances);
            let locationTally = {};
            let applianceLocRecord = [];
            for (let i = 0; i < applianceKeys.length; i++) {
                if (!locationTally[applianceLocations.appliances[applianceKeys[i]].location]) {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location] = {
                        location: applianceLocations.appliances[applianceKeys[i]].location,
                        number: 1,
                        appliances: [applianceKeys[i].split('|')[1]]
                    };
                }
                else {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].number++;
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].appliances.push(applianceKeys[i].split('|')[1]);
                }
                applianceLocRecord.push({
                    appliance: applianceKeys[i].split('|')[1],
                    location: applianceLocations.appliances[applianceKeys[i]].location,
                    position: locationTally[applianceLocations.appliances[applianceKeys[i]].location].number,
                    total: -1
                });
            }
            for (let i = 0; i < applianceLocRecord.length; i++) {
                if (locationTally[applianceLocRecord[i].location]) {
                    applianceLocRecord[i].total = locationTally[applianceLocRecord[i].location].number;
                }
            }
            setApplianceLoc(applianceLocRecord);
        }
    }, [network, applianceLocations]);
    return React.createElement(Gantt, { motionData: mobility, domainArray: domainArray, applianceLocations: applianceLoc, applianceData: appliance, bounds: bounds });
}
export function ContextModal(props) {
    var _a, _b;
    return (React.createElement(Modal, { style: { overlay: { backgroundColor: 'rgba(200, 200, 200, 0.75)' }, content: { inset: '100px', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset' } }, isOpen: props.target != null, contentLabel: "Event context", onRequestClose: () => { props.setTarget(null); } },
        React.createElement("h1", null,
            "Medication accessed ",
            ((_a = props.target) === null || _a === void 0 ? void 0 : _a.inWindow) ? 'within window' : 'outside window',
            " - ",
            moment((_b = props.target) === null || _b === void 0 ? void 0 : _b.startDateTime).format('hh:mm DD/MM/YY')),
        React.createElement("h2", null, "Surrounding activity"),
        React.createElement("div", { style: { height: '70%', width: '100%' } }, props.target != null && React.createElement(GanttWrapper, { acsisID: props.acsis, target: props.target }))));
}
export function MedicationChart(props) {
    const [modalTarget, setModalTarget] = useState(null);
    const d3Container = useRef(null);
    let svgWidth = 0;
    let svgHeight = 0;
    const margin = { left: 100, top: 10, right: 10, bottom: 20 };
    let width = svgWidth - margin.left - margin.right;
    let height = svgHeight - margin.top - margin.bottom;
    useEffect(() => {
        plotChart();
    }, [props.data, d3Container.current]);
    let timeout = false;
    useEffect(() => {
        let delta = 1000;
        function initDraw() {
            if (timeout === false) {
                timeout = true;
                setTimeout(plotChart, delta);
            }
        }
        initDraw();
        window.addEventListener('resize', initDraw);
        return () => {
            window.removeEventListener('resize', initDraw);
        };
    }, [props.data]);
    function plotChart() {
        timeout = false;
        if (!props.data) {
            return;
        }
        const svg = d3.select(d3Container.current);
        const margin = { left: 100, top: 10, right: 10, bottom: 20 };
        const canvas = d3.select(d3Container.current);
        if (canvas && canvas.node()) {
            svgWidth = canvas.node().getBoundingClientRect().width;
            svgHeight = canvas.node().getBoundingClientRect().height;
        }
        width = svgWidth - margin.left - margin.right;
        height = svgHeight - margin.top - margin.bottom;
        let constraint = width;
        if (constraint > height) {
            constraint = height;
        }
        constraint = constraint / 2;
        // TODO: Replace this with a better update method
        svg.selectAll('*').remove();
        let mainGroup = svg
            .append('g')
            .attr('transform', `translate(${(width + margin.left + margin.right) / 2}, ${(height + margin.top + margin.bottom) / 2})`);
        let arc = d3.arc();
        let labelData = [24, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        mainGroup.selectAll('text.labels')
            .data(labelData)
            .enter()
            .append('text')
            .classed('labels', true)
            .attr('stroke', '#777777')
            .attr('x', (d, i) => Math.cos((Math.PI * -0.5) + (Math.PI * 2) * (i / 24)) * (constraint))
            .attr('y', (d, i) => Math.sin((Math.PI * -0.5) + (Math.PI * 2) * (i / 24)) * (constraint))
            .attr('text-anchor', 'middle')
            .text(d => d)
            .style("opacity", 0.8);
        if (props.windowData) {
            let windows = props.windowData.map(d => {
                // Get the start and end times as a percentage of the day
                let start = moment.duration(d.startTime).asSeconds() / 86400;
                let end = moment.duration(d.endTime).asSeconds() / 86400;
                return arc({
                    innerRadius: constraint * 0.66,
                    outerRadius: constraint * 0.85,
                    startAngle: (Math.PI * 2) * start,
                    endAngle: (Math.PI * 2) * end
                });
            });
            mainGroup.append('circle')
                .attr('r', '' + constraint * 0.66)
                .style('stroke-dasharray', '5, 3')
                .style('fill', 'none')
                .style('stroke', '#777777');
            mainGroup.append('circle')
                .attr('r', '' + constraint * 0.85)
                .style('stroke-dasharray', '5, 3')
                .style('fill', 'none')
                .style('stroke', '#777777');
            //.style('stroke-width', '2px');
            mainGroup.selectAll('path.window')
                .data(windows)
                .enter()
                .append('path')
                .classed('window', true)
                .attr('d', d => d)
                .attr('fill', '#80b918')
                .attr('stroke', '#777777')
                .style("opacity", 0.5);
        }
        if (props.data) {
            let tooltip = d3.select("body")
                .append("div")
                .style("position", "absolute")
                .style("visibility", "hidden")
                .style("padding", "5px")
                .style("border-radius", "3px")
                .style("background-color", '#fff')
                .classed("shadow", true)
                .text("");
            mainGroup.selectAll('circle.data-point')
                .data(props.data)
                .enter()
                .append('circle')
                .classed('data-point', true)
                .attr('r', '10')
                .attr('fill', d => { return handleColor(d.inWindow); })
                .attr('stroke', '#777777')
                .attr('cx', d => Math.cos((Math.PI * -0.5) + (Math.PI * 2) * (moment.duration(d.time).asSeconds() / 86400)) * (constraint * 0.755))
                .attr('cy', d => Math.sin((Math.PI * -0.5) + (Math.PI * 2) * (moment.duration(d.time).asSeconds() / 86400)) * (constraint * 0.755))
                .style("opacity", 0.8)
                .on("mouseover", function (event, d) { tooltip.style("visibility", "visible"); })
                .on("mousemove", function (event, d) {
                tooltip.style("left", (event.pageX + 20) + "px")
                    .style("top", (event.pageY + 20) + "px")
                    .text(`Medication box opened ${d.inWindow ? 'within' : 'outside'} window at ${d.time} on ${d.date}`);
            })
                .on("mouseout", function (event, d) { tooltip.style("visibility", "hidden"); })
                .on("click", function (event, d) { setModalTarget(d); });
        }
    }
    function handleColor(inWindow) {
        if (props.windowData == null || props.windowData.length === 0) {
            return '#6666ff';
        }
        if (inWindow) {
            return '#80b918';
        }
        else {
            return '#faa307';
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { className: "gantt-chart", width: "100%", height: "100%", ref: d3Container }),
        React.createElement(ContextModal, { setTarget: setModalTarget, target: modalTarget, acsis: props.acsis })));
}
export default MedicationChart;
function colterStevens(network) {
    let edgeNode = -1;
    for (let i = 0; i < network.length; i++) {
        if (network[i].links.length === 1) {
            edgeNode = i;
            break;
        }
    }
    if (edgeNode === -1) {
        console.log('Impossible.');
        return [];
    }
    let traversed = [];
    sourceCode2011(traversed, network, edgeNode);
    let output = [];
    traversed.forEach(x => { output.push(network[x].value); });
    return output;
}
function sourceCode2011(traversed, network, currentNode) {
    traversed.push(currentNode);
    let availableLinks = network[currentNode].links;
    let nextValidSteps = availableLinks.filter(x => {
        let visited = false;
        traversed.forEach(y => {
            if (x === y) {
                visited = true;
            }
        });
        if (visited === false) {
            return true;
        }
        else {
            return false;
        }
    });
    let branchesArray = [];
    nextValidSteps.forEach(x => {
        let branches = 0;
        let links = network[x].links;
        links.forEach(i => {
            let visitedB = false;
            traversed.forEach(y => {
                if (i === y) {
                    visitedB = true;
                }
            });
            if (visitedB === false) {
                branches++;
            }
        });
        branchesArray.push({
            index: x,
            branches
        });
    });
    let executionOrder = branchesArray.sort((a, b) => {
        if (a.branches > b.branches) {
            return 1;
        }
        if (a.branches < b.branches) {
            return -1;
        }
        return 0;
    });
    executionOrder.forEach(x => {
        sourceCode2011(traversed, network, x.index);
    });
}
