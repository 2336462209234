export const colourScale5 = [
    '#80c300',
    '#bfd400',
    '#ffd100',
    '#ff9300',
    '#FF3300'
];
export const trueFalse = [
    '#80c300',
    '#FF3300'
];
export const defaultColors = {
    answer: '#d0d0d0',
    answerHover: '#7e7e7e',
    index: '#AAAAAA',
    indexMissed: '#FF3300'
};
