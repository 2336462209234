import * as React from 'react';
import './styles.css';
export function SingleLineWrappedDropDown(props) {
    let backgroundColor = '#ebebeb';
    let options = [];
    props.options.forEach(x => {
        options.push(React.createElement("option", { value: x }, x));
    });
    return (React.createElement("div", { className: "referral__singleLineTextInputContainer", style: props.styleProp },
        React.createElement("div", { style: { width: '200px', alignItems: 'center', display: 'flex' } },
            React.createElement("span", { className: "referral__singleLineTextInputText" }, props.entryName)),
        React.createElement("select", { className: "referral__singleLineTextInput", style: { backgroundColor }, value: props.value, onChange: (text) => { props.setValue(text.target.value); } }, options)));
}
export default SingleLineWrappedDropDown;
