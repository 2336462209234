import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment";
import { useMediaQuery } from 'react-responsive';
import './WalkingWithPurpose.css';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import WalkingWithPurposeLine from '../charts/WalkingWithPurposeLine';
import WalkingWithPurposePie from '../charts/WalkingWithPurposePie';
import WalkingWithPurposeKey from '../WalkingWithPurpose/WalkingWithPurposeKey';
import { authProvider } from '../../authProvider';
export function WalkingWithPurpose(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [walkingWithPurposeData, setWalkingWithPurposeData] = useState([]);
    const [walkingWithPurposeKey, setWalkingWithPurposeKey] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [pieData, setPieData] = useState(null);
    const [pieDataDefault, setPieDataDefault] = useState(null);
    const [pieSelectedSlice, setPieSelectedSlice] = useState(null);
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    useEffect(() => {
        if (startDate === endDate) {
            return;
        }
        async function fetchData() {
            setSliderLocked(true);
            let successfulLoad = true;
            const resData = await fetch(`/api/walkingWithPurposeData?&acsis=${props.acsisID}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            resData
                .json()
                .then((resData) => {
                setWalkingWithPurposeData(resData);
            })
                .catch(err => {
                setErrors(err);
                successfulLoad = false;
            });
            const resKey = await fetch(`/api/walkingWithPurposeKey`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            resKey
                .json()
                .then((resKey) => {
                setWalkingWithPurposeKey(resKey.pointValues);
            })
                .catch(err => {
                setErrors(err);
                successfulLoad = false;
            });
            if (successfulLoad) {
                setLoaded(true);
            }
            setSliderLocked(false);
        }
        fetchData();
    }, [startDate, endDate]);
    return (React.createElement("div", { className: "dashboard-walkingWithPurpose__container" },
        React.createElement("div", { className: "dashboardWalkingWithPurpose__header shadow corners" },
            React.createElement("div", { className: "dashboardWalkingWithPurpose__titleTextContainer" },
                React.createElement("h1", { style: { textAlign: 'center' } }, "Routines")),
            React.createElement("div", { className: "dashboardWalkingWithPurpose__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 150, maximumDays: 1000, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardWalkingWithPurpose__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardWalkingWithPurpose__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "dashboard-walkingWithPurpose__pieContainer corners shadow", id: "WalkingwithPurposePieContainer" }, walkingWithPurposeData && walkingWithPurposeData.length > 0 ?
            React.createElement(WalkingWithPurposePie, { data: pieDataDefault, stateData: pieData, setPieSelectedSlice: setPieSelectedSlice, pointValues: walkingWithPurposeKey })
            :
                React.createElement("span", null, "There is not any data available for the selected time period")),
        React.createElement("div", { className: "dashboard-walkingWithPurpose__keyContainer corners shadow" }, RenderKey(walkingWithPurposeKey, pieSelectedSlice)),
        React.createElement("div", { className: "dashboard-walkingWithPurpose__lineContainer corners shadow", id: "WalkingwithPurposeLineContainer" }, walkingWithPurposeData && walkingWithPurposeData.length > 0 ?
            React.createElement(WalkingWithPurposeLine, { data: walkingWithPurposeData, setPieDataDefault: setPieDataDefault, pieData: pieData, setPieData: setPieData, pieSelectedSlice: pieSelectedSlice, pointValues: walkingWithPurposeKey })
            :
                React.createElement("span", null, "There is not any data available for the selected time period"))));
}
export default WalkingWithPurpose;
function RenderKey(walkingWithPurposeKey, pieSelectedSlice) {
    let key = [];
    if (walkingWithPurposeKey.length === 0) {
        return null;
    }
    let localKeyArray = [];
    for (let i = 0; i < walkingWithPurposeKey.length; i++) {
        localKeyArray.push(React.createElement(WalkingWithPurposeKey, { header: walkingWithPurposeKey[i].header, body: walkingWithPurposeKey[i].body, color: walkingWithPurposeKey[i].color, name: walkingWithPurposeKey[i].name, selection: pieSelectedSlice }));
    }
    return localKeyArray;
}
