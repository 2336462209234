import * as React from 'react';
import coronavirusData from "../CoronavirusData";
import { StackedAreaChart } from './charts/StackedAreaChart';
export function Coronavirus(props) {
    const dates = Object.keys(coronavirusData.locations[0].timelines.confirmed.timeline);
    const locations = coronavirusData.locations.map(loc => loc.country + (loc.province ? ' - ' + loc.province : ''));
    const dataGroupedByDate = dates.map(date => (coronavirusData.locations.reduce((acc, loc) => ({
        ...acc,
        [loc.country + (loc.province ? ' - ' + loc.province : '')]: loc.timelines.confirmed.timeline[date]
    }), { date: new Date(date) })));
    let dataGroupedByLocation = coronavirusData.locations.map(loc => ({
        location: loc.country,
        values: dates.map(date => loc.timelines.confirmed.timeline[date])
    }));
    return (React.createElement("div", null,
        React.createElement(StackedAreaChart, { locations: locations, dates: dates, data: dataGroupedByDate, width: 1600, height: 800 }),
        React.createElement("hr", null),
        React.createElement("span", null, "Has error: No")));
}
export default Coronavirus;
