import * as React from 'react';
import './styles.css';
import { toast } from 'react-toastify';
import { validateInputs } from '../../validate';
function submitButtonFeedback(inputs, submitFunction) {
    let validity = validateInputs(inputs);
    if (validity !== true) {
        toast.error('Please ensure that you havnt missed any questions');
        return;
    }
    submitFunction(true);
}
export function SubmitButton(props) {
    return (React.createElement("div", { className: "dashboard-risk-score__submit-button corners shadow", onClick: () => submitButtonFeedback(props.inputs, props.submitFunction) },
        React.createElement("span", { className: "dashboard-risk-score__submit-button-text" }, "SUBMIT")));
}
export default SubmitButton;
