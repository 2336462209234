import * as React from 'react';
import { useState } from 'react';
import './styles.css';
import { defaultColors } from '../../const';
export function Tile(props) {
    const [hover, setHover] = useState(false);
    let backgroundColor = defaultColors.answer;
    if (props.header === true) {
        backgroundColor = props.color;
    }
    else if (props.editable !== true) {
        if (props.existingScores !== null) {
            let index = props.existingScores.findIndex(element => element.name === props.questionName);
            if (index === -1) {
                // a -1 index is found, maening this question may be a child of another question, find all parents
                let score = -1;
                for (let i = 0; i < props.existingScores.length; i++) {
                    for (let j = 0; j < props.existingScores[i].children.length; j++) {
                        if (props.existingScores[i].children[j].name === props.questionName) {
                            score = props.existingScores[i].children[j].value;
                        }
                    }
                }
                if (score === props.index) {
                    backgroundColor = props.color;
                }
            }
            else {
                if (props.existingScores[index].value === props.index) {
                    backgroundColor = props.color;
                }
            }
        }
    }
    else if (props.selection === props.index) {
        backgroundColor = props.color;
    }
    else if (hover) {
        backgroundColor = defaultColors.answerHover;
    }
    else {
        backgroundColor = defaultColors.answer;
    }
    return (React.createElement("div", { className: "dashboard-risk-score__tile shadow corners", style: {
            backgroundColor,
            gridColumnStart: props.index + 2,
            height: (props.header === false ? '100%' : '30%'),
            marginTop: (props.header === false ? "0px" : 'auto'),
            cursor: (props.editable === true ? "pointer" : 'auto')
        }, onMouseEnter: () => {
            if (props.editable === true) {
                setHover(true);
            }
        }, onMouseLeave: () => {
            if (props.editable === true) {
                setHover(false);
            }
        }, onClick: () => {
            if (props.editable === true) {
                if (props.setSelection != null) {
                    props.setSelection(props.index);
                }
                if (props.setMissed != null) {
                    props.setMissed(false);
                }
            }
        } },
        React.createElement("span", { className: "dashboard-risk-score__tile-text" }, props.text)));
}
export default Tile;
