import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment";
import "./Mobility.css";
import "../TextSelect.css";
import { HubConnectionBuilder } from '@aspnet/signalr';
import { useMediaQuery } from 'react-responsive';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import GanttIndicator from '../charts/ActivityChart/ganttIndicator';
import GanttMain from '../charts/ActivityChart/ganttMain';
import { authProvider } from '../../authProvider';
export function Mobility(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [mobility, setMobility] = useState(null);
    const [light, setLight] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [appliance, setAppliance] = useState(null);
    const [door, setDoor] = useState(null);
    const [network, setNetwork] = useState(null);
    const [toggles, setToggles] = useState({});
    const [live, setLive] = useState(false);
    const [hubConnection, setHubConnection] = useState();
    const [applianceLocations, setApplianceLocations] = useState(null);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    const [domainArray, setDomainArray] = useState(null);
    const [brushedRegion, setBrushedRegion] = useState(null);
    const [applianceLoc, setApplianceLoc] = useState(null);
    const maximumDays = 3;
    useEffect(() => {
        if (!live && (startDate == null || endDate == null)) {
            return;
        }
        if (endDate.diff(startDate, 'days') > maximumDays) {
            return;
        }
        if (startDate.isAfter(endDate)) {
            return;
        }
        setSliderLocked(true);
        async function fetchData() {
            let fixedStart = moment(startDate);
            let fixedEnd = moment(endDate);
            if (live) {
                fixedStart = moment().subtract(6, 'hours');
                fixedEnd = moment();
            }
            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const lightRes = fetch(`/api/light?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const temperatureRes = fetch(`/api/temperature?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const doorRes = fetch(`/api/door?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes) => {
                    setMobility(mobilityRes);
                }).catch(err => setErrors(err)),
                lightRes.then(res => res.json()).then((lightRes) => {
                    setLight(lightRes);
                }).catch(err => setErrors(err)),
                temperatureRes.then(res => res.json()).then((temperatureRes) => {
                    setTemperature(temperatureRes);
                }).catch(err => setErrors(err)),
                applianceRes.then(res => res.json()).then((applianceRes) => {
                    setAppliance(applianceRes);
                }).catch(err => setErrors(err)),
                doorRes.then(res => res.json()).then((doorRes) => {
                    setDoor(doorRes);
                }).catch(err => setErrors(err)),
                networkRes.then(res => res.json()).then((res) => {
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceLocRes.then(res => res.json()).then((res) => {
                    setApplianceLocations(res);
                }).catch(err => setErrors(err))
            ]);
            setSliderLocked(false);
            setTimeout(() => setSliderLocked(false), 1500);
        }
        fetchData();
        // Live data stream
        const createHubConnection = async () => {
            const accessToken = (await authProvider.getAccessToken()).accessToken;
            const hubConnect = new HubConnectionBuilder()
                .withUrl('/event', {
                accessTokenFactory: () => accessToken
            })
                .build();
            try {
                await hubConnect.start();
                //await hubConnect.invoke('AddToGroup', 'raw');
                //                await hubConnect.invoke('AddToGroup', '4334943');
                await hubConnect.invoke('AddToGroup', props.acsisID.toString());
                console.log(`Joined group "${props.acsisID.toString()}"`);
                hubConnect.on('Send', (name, message) => {
                    //setFeed(m => [...m, message]);
                    let mess = JSON.parse(message);
                    if (live && mess.RecordType == 'Motion' || mess.RecordType == 'MOTION') {
                        let key, keys = Object.keys(mess);
                        let n = keys.length;
                        let newobj = {};
                        while (n--) {
                            key = keys[n];
                            newobj[key.charAt(0).toLowerCase() + key.slice(1)] = mess[key];
                        }
                        setMobility(mobility => mobility ? [...mobility, newobj] : [newobj]);
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
            setHubConnection(hubConnect);
        };
        createHubConnection();
    }, [startDate, endDate, live]);
    useEffect(() => {
        if (network != null) {
            let quickLinkedList = [];
            let net2 = network;
            for (let i = 0; i < net2.nodes.length; i++) {
                quickLinkedList.push({
                    index: i,
                    value: net2.nodes[i].id,
                    links: []
                });
            }
            for (let i = 0; i < net2.links.length; i++) {
                let i1 = quickLinkedList.findIndex(x => x.value === net2.links[i].source);
                let i2 = quickLinkedList.findIndex(x => x.value === net2.links[i].target);
                if (i1 === -1 || i2 === -1) {
                    continue;
                }
                quickLinkedList[i1].links.push(i2);
                quickLinkedList[i2].links.push(i1);
            }
        }
        if (applianceLocations != null) {
            // Sort the appliance location record
            let applianceKeys = Object.keys(applianceLocations.appliances);
            let locationTally = {};
            let applianceLocRecord = [];
            for (let i = 0; i < applianceKeys.length; i++) {
                if (!locationTally[applianceLocations.appliances[applianceKeys[i]].location]) {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location] = {
                        location: applianceLocations.appliances[applianceKeys[i]].location,
                        number: 1,
                        appliances: [applianceKeys[i].split('|')[1]]
                    };
                }
                else {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].number++;
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].appliances.push(applianceKeys[i].split('|')[1]);
                }
                applianceLocRecord.push({
                    appliance: applianceKeys[i].split('|')[1],
                    location: applianceLocations.appliances[applianceKeys[i]].location,
                    position: locationTally[applianceLocations.appliances[applianceKeys[i]].location].number,
                    total: -1
                });
            }
            for (let i = 0; i < applianceLocRecord.length; i++) {
                if (locationTally[applianceLocRecord[i].location]) {
                    applianceLocRecord[i].total = locationTally[applianceLocRecord[i].location].number;
                }
            }
            // setApplianceLoc(applianceLocRecord);
        }
    }, [network, applianceLocations]);
    return (React.createElement("div", { className: "dashboardMobility__container" },
        React.createElement("div", { className: "dashboardMobility__header shadow corners" },
            React.createElement("div", { className: "dashboardMobility__titleTextContainer" },
                React.createElement("h1", { style: { textAlign: 'center' } }, "Activity")),
            React.createElement("div", { className: "dashboardMobility__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 1, maximumDays: maximumDays, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardMobility__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardMobility__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "dashboardMobility__mainGantt corners shadow" },
            React.createElement(GanttMain, { motionData: mobility, domainArray: domainArray, applianceLocations: applianceLoc, applianceData: appliance, bounds: brushedRegion })),
        React.createElement("div", { className: "dashboardMobility__indicatorGantt corners shadow" },
            React.createElement(GanttIndicator, { domainArray: domainArray, motionData: mobility, setBrushed: setBrushedRegion }))));
}
export default Mobility;
function colterStevens(network) {
    let edgeNode = -1;
    for (let i = 0; i < network.length; i++) {
        if (network[i].links.length === 1) {
            edgeNode = i;
            break;
        }
    }
    if (edgeNode === -1) {
        console.log('Impossible.');
        return [];
    }
    let traversed = [];
    sourceCode2011(traversed, network, edgeNode);
    let output = [];
    traversed.forEach(x => { output.push(network[x].value); });
    return output;
}
function sourceCode2011(traversed, network, currentNode) {
    traversed.push(currentNode);
    let availableLinks = network[currentNode].links;
    let nextValidSteps = availableLinks.filter(x => {
        let visited = false;
        traversed.forEach(y => {
            if (x === y) {
                visited = true;
            }
        });
        if (visited === false) {
            return true;
        }
        else {
            return false;
        }
    });
    let branchesArray = [];
    nextValidSteps.forEach(x => {
        let branches = 0;
        let links = network[x].links;
        links.forEach(i => {
            let visitedB = false;
            traversed.forEach(y => {
                if (i === y) {
                    visitedB = true;
                }
            });
            if (visitedB === false) {
                branches++;
            }
        });
        branchesArray.push({
            index: x,
            branches
        });
    });
    let executionOrder = branchesArray.sort((a, b) => {
        if (a.branches > b.branches) {
            return 1;
        }
        if (a.branches < b.branches) {
            return -1;
        }
        return 0;
    });
    executionOrder.forEach(x => {
        sourceCode2011(traversed, network, x.index);
    });
}
