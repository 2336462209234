const moment = require('moment');
export function mobilityIndexDataHandler(data) {
    if (data == null) {
        return [];
    }
    if (data.length === 0) {
        return [];
    }
    ;
    let intermediateArray = [];
    for (let i = 0; i <= data.length - 1; i++) {
        intermediateArray.push([new Date(data[i].StartDateTime), []]);
    }
    let timings = [];
    let keys = [];
    const removedProperties = ['id', '_rid', '_self', '_etag', '_attachments', '_ts', 'RecordType', 'ACSIS', 'DateTime'];
    data.map((record) => {
        if (record.StartDateTime == null) {
            console.log('null datetime');
        }
        timings.push(moment(record.StartDateTime));
        for (let property of removedProperties) {
            delete record[property];
        }
        ;
        // timings.push(record);
        for (let key of Object.keys(record)) {
            keys.push(key);
        }
    });
    let uniqueKeys = [...new Set(keys)];
    uniqueKeys.shift();
    // Outlier removal, taking the full range, remove the first set of 'Extreme Outliers' by removing the top 1 standard deviation.
    // then find the standard deviation for a second time, and remove the actual outliers.
    // this will leave the function with an 'upper cap' that can be applied to all further analysis within this key
    for (let key of uniqueKeys) {
        let keyTimings = [];
        let coreKeyArray = [];
        data.map((record) => {
            if (record[key]) {
                keyTimings.push(...record[key]);
                coreKeyArray.push(record[key]);
            }
            else {
                coreKeyArray.push(undefined);
            }
        });
        // first wave of outlier removal
        const mean1 = keyTimings.reduce(function (a, b) {
            return a + b;
        }, 0) / keyTimings.length;
        const std1 = Math.sqrt((keyTimings.reduce(function (a, b) {
            return a + ((b - mean1) * (b - mean1));
        })) / keyTimings.length);
        let initialOutlierRange = keyTimings.filter((timing) => {
            return timing < mean1 + std1;
        });
        // second wave of outlier removal and upper boundary calculation
        const mean2 = initialOutlierRange.reduce(function (a, b) {
            return a + b;
        }, 0) / initialOutlierRange.length;
        const std2 = Math.sqrt((initialOutlierRange.reduce(function (a, b) {
            return a + ((b - mean2) * (b - mean2));
        })) / initialOutlierRange.length);
        let keyUpperBound = std2 + mean2;
        // removal of outliers from the core key array ( the array which shall be used for any further analysis )
        // the remainig values then are averaged, and tunred into z scores to standardise the data across each signature route.
        // at the final step, all undefined numbers are turned returned to 0 (no effect on overall mobility index)
        // these values are then added tothe oputput array, item[1] = array
        coreKeyArray = coreKeyArray.map((arr) => {
            if (arr != undefined) {
                arr = arr.filter((val) => {
                    return val < keyUpperBound;
                });
                if (arr.length == 0) {
                    arr = undefined;
                }
            }
            return arr;
        });
        coreKeyArray = coreKeyArray.map((arr) => {
            if (arr != undefined) {
                let output = ((arr.reduce((a, b) => {
                    return a + b;
                }, 0) / arr.length) - mean2) / std2 * -1;
                return output;
            }
            else {
                return 0;
            }
        });
        // append to the output array by adding to the previously defined position contining the Date object at position 0
        for (let i = 0; i <= coreKeyArray.length - 1; i++) {
            intermediateArray[i][1].push(coreKeyArray[i]);
        }
    }
    ;
    // sum the Z score array and return
    let noNullArray = intermediateArray.map((tuple) => {
        return [tuple[0], tuple[1].reduce((a, b) => {
                return a + b;
            }, 0)];
    });
    let finalIntermediate = noNullArray.map((tuple) => {
        if (tuple[1] == 0) {
            return [tuple[0], null];
        }
        else {
            return tuple;
        }
    });
    let origin = 0;
    // Ensure that any days when no data is processed mirrors the previous day.
    for (let i = 0; i < finalIntermediate.length; i++) {
        if (finalIntermediate[i][1] == null) {
            if (i == 0 || finalIntermediate[i][1 - 1] == null) {
                finalIntermediate[i][1] = 0;
            }
            else {
                finalIntermediate[i][1] = finalIntermediate[i - 1][1];
            }
        }
        if (finalIntermediate[i][1] != null && finalIntermediate[0][1] != null) {
            if (i == 0) {
                origin = finalIntermediate[0][1];
            }
            let current = finalIntermediate[i][1];
            finalIntermediate[i][1] = current - origin;
        }
    }
    let DESInput = [];
    for (let i = 0; i < finalIntermediate.length; i++) {
        DESInput.push(finalIntermediate[i][1]);
    }
    let predictedPoints = 10;
    let { trend, variance } = DES(DESInput, 0.2, 0.2, predictedPoints);
    let outputArray = [];
    for (let i = 3; i < trend.length - predictedPoints; i++) {
        outputArray.push({
            key: 'trendReal',
            x: moment(timings[i - 2]),
            y: trend[i],
        });
        outputArray.push({
            key: 'varianceReal',
            x: moment(timings[i - 2]),
            y: variance[i],
        });
    }
    let j = 0;
    // Shift may look wrong but index of line starts on 0, first shown datapoint is index 1 >> next point on from final date of real values
    // chancged offset from -2 to -1
    for (let i = trend.length - predictedPoints - 1; i < trend.length; i++) {
        outputArray.push({
            key: 'trendProjected',
            x: moment(timings[timings.length - 1]).add(j, 'day').toISOString(),
            y: trend[i],
        });
        outputArray.push({
            key: 'varianceProjected',
            x: moment(timings[timings.length - 1]).add(j, 'day').toISOString(),
            y: variance[i],
        });
        j++;
    }
    return outputArray;
}
function SES(y, alpha) {
    let x = [];
    for (let i = 0; i < y.length; i++) {
        x.push(i);
    }
    let forecast = [0, y[0]];
    let historicalPeriodLength = y.length;
    for (let t = 2; t < historicalPeriodLength; t++) {
        forecast[t] = alpha * y[t - 1] + (1 - alpha) * forecast[t - 1];
    }
    return forecast;
}
function DES(y, alpha, beta, numPredictions) {
    // Alpha is the smoothign factor 
    let x = [];
    for (let i = 0; i < y.length + 10; i++) {
        x.push(i);
    }
    let C = [0, y[0]];
    let T = [0, y[1] - y[0]];
    let forecast = [0, 0, 0];
    for (let t = 2; t < y.length; t++) {
        let tempC = (alpha * y[t - 1]) + (1 - alpha) * (C[t - 2] + T[t - 2]);
        let tempT = (beta * (C[t - 1] - C[t - 2])) + ((1 - beta) * T[t - 2]);
        forecast.push(tempC + tempT);
        C.push(tempC);
        T.push(tempT);
    }
    for (let t = y.length - 1; t < y.length + numPredictions; t++) {
        let tempC = (alpha * forecast[t - 1]) + (1 - alpha) * (C[t - 2] + T[t - 2]);
        let tempT = (beta * (C[t - 1] - C[t - 2])) + ((1 - beta) * T[t - 2]);
        forecast.push(tempC + tempT);
        C.push(tempC);
        T.push(tempT);
    }
    let trend = SES(forecast, 0.2);
    let variance = SES(forecast, 0.5);
    return {
        trend,
        variance
    };
}
