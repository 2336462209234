import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment";
import './Sleep.css';
import './Temperature.css';
import { ResponsiveLine } from '@nivo/line';
import { group } from "d3-array";
import { authProvider } from '../../authProvider';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { useMediaQuery } from 'react-responsive';
export function Temperature(props) {
    var _a;
    const [temperature, setTemperature] = useState([]);
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    const maxWindow = 5;
    useEffect(() => {
        async function fetchData() {
            if (startDate == null || endDate == null || endDate.diff(startDate, 'days') > maxWindow * 1.1 || endDate <= startDate) {
                return;
            }
            setSliderLocked(true);
            const temperatureRes = await fetch(`/api/temperature?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            setSliderLocked(false);
            temperatureRes
                .json()
                .then((res) => {
                let mapped = [];
                group(res, v => v.location).forEach((v, k) => {
                    let result = {
                        id: k,
                        color: "hsl(335, 70%, 50%)",
                        data: v.map(d => ({
                            x: new Date(d.startDateTime),
                            y: d.measValue
                        }))
                    };
                    mapped.push(result);
                });
                mapped.forEach(v => v.data.push({ x: endDate.toDate(), y: v.data[v.data.length - 1].y }));
                setTemperature(mapped);
            })
                .catch(err => console.warn(err));
        }
        fetchData();
    }, [startDate, endDate]);
    let graphData = null;
    if (temperature.length > 0) {
        graphData = React.createElement(ResponsiveLine, { data: temperature, xScale: {
                type: 'time'
            }, yScale: {
                type: 'linear', min: 'auto', max: 'auto'
            }, axisBottom: {
                legend: "Date", legendOffset: 40, tickSize: 5, tickPadding: 5, format: d => moment(d).format('HH:mm DD/MM')
            }, axisLeft: { legend: "Temperature (°C)", legendOffset: -30, tickSize: 5, tickPadding: 5 }, margin: { top: 20, right: 200, bottom: 50, left: 50 }, enableSlices: "x", legends: [
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] });
    }
    return (React.createElement("div", { className: "dashboard-toilet-frequency" },
        React.createElement("div", { className: "temperature__header shadow corners" },
            React.createElement("div", { className: "temperature__titleTextContainer" },
                React.createElement("h1", { style: { textAlign: 'center' } }, "Temperature")),
            React.createElement("div", { className: "temperature__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 2, maximumDays: maxWindow, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "temperature__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "temperature__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "shadow corners", style: { height: '600px', backgroundColor: '#fff' } },
            React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, graphData)))));
}
export default Temperature;
