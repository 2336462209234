import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkGraph } from '../charts/NetworkGraph';
import { authProvider } from '../../authProvider';
export function Network(props) {
    const [hasError, setErrors] = useState(false);
    const [network, setNetwork] = useState(null);
    const [appliances, setAppliances] = useState(null);
    const [location, setLocation] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const locationRes = await fetch(`/api/currentRoom?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const networkRes = await fetch(`/api/network?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            const applianceRes = await fetch(`/api/applianceLocation?acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                networkRes.json().then((res) => {
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceRes.json().then((res) => {
                    setAppliances(res);
                }).catch(err => setErrors(err)),
                locationRes.json().then((res) => {
                    setLocation(res.location);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, []);
    return (
    // <div>
    React.createElement(React.Fragment, null,
        React.createElement("div", { className: "dashboard-home__title" },
            React.createElement("h1", null, "Latest network")),
        React.createElement("div", { className: "shadow corners", style: { backgroundColor: "#ffffff", width: '100%', height: '90%' } },
            React.createElement(NetworkGraph, { acsis: props.acsisID, data: network, applianceData: appliances, location: location, width: 1420, height: 710 })))
    // {/* </div> */}
    );
}
export default Network;
