import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authProvider';
// TODO: Turn auth on
ReactDOM.render(React.createElement(AzureAD, { provider: authProvider, forceLogin: true },
    React.createElement(App, null)), document.getElementById('root'));
// ReactDOM.render(
//   <App />
//   , document.getElementById('root'));
/*
ReactDOM.render(
    <App />
    , document.getElementById('root'));
    */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
