import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { authProvider } from '../../authProvider';
export function MedicationWindowSettings(props) {
    const [hasError, setErrors] = useState(false);
    const [changes, setChanges] = useState(false);
    const [medicationWindows, setMedicationWindows] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const medicationWindowRes = await fetch(`/api/serviceUser/${props.acsis}/medicationWindow`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                medicationWindowRes.json().then((medicationWindowRes) => {
                    setMedicationWindows(medicationWindowRes.windows);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, []);
    // Attempt to submit, if we fail for whatever reason, notify the user
    const handleSubmit = async (e) => {
        e.preventDefault();
        let sendData = await fetch(`/api/serviceUser/${props.acsis}/medicationWindow`, {
            method: "POST",
            body: JSON.stringify(medicationWindows),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken
            }
        }).catch(e => console.error('Could not submit medication window', e));
        if (sendData && sendData.ok) {
            toast.success('Medication windows successfully updated.');
            setChanges(false);
        }
        else {
            toast.error('Could not save medication windows, please try again.');
        }
    };
    const updateWindow = (key, property, e) => {
        let value = e.target.value;
        if (medicationWindows[key][property] == value) {
            return;
        }
        // We create a clone of the array and then the particular object we are updating, so as no to impact the immutability of the state array
        let newWindows = [...medicationWindows];
        newWindows[key] = { ...newWindows[key] };
        newWindows[key][property] = value;
        setMedicationWindows(newWindows);
        setChanges(true);
    };
    // Map the windows to JSX representations
    let mappedWindows = [];
    if (medicationWindows != null) {
        mappedWindows = medicationWindows.map((d, key) => {
            return (React.createElement("div", { key: key.toString() },
                React.createElement("label", null,
                    "From:",
                    React.createElement("input", { type: "text", onChange: e => updateWindow(key, 'startTime', e), value: d.startTime })),
                React.createElement("label", null,
                    "To:",
                    React.createElement("input", { type: "text", onChange: e => updateWindow(key, 'endTime', e), value: d.endTime })),
                React.createElement("hr", null)));
        });
    }
    return (React.createElement("div", { className: "window-settings" },
        React.createElement("form", { onSubmit: handleSubmit, className: "shadow corners" },
            React.createElement("h2", null, "Medication windows"),
            mappedWindows,
            React.createElement("input", { type: "submit", value: "Save", disabled: !changes }))));
}
export default MedicationWindowSettings;
