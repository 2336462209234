import React from 'react';
import './WalkingWithPurposeKey.css';
export function WwpKeyItem(props) {
    return (React.createElement("div", { className: "wwpKeyItem_container", style: { opacity: props.name === props.selection || props.selection === null ? 1 : 0.3 } },
        React.createElement("div", { className: "wwpKey_colorSlice" },
            React.createElement("div", { className: "wwpKeyItem_indicator", style: { backgroundColor: props.color } })),
        React.createElement("div", { className: "wwpKeyItem_TextWindow" },
            React.createElement("text", { className: "wwpKeyItem_textHeader" }, props.header),
            React.createElement("text", { className: "wwpKeyItem_textBody" }, props.body))));
}
export default WwpKeyItem;
