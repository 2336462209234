import React from 'react';
export function DropDownOpenArrow(props) {
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 23 22" }, props),
        React.createElement("g", { "data-name": "Group 28", transform: "translate(-6384 -1300.5)", fill: "#707070" },
            React.createElement("rect", { "data-name": "Rectangle 33", width: 4, height: 22, rx: 2, transform: "translate(6393 1300.5)" }),
            React.createElement("rect", { "data-name": "Rectangle 34", width: 4, height: 23, rx: 2, transform: "rotate(90 2548.75 3858.25)" }))));
}
export function DropDownCloseCross(props) {
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 19.092 19.092" }, props),
        React.createElement("g", { "data-name": "Group 29", transform: "rotate(45 4774.12 -7048.006)", fill: "#707070" },
            React.createElement("rect", { "data-name": "Rectangle 33", width: 4, height: 22, rx: 2, transform: "translate(6393 1300.5)" }),
            React.createElement("rect", { "data-name": "Rectangle 34", width: 4, height: 23, rx: 2, transform: "rotate(90 2548.75 3858.25)" }))));
}
