import * as React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import moment from "moment";
import { mobilityIndexDataHandler } from '../../dataHandling/mobilityIndexDataHandler';
import { MobilityIndexGraph } from '../charts/MobilityIndexGraph';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import './MobilityIndex.css';
import { authProvider } from '../../authProvider';
export function MobilityIndex(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [mobIndex, setMobIndex] = useState([]);
    const [yBounds, setYBounds] = useState(null);
    // const [startDate, setStartDate] = useState(moment().subtract(50, 'days'));
    // const [endDate, setEndDate] = useState(moment());
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [routeTimings, setRouteTimings] = useState(null);
    const isPhoneOrTablet = useMediaQuery({
        query: '(max-width: 1050px)'
    });
    const maximumDays = 200;
    useEffect(() => {
        if (startDate === null || endDate === null || endDate.diff(startDate, 'days') > maximumDays * 1.1 || startDate >= endDate) {
            return;
        }
        async function fetchData() {
            setSliderLocked(true);
            const res = await fetch(`/api/RouteTimings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {
                headers: {
                    Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken,
                    "Cache-Control": "no-cache"
                }
            });
            res
                .json()
                .then((res) => {
                let outputRouteTimings = res.filter((x) => moment(x.StartDateTime) > startDate);
                if (outputRouteTimings.length > 1) {
                    setRouteTimings(outputRouteTimings);
                }
                setLoaded(true);
                setSliderLocked(false);
            })
                .catch(err => setErrors(err));
            const boundaryRes = await fetch('/api/MobilityIndexBounds', { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            boundaryRes
                .json()
                .then((boundaryRes) => {
                setYBounds([boundaryRes.lowerBound, boundaryRes.upperBound]);
            })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [startDate, endDate]);
    let inputData = mobilityIndexDataHandler(routeTimings);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "dashboardMobilityIndex__header shadow corners" },
            React.createElement("div", { className: "dashboardMobilityIndex__titleTextContainer" },
                React.createElement("h1", null, "Mobility Index")),
            React.createElement("div", { className: "dashboardMobilityIndex__sliderContainer", id: "sliderContainer" },
                isPhoneOrTablet === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 40, maximumDays: maximumDays, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardMobilityIndex__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardMobilityIndex__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "shadow corners", style: { padding: "40px", backgroundColor: "#FFFFFF" } }, inputData.length > 40 ?
            React.createElement(MobilityIndexGraph, { data: inputData, bounds: yBounds, setLock: setSliderLocked, startDate: startDate, endDate: endDate })
            : loaded ? (React.createElement("h3", null, "There is not currently enough data for the Mobility Index visualization to be shown")) : React.createElement("h3", null, "Loading"))));
}
export default MobilityIndex;
