import * as React from 'react';
import './App.css';
import { Router, navigate } from "@reach/router";
import { ServiceUsers } from './components/ServiceUsers';
import { ServiceUserDashboard } from './components/ServiceUserDashboard';
import ServiceUserReferral from './components/serviceUserReferral/ReferralFormParent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
    /*return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
      <MobilityGraph />
    );*/
    /*
    <div className="app__logo"><img className="app__logo-image" src={process.env.PUBLIC_URL + "/TH.jpg"} alt="TH" /></div>
    <div className="app__header shadow"><h1>TH-Web</h1><SettingsIcon style={{ marginLeft: "auto" }} /></div>
    <div className="app__sidebar"></div>
    <div className="app__content">
    */
    function navigateHome() {
        navigate('/');
    }
    /*
        useEffect(() => {
            async function getTokens() {
                let accessToken: any = 'No access token';
                let idToken: any = 'No access token';
    
                try {
                    accessToken = await authProvider.getAccessToken();
                } catch (e) {
                    accessToken = e;
                }
    
                try {
                    idToken = await authProvider.getIdToken();
                } catch (e) {
                    idToken = e;
                }
                console.log(accessToken);
                console.log(idToken);
            }
            console.log('Triggering token fetch');
            getTokens();
        }, []);
        */
    return (React.createElement("div", { className: "app" },
        React.createElement("div", { className: "app__header shadow" },
            React.createElement("h1", { onClick: navigateHome, style: { cursor: "pointer" } },
                React.createElement("span", { style: { color: '#555' } }, "D"),
                "ata ",
                React.createElement("span", { style: { color: '#555' } }, "I"),
                "nspired ",
                React.createElement("span", { style: { color: '#555' } }, "L"),
                "iving")),
        React.createElement("div", { className: "app__content" },
            React.createElement(Router, null,
                React.createElement(ServiceUsers, { path: "/" }),
                React.createElement(ServiceUserDashboard, { path: "dashboard/:acsisID/*" }),
                React.createElement(ServiceUserReferral, { path: "referral" }))),
        React.createElement(ToastContainer, null)));
}
export default App;
