import * as React from 'react';
import './styles.css';
export function SingleLineWrappedTextInput(props) {
    let backgroundColor = '#ebebeb';
    return (React.createElement("div", { className: "referral__singleLineTextInputContainer", style: props.styleProp },
        React.createElement("div", { style: { width: '200px', alignItems: 'center', display: 'flex' } },
            React.createElement("span", { className: "referral__singleLineTextInputText" }, props.entryName)),
        React.createElement("input", { type: "text", className: "referral__singleLineTextInput", placeholder: props.placeholderText, style: { backgroundColor }, value: props.value, onChange: (text) => { props.setValue(text.target.value); }, readOnly: !props.editable })));
}
export default SingleLineWrappedTextInput;
