import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import moment from "moment";
import './Sleep.css';
import './Light.css';
import { ResponsiveLine } from '@nivo/line';
import * as d3 from "d3";
import { group } from "d3-array";
import { scaleTime, scaleBand, scaleOrdinal, axisBottom, axisLeft } from 'd3';
import { authProvider } from '../../authProvider';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { useMediaQuery } from 'react-responsive';
export function Light(props) {
    var _a;
    const [hasError, setErrors] = useState(false);
    const [light, setLight] = useState([]);
    const d3Container = useRef(null);
    const [firstDraw, setFirstDraw] = useState(true);
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    const maxWindow = 5;
    useEffect(() => {
        async function fetchData() {
            if (startDate == null || endDate == null || endDate.diff(startDate, 'days') > maxWindow * 1.1 || endDate <= startDate) {
                return;
            }
            setSliderLocked(true);
            const lightRes = await fetch(`/api/light?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            setSliderLocked(false);
            lightRes
                .json()
                .then((res) => {
                let mapped = [];
                group(res, v => v.location).forEach((v, k) => {
                    let result = {
                        id: k,
                        color: "hsl(335, 70%, 50%)",
                        data: v.map(d => ({
                            x: new Date(d.startDateTime),
                            y: d.measValue
                        }))
                    };
                    mapped.push(result);
                });
                mapped.forEach(v => v.data.push({ x: endDate.toDate(), y: v.data[v.data.length - 1].y }));
                setLight(mapped);
                if (res && d3Container.current) {
                    const svg = d3.select(d3Container.current);
                    const margin = { left: 100, top: 10, right: 10, bottom: 20 };
                    const width = 1400;
                    const height = 400;
                    // TODO: Replace this with a better update method
                    //svg.selectAll('*').remove();
                    let startDateTemp, endDateTemp;
                    startDateTemp = startDate.toDate();
                    endDateTemp = endDate.toDate();
                    let startDates = [];
                    let endDates = [];
                    let preparedLightData = [];
                    if (res) {
                        preparedLightData = res.map((d, i) => {
                            if (d.startDateTime == null || d.endDateTime == null) {
                                return null;
                            }
                            let start = new Date(d.startDateTime);
                            let end = new Date(d.endDateTime);
                            // TODO: Decide if this is needed for light data.
                            startDates.push(start);
                            endDates.push(end);
                            return {
                                startDateTime: start,
                                endDateTime: end,
                                location: d.location,
                                duration: d.duration,
                                measValue: d.measValue
                            };
                        }).filter(d => d ? true : false);
                    }
                    let x = scaleTime()
                        .domain([startDateTemp, endDateTemp])
                        .range([margin.left, width - margin.right])
                        .clamp(true);
                    // New yEntries to accomodate doors
                    //let yArray = d3.map(props.data, d => d.location).keys();            
                    //let yArray = new Map(preparedLightData.map(d => d.location)).keys();
                    let yEntries = new Set(preparedLightData.map(d => d.location));
                    //let yEntries = d3.map(props.data, d => d.location).keys();
                    let y = scaleBand()
                        .domain(yEntries)
                        .range([height - margin.bottom, margin.top]);
                    //let yColour = scaleOrdinal(Tableau10)
                    let yColour = scaleOrdinal(['#f1faee', '#a8dadc'])
                        .domain(yEntries);
                    let xAxis = (g) => g
                        .attr("transform", `translate(0,${height - margin.bottom})`)
                        .transition().duration(1000)
                        .call(axisBottom(x).ticks(width / 80).tickSizeOuter(0));
                    let yAxis = (g) => g
                        .attr("transform", `translate(${margin.left},0)`)
                        .call(axisLeft(y).tickSize(0));
                    if (firstDraw) {
                        let backBar = svg.selectAll("g.backBar").data(yEntries);
                        let backBarGroup = backBar.join("g")
                            .classed("backBar", true)
                            .attr("transform", (d) => `translate(0,${y(d)})`);
                        backBarGroup.append("rect")
                            .attr("fill", (d) => yColour(d))
                            .attr("x", margin.left)
                            .attr("width", width - margin.right - margin.left)
                            //.attr("y", 5)
                            .attr("height", y.bandwidth())
                            .style('opacity', 0.1);
                        backBarGroup.append("line")
                            .attr('x1', margin.left)
                            .attr('x2', width - margin.right)
                            .attr('stroke', '#aaa')
                            .style('stroke-dasharray', '3,3');
                    }
                    const lightBarData = svg.selectAll("g.lightBar")
                        .data(preparedLightData);
                    const lightBar = lightBarData.join("g")
                        .classed("lightBar", true)
                        .attr("transform", (d) => `translate(0,${y(d.location)})`);
                    const lightScale = d3.scaleLinear().domain([0, 600]).range(["#000055", "#ffbf00"]).clamp(true);
                    lightBar.append("rect")
                        .attr("fill", (d) => lightScale(d.measValue))
                        .attr("x", (d) => x(d.startDateTime))
                        .attr("width", (d) => x(d.endDateTime) - x(d.startDateTime))
                        .attr("y", (y.bandwidth() * 0.2))
                        .attr("height", y.bandwidth() * 0.6)
                        .on("mousemove", (event, d, i) => {
                        //tip.html(JSON.stringify(d, null, "  "))
                        /*    .style("left", 500)
                            .style("top", 500);*/
                    });
                    /*const barData = svg.selectAll("g.bar")
                        .data(preparedData);

                    /*const bar = (barData as any).join("g")
                        .classed("bar", true)
                        .attr("transform", (d: GanttChartProcessedData) => `translate(0,${y(d.location)})`);
                        */
                    //let tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any> | d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
                    if (firstDraw) {
                        d3.select("body")
                            .append("div")
                            .classed('graph-tooltip', true)
                            .style("position", "absolute")
                            .style("visibility", "hidden")
                            .style("padding", "5px")
                            .style("border-radius", "3px")
                            .style("background-color", '#fff')
                            .style("color", '#000')
                            .classed("shadow", true);
                        //.text("");
                    }
                    if (firstDraw) {
                        svg.append("g")
                            .classed('axis', true)
                            .classed('x-axis', true);
                        svg.append("g")
                            .classed('axis', true)
                            .classed('y-axis', true);
                    }
                    d3.select('g.x-axis')
                        .call(xAxis);
                    d3.select('g.y-axis')
                        .call(yAxis);
                    setFirstDraw(false);
                }
            })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [startDate, endDate]);
    let graphData = null;
    let barData = null;
    if (light.length > 0) {
        graphData = React.createElement(ResponsiveLine, { data: light, xScale: {
                type: 'time'
            }, yScale: {
                type: 'linear', min: 'auto', max: 'auto'
            }, axisBottom: {
                legend: "Date", legendOffset: 40, tickSize: 5, tickPadding: 5, format: d => moment(d).format('HH:mm DD/MM')
            }, axisLeft: { legend: "Luight (lux)", legendOffset: -30, tickSize: 5, tickPadding: 5 }, margin: { top: 40, right: 150, bottom: 50, left: 50 }, enableSlices: "x", legends: [
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] });
    }
    return (React.createElement("div", { className: "dashboard-toilet-frequency" },
        React.createElement("div", { className: "light__header shadow corners" },
            React.createElement("div", { className: "light__titleTextContainer" },
                React.createElement("h1", { style: { textAlign: 'center' } }, "Light")),
            React.createElement("div", { className: "light__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 2, maximumDays: maxWindow, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "light__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "light__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "light__graphContainer shadow corners" },
            React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, graphData)))));
}
export default Light;
