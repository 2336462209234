import * as React from 'react';
import './RiskScore.css';
import MultiChoice from './complex/multiChoice/multiChoice';
import TrueFalse from './complex/trueFalse/trueFalse';
import ColouredHeader from './complex/colouredHeader/colouredHeader';
import SubmitButton from './basic/submitButton/submitButton';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { authProvider } from '../../../authProvider';
const headRoom = "-20px";
function RiskScore(props) {
    const inputs = [];
    const [submitForm, setSubmitForm] = useState(false);
    const [editable, setEditable] = useState(null);
    const [existingScores, setExistingScores] = useState(null);
    const [hasError, setErrors] = useState(false);
    const [scorePing, reloadScores] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            console.log('start fetch');
            const riskScoreRes = await fetch(`/api/serviceUser/${props.acsis}/riskScore`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            await Promise.all([
                riskScoreRes.json().then((riskScoreRes) => {
                    if (riskScoreRes.scores == null) {
                        setEditable(true);
                        console.log("Editable Risk Score");
                    }
                    else {
                        console.log("Risk scores have been found, not editable");
                        setExistingScores(riskScoreRes.scores);
                        setEditable(false);
                    }
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, [scorePing]);
    useEffect(() => {
        async function submit() {
            if (submitForm === false) {
                return;
            }
            console.log('Submitting risk score form');
            toast.success("Risk score is being submitted...");
            let postInputArray = [...inputs];
            for (let i = 0; i < postInputArray.length; i++) {
                postInputArray[i].validateFunction = undefined;
                delete postInputArray[i].validateFunction;
            }
            let sendData = await fetch(`/api/serviceUser/${props.acsis}/riskScore`, {
                method: "POST",
                body: JSON.stringify(postInputArray),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken
                }
            }).then(async (res) => {
                return await res.json();
            }).catch(e => console.error('Could not submit risk scores', e));
            if (sendData) {
                toast.success(`Referral complete!`);
                if (props.setReferralState) {
                    console.log(sendData);
                    if (props.setCohortAllocation && sendData && sendData.cohort) {
                        props.setCohortAllocation(sendData.cohort);
                    }
                    ;
                    props.setReferralState(2);
                }
                else {
                    reloadScores(!scorePing);
                }
            }
            else {
                toast.error('Could not save Risk Score form, please try again.');
            }
        }
        submit();
    }, [submitForm]);
    // MOBILITY
    const [mobilitySelection, setMobilitySelection] = useState(-1);
    const [mobilitySelectionMissed, setMobilitySelectionMissed] = useState(false);
    const [fallSelection, setFallSelection] = useState(-1);
    const [fallSelectionMissed, setFallSelectionMissed] = useState(false);
    const [mobilityReason, setMobilityReason] = useState("");
    const [mobilityReasonMissed, setMobilityReasonMissed] = useState(false);
    const [wwpSelection, setWwpSelection] = useState(-1);
    const [wwpSelectionMissed, setWwpSelectionMissed] = useState(false);
    inputs.push({
        name: 'Mobility',
        type: 'multiChoice',
        value: mobilitySelection,
        validateFunction: setMobilitySelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Fall',
        type: 'multiChoice',
        value: fallSelection,
        validateFunction: setFallSelectionMissed,
        children: []
    });
    //inputs.push({
    //    name: 'Falls Reason', 
    //    type: 'text',
    //    value: -1,
    //    stringValue: mobilityReason,
    //    validateFunction: setMobilityReasonMissed,
    //    children: []
    //});
    inputs.push({
        name: 'WWP',
        type: 'multiChoice',
        value: wwpSelection,
        validateFunction: setWwpSelectionMissed,
        children: []
    });
    // SELF CARE
    const [personalCareSelection, setPersonalCareSelection] = useState(-1);
    const [personalCareSelectionMissed, setPersonalCareSelectionMissed] = useState(false);
    const [nutHydSelection, setNutHydSelection] = useState(-1);
    const [nutHydSelectionMissed, setNutHydSelectionMissed] = useState(false);
    const [medManagementSelection, setMedManagementSelection] = useState(-1);
    const [medManagementSelectionMissed, setMedManagementSelectionMissed] = useState(false);
    const [addictionSelection, setAddictionSelection] = useState(-1);
    const [addictionSelectionMissed, setAddictionSelectionMissed] = useState(false);
    const [utiSelection, setUtiSelection] = useState(-1);
    const [utiSelectionMissed, setUtiSelectionMissed] = useState(false);
    inputs.push({
        name: 'Personal Care',
        type: 'multiChoice',
        value: personalCareSelection,
        validateFunction: setPersonalCareSelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Nutrition and Hydration',
        type: 'multiChoice',
        value: nutHydSelection,
        validateFunction: setNutHydSelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Medication Management',
        type: 'multiChoice',
        value: medManagementSelection,
        validateFunction: setMedManagementSelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Addiction',
        type: 'multiChoice',
        value: addictionSelection,
        validateFunction: setAddictionSelectionMissed,
        children: []
    });
    inputs.push({
        name: 'UTI',
        type: 'multiChoice',
        value: utiSelection,
        validateFunction: setUtiSelectionMissed,
        children: []
    });
    //SOCIAL ISOLATION
    const [socialIsolation1Selection, setSocialIsolation1Selection] = useState(-1);
    const [socialIsolation1SelectionMissed, setSocialIsolation1SelectionMissed] = useState(false);
    const [socialIsolation2Selection, setSocialIsolation2Selection] = useState(-1);
    const [socialIsolation2SelectionMissed, setSocialIsolation2SelectionMissed] = useState(false);
    const [socialIsolation3Selection, setSocialIsolation3Selection] = useState(-1);
    const [socialIsolation3SelectionMissed, setSocialIsolation3SelectionMissed] = useState(false);
    inputs.push({
        name: 'Social Isolation 1',
        type: 'trueFalse',
        value: socialIsolation1Selection,
        validateFunction: setSocialIsolation1SelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Social Isolation 2',
        type: 'trueFalse',
        value: socialIsolation2Selection,
        validateFunction: setSocialIsolation2SelectionMissed,
        children: []
    });
    inputs.push({
        name: 'Social Isolation 3',
        type: 'trueFalse',
        value: socialIsolation3Selection,
        validateFunction: setSocialIsolation3SelectionMissed,
        children: []
    });
    // ENVIRONMENTAL
    const [hsHomeSelection, setHsHomeSelection] = useState(-1);
    const [hsHomeSelectionMissed, setHsHomeSelectionMissed] = useState(false);
    inputs.push({
        name: 'HS of Home',
        type: 'multiChoice',
        value: hsHomeSelection,
        validateFunction: setHsHomeSelectionMissed,
        children: []
    });
    // COGNITIVE DECLINE
    const [cogInclusion, setCogInclusion] = useState(-1);
    const [cogInclusionMissed, setCogInclusionMissed] = useState(false);
    const [cogSeveritySelection, setCogSeveritySelection] = useState(-1);
    const [cogSeveritySelectionMissed, setCogSeveritySelectionMissed] = useState(false);
    const [cogDeclineSelection, setCogDeclineSelection] = useState(-1);
    const [cogDeclineSelectionMissed, setCogDeclineSelectionMissed] = useState(false);
    const [cogDiagnosisText, setCogDiagnosisText] = useState("");
    const [cogDiagnosisTextMissed, setCogDiagnosisTextMissed] = useState(false);
    inputs.push({
        name: 'Cognitive Decline',
        type: 'trueFalse',
        value: cogInclusion,
        validateFunction: setCogInclusionMissed,
        children: [
            {
                name: 'Cognitive Severity',
                type: 'multiChoice',
                value: cogSeveritySelection,
                validateFunction: setCogSeveritySelectionMissed
            },
            {
                name: 'Cognitive Decline Rate',
                type: 'multiChoice',
                value: cogDeclineSelection,
                validateFunction: setCogDeclineSelectionMissed
            },
            //{
            //    name: 'Cognitive Diagnosis',
            //    type: 'text',
            //    value: -1,
            //    stringValue: cogDiagnosisText,
            //    validateFunction: setCogDiagnosisTextMissed,
            //}
        ]
    });
    // PHYSICAL DECLINE
    const [phyInclusion, setPhyInclusion] = useState(-1);
    const [phyInclusionMissed, setPhyInclusionMissed] = useState(false);
    const [phySeveritySelection, setPhySeveritySelection] = useState(-1);
    const [phySeveritySelectionMissed, setPhySeveritySelectionMissed] = useState(false);
    const [phyDeclineSelection, setPhyDeclineSelection] = useState(-1);
    const [phyDeclineSelectionMissed, setPhyDeclineSelectionMissed] = useState(false);
    const [phyDiagnosisText, setPhyDiagnosisText] = useState("");
    const [phyDiagnosisTextMissed, setPhyDiagnosisTextMissed] = useState(false);
    inputs.push({
        name: 'Physical Decline',
        type: 'trueFalse',
        value: phyInclusion,
        validateFunction: setPhyInclusionMissed,
        children: [
            {
                name: 'Physical Severity',
                type: 'multiChoice',
                value: phySeveritySelection,
                validateFunction: setPhySeveritySelectionMissed
            },
            {
                name: 'Physical Decline Rate',
                type: 'multiChoice',
                value: phyDeclineSelection,
                validateFunction: setPhyDeclineSelectionMissed
            },
            //{
            //    name: 'Physical Diagnosis',
            //    type: 'text',
            //    value: -1,
            //    stringValue: phyDiagnosisText,
            //    validateFunction: setPhyDiagnosisTextMissed,
            //}
        ]
    });
    // SENSORY DECLINE
    const [senInclusion, setSenInclusion] = useState(-1);
    const [senInclusionMissed, setSenInclusionMissed] = useState(false);
    const [senSeveritySelection, setSenSeveritySelection] = useState(-1);
    const [senSeveritySelectionMissed, setSenSeveritySelectionMissed] = useState(false);
    const [senDeclineSelection, setSenDeclineSelection] = useState(-1);
    const [senDeclineSelectionMissed, setSenDeclineSelectionMissed] = useState(false);
    const [senDiagnosisText, setSenDiagnosisText] = useState("");
    const [senDiagnosisTextMissed, setSenDiagnosisTextMissed] = useState(false);
    inputs.push({
        name: 'Sensory Decline',
        type: 'trueFalse',
        value: senInclusion,
        validateFunction: setSenInclusionMissed,
        children: [
            {
                name: 'Sensory Severity',
                type: 'multiChoice',
                value: senSeveritySelection,
                validateFunction: setSenSeveritySelectionMissed
            },
            {
                name: 'Sensory Decline Rate',
                type: 'multiChoice',
                value: senDeclineSelection,
                validateFunction: setSenDeclineSelectionMissed
            },
            //{
            //    name: 'Sensory Diagnosis',
            //    type: 'text',
            //    value: -1,
            //    stringValue: senDiagnosisText,
            //    validateFunction: setSenDiagnosisTextMissed,
            //}
        ]
    });
    // OTHER DECLINE
    const [othInclusion, setOthInclusion] = useState(-1);
    const [othInclusionMissed, setOthInclusionMissed] = useState(false);
    const [othSeveritySelection, setOthSeveritySelection] = useState(-1);
    const [othSeveritySelectionMissed, setOthSeveritySelectionMissed] = useState(false);
    const [othDeclineSelection, setOthDeclineSelection] = useState(-1);
    const [othDeclineSelectionMissed, setOthDeclineSelectionMissed] = useState(false);
    const [othDiagnosisText, setOthDiagnosisText] = useState("");
    const [othDiagnosisTextMissed, setOthDiagnosisTextMissed] = useState(false);
    inputs.push({
        name: 'Other Decline',
        type: 'trueFalse',
        value: othInclusion,
        validateFunction: setOthInclusionMissed,
        children: [
            {
                name: 'Other Severity',
                type: 'multiChoice',
                value: othSeveritySelection,
                validateFunction: setCogSeveritySelectionMissed
            },
            {
                name: 'Other Decline Rate',
                type: 'multiChoice',
                value: othDeclineSelection,
                validateFunction: setOthDeclineSelectionMissed
            },
            //{
            //    name: 'Other Diagnosis',
            //    type: 'text',
            //    value: -1,
            //    stringValue: othDiagnosisText,
            //    validateFunction: setOthDiagnosisTextMissed,
            //}
        ]
    });
    // START OF SUBMIT LOGIC
    return (React.createElement("div", { className: "dashboard-risk-score__parentContainer" },
        React.createElement("div", { className: "dashboard-risk-score__container" },
            React.createElement(ColouredHeader, { size: 5, title: "Mobility" }),
            React.createElement(MultiChoice, { question: "Mobility", answers: [
                    "Independent without walking aids",
                    "Independent with walking aids",
                    "Ocasional assistance required. e.g. use of grab rails. Generally independent with/without walking aids",
                    "Generally dependent on walking aids or other assistance e.g. bed lever, mowbray",
                    "Weight bearing but assistance (or use of aid) always required to mobilise e.g. daily assistance with tranfers out of bed"
                ], selection: mobilitySelection, setSelection: setMobilitySelection, missed: mobilitySelectionMissed, setMissed: setMobilitySelectionMissed, editable: editable, existingScores: existingScores, questionName: "Mobility" }),
            React.createElement(MultiChoice, { question: "Falls", answers: [
                    "Very low risk of falls",
                    "Low risk of falls",
                    "At risk of falls",
                    "Conveyance to hospital within the last 2 months but with no admission",
                    "Fall(s) resulting in hospital admission(s) within the last 2 months"
                ], selection: fallSelection, setSelection: setFallSelection, missed: fallSelectionMissed, setMissed: setFallSelectionMissed, editable: editable, existingScores: existingScores, questionName: "Fall" }),
            React.createElement(MultiChoice, { question: "Routines", answers: [
                    "No known incidents",
                    "Evidence of low number of incidents",
                    "Evidence of occasional incidents",
                    "Evidence of regular incidents",
                    "Evidence of regular incidents including risk of harm e.g. leaving property at night"
                ], selection: wwpSelection, setSelection: setWwpSelection, missed: wwpSelectionMissed, setMissed: setWwpSelectionMissed, additionalInfo: "Walking with purpose is the act of wandering due to confusion, anxiety or as a symptom of dementia", bottom: headRoom, editable: editable, existingScores: existingScores, questionName: "WWP" }),
            React.createElement(ColouredHeader, { size: 5, title: "Self Care" }),
            React.createElement(MultiChoice, { question: "Ability to maintain personal care", answers: [
                    "No reliance on support/others to meet need",
                    "Some reliance on support / others to meet need e.g. informal carer or aids",
                    "Assistance required at least 3 days a week (formal/informal care) to meet need",
                    "Daily package of care or daily assistance required to meet need",
                    "Complete reliance on support/others to meet need"
                ], selection: personalCareSelection, setSelection: setPersonalCareSelection, missed: personalCareSelectionMissed, setMissed: setPersonalCareSelectionMissed, editable: editable, existingScores: existingScores, questionName: "Personal Care" }),
            React.createElement(MultiChoice, { question: "Ability to maintain nutrition and hydration", answers: [
                    "No reliance on support/others to meet need",
                    "Some reliance on support / others to meet need e.g. prep meals or shopping",
                    "Assistance required at least 3 days a week (formal/informal care) to meet need",
                    "Daily package of care or daily assistance required to meet need",
                    "Complete reliance on support/others to meet need"
                ], selection: nutHydSelection, setSelection: setNutHydSelection, missed: nutHydSelectionMissed, setMissed: setNutHydSelectionMissed, editable: editable, existingScores: existingScores, questionName: "Nutrition and Hydration" }),
            React.createElement(MultiChoice, { question: "UTI", answers: [
                    "No diagnosis in previous 3 years",
                    "One diagnosis in the previous 3 years",
                    "One diagnosis in the previous year (non recurrent)",
                    "Current/previous (within the last year) diagnosis of rUTI",
                    "Multiple diagnosis of rUTI in within the last 3 years"
                ], selection: utiSelection, setSelection: setUtiSelection, missed: utiSelectionMissed, setMissed: setUtiSelectionMissed, additionalInfo: "Recurring Urinary Tract Infection (rUTI): 3 positive tests in 12 months, or, 2 in 6 months", editable: editable, existingScores: existingScores, questionName: "UTI" }),
            React.createElement(MultiChoice, { question: "Medication management", answers: [
                    "Independently managed",
                    "Managed with support e.g. dosset box / AT",
                    "Prompt needed",
                    "Support daily with prompt / assist",
                    "Administered by others"
                ], selection: medManagementSelection, setSelection: setMedManagementSelection, missed: medManagementSelectionMissed, setMissed: setMedManagementSelectionMissed, editable: editable, existingScores: existingScores, questionName: "Medication Management" }),
            React.createElement(TrueFalse, { question: "Dependance on non-prescription drugs and/or alcohol", answers: [
                    "True",
                    "False"
                ], selection: addictionSelection, setSelection: setAddictionSelection, missed: addictionSelectionMissed, setMissed: setAddictionSelectionMissed, inverse: true, bottom: headRoom, editable: editable, existingScores: existingScores, questionName: "Addiction" }),
            React.createElement(ColouredHeader, { size: 0, title: "Social Isolation" }),
            React.createElement(TrueFalse, { question: "SU is not content with their friendships and relationships", answers: [
                    "True",
                    "False"
                ], selection: socialIsolation1Selection, setSelection: setSocialIsolation1Selection, missed: socialIsolation1SelectionMissed, setMissed: setSocialIsolation1SelectionMissed, inverse: true, editable: editable, existingScores: existingScores, questionName: "Social Isolation 1" }),
            React.createElement(TrueFalse, { question: "SU does not have enough people they feel comfortable asking for support from at any time", answers: [
                    "True",
                    "False"
                ], selection: socialIsolation2Selection, setSelection: setSocialIsolation2Selection, missed: socialIsolation2SelectionMissed, setMissed: setSocialIsolation2SelectionMissed, inverse: true, editable: editable, existingScores: existingScores, questionName: "Social Isolation 2" }),
            React.createElement(TrueFalse, { question: "SU feels isolated from others", answers: [
                    "True",
                    "False"
                ], selection: socialIsolation3Selection, setSelection: setSocialIsolation3Selection, missed: socialIsolation3SelectionMissed, setMissed: setSocialIsolation3SelectionMissed, inverse: true, bottom: headRoom, editable: editable, existingScores: existingScores, questionName: "Social Isolation 3" }),
            React.createElement(ColouredHeader, { size: 5, title: "Environmental" }),
            React.createElement(MultiChoice, { question: "Health and safety of home", answers: [
                    "Prompts or encouragement required to maintain a safe/comfortable home environment",
                    "Minimal support required to maintain a safe/comfortable home environment",
                    "Needs some supplementary support from others to maintain a comfortable environment",
                    "Needs some supplementary support from others to maintain a safe environment",
                    "Consistent and planned support from formal or informal carers to maintain a safe/comfortable environment"
                ], selection: hsHomeSelection, setSelection: setHsHomeSelection, missed: hsHomeSelectionMissed, setMissed: setHsHomeSelectionMissed, additionalInfo: "Personal or environmental, e.g. hoarding, heat and light, risk of leaving gas on etc", bottom: '60px', editable: editable, existingScores: existingScores, questionName: "HS of Home" }),
            React.createElement(TrueFalse, { question: "Current cognitive impairment diagnosis", answers: [
                    "True",
                    "False"
                ], selection: cogInclusion, setSelection: setCogInclusion, missed: cogInclusionMissed, setMissed: setCogInclusionMissed, inverse: true, bottom: cogInclusion === 0 ? headRoom : '20px', editable: editable, existingScores: existingScores, questionName: "Cognitive Decline" }),
            cogInclusion === 0 || isExpanded(existingScores, 'Cognitive Decline', 0) ?
                React.createElement(React.Fragment, null,
                    React.createElement(ColouredHeader, { size: 5, title: "Cognitive Impairment" }),
                    React.createElement(MultiChoice, { question: "Cognitive impairment severity", answers: [
                            "No support required to manage day to day life",
                            "",
                            "Occasional support required to manage day to day life",
                            "Regular support required to manage day to day life",
                            "Daily support required to manage day to day life"
                        ], selection: cogSeveritySelection, setSelection: setCogSeveritySelection, missed: cogSeveritySelectionMissed, setMissed: setCogSeveritySelectionMissed, additionalInfo: "e.g. ability to make decisions, how are they now?", editable: editable, existingScores: existingScores, questionName: "Cognitive Severity" }),
                    React.createElement(MultiChoice, { question: "Rate of cognitive decline caused by any current diagnosis", answers: [
                            "Slow: Gradual decline which is well managed",
                            "",
                            "Medium: Consistent and steady rate of decline which is well managed",
                            "",
                            "Fast: Unstable and unpredictable rate of decline"
                        ], selection: cogDeclineSelection, setSelection: setCogDeclineSelection, missed: cogDeclineSelectionMissed, setMissed: setCogDeclineSelectionMissed, additionalInfo: "we need to know how likely it is that the SU will require 24hr care within a year due to cognitive decline", editable: editable, existingScores: existingScores, questionName: "Cognitive Decline Rate", bottom: '80px' }))
                : null,
            React.createElement(TrueFalse, { question: "Current Physical impairment diagnosis", answers: [
                    "True",
                    "False"
                ], selection: phyInclusion, setSelection: setPhyInclusion, missed: phyInclusionMissed, setMissed: setPhyInclusionMissed, inverse: true, bottom: phyInclusion === 0 ? headRoom : '20px', additionalInfo: "Any condition that impacts on their physical condition e.g. cancer, dysphagia, osteoarthritis", editable: editable, existingScores: existingScores, questionName: "Physical Decline" }),
            phyInclusion === 0 || isExpanded(existingScores, 'Physical Decline', 0) ?
                React.createElement(React.Fragment, null,
                    React.createElement(ColouredHeader, { size: 5, title: "Physical Impairment" }),
                    React.createElement(MultiChoice, { question: "Physical impairment severity", answers: [
                            "No support required to manage day to day life",
                            "",
                            "Occasional support required to manage day to day life",
                            "Regular support required to manage day to day life",
                            "Daily support required to manage day to day life"
                        ], selection: phySeveritySelection, setSelection: setPhySeveritySelection, missed: phySeveritySelectionMissed, setMissed: setPhySeveritySelectionMissed, additionalInfo: "e.g. how are they now?", editable: editable, existingScores: existingScores, questionName: "Physical Severity" }),
                    React.createElement(MultiChoice, { question: "Rate of physical decline caused by any current diagnosis", answers: [
                            "Slow: Gradual decline which is well managed",
                            "",
                            "Medium: Consistent and steady rate of decline which is well managed",
                            "",
                            "Fast: Unstable and unpredictable rate of decline"
                        ], selection: phyDeclineSelection, setSelection: setPhyDeclineSelection, missed: phyDeclineSelectionMissed, setMissed: setPhyDeclineSelectionMissed, additionalInfo: "we need to know how likely it is that the SU will require 24hr care within a year due to physical decline", editable: editable, existingScores: existingScores, questionName: "Physical Decline Rate", bottom: '80px' }))
                : null,
            React.createElement(TrueFalse, { question: "Current Sensory impairment diagnosis", answers: [
                    "True",
                    "False"
                ], selection: senInclusion, setSelection: setSenInclusion, missed: senInclusionMissed, setMissed: setSenInclusionMissed, inverse: true, bottom: senInclusion === 0 ? headRoom : '20px', additionalInfo: "Any condition that impacts on their Sensory condition e.g. Autism spectrum disorder, macular degeneration, hearing loss", editable: editable, existingScores: existingScores, questionName: "Sensory Decline" }),
            senInclusion === 0 || isExpanded(existingScores, 'Sensory Decline', 0) ?
                React.createElement(React.Fragment, null,
                    React.createElement(ColouredHeader, { size: 5, title: "Sensory Impairment" }),
                    React.createElement(MultiChoice, { question: "Sensory impairment severity", answers: [
                            "No support required to manage day to day life",
                            "",
                            "Occasional support required to manage day to day life",
                            "Regular support required to manage day to day life",
                            "Daily support required to manage day to day life"
                        ], selection: senSeveritySelection, setSelection: setSenSeveritySelection, missed: senSeveritySelectionMissed, setMissed: setSenSeveritySelectionMissed, additionalInfo: "e.g. how are they now?", editable: editable, existingScores: existingScores, questionName: "Sensory Severity" }),
                    React.createElement(MultiChoice, { question: "Rate of sensory decline caused by any current diagnosis", answers: [
                            "Slow: Gradual decline which is well managed",
                            "",
                            "Medium: Consistent and steady rate of decline which is well managed",
                            "",
                            "Fast: Unstable and unpredictable rate of decline"
                        ], selection: senDeclineSelection, setSelection: setSenDeclineSelection, missed: senDeclineSelectionMissed, setMissed: setSenDeclineSelectionMissed, additionalInfo: "we need to know how likely it is that the SU will require 24hr care within a year due to sensory decline", editable: editable, existingScores: existingScores, questionName: "Sensory Decline Rate", bottom: '80px' }))
                : null,
            React.createElement(TrueFalse, { question: "Other current diagnosis", answers: [
                    "True",
                    "False"
                ], selection: othInclusion, setSelection: setOthInclusion, missed: othInclusionMissed, setMissed: setOthInclusionMissed, inverse: true, bottom: othInclusion === 0 ? headRoom : '20px', additionalInfo: "Any condition that impacts the service users day to day life that does not fit into any of the categories defined above", editable: editable, existingScores: existingScores, questionName: "Other Decline" }),
            othInclusion === 0 || isExpanded(existingScores, 'Other Decline', 0) ?
                React.createElement(React.Fragment, null,
                    React.createElement(ColouredHeader, { size: 5, title: "Other Impairment" }),
                    React.createElement(MultiChoice, { question: "Impairment severity", answers: [
                            "No support required to manage day to day life",
                            "",
                            "Occasional support required to manage day to day life",
                            "Regular support required to manage day to day life",
                            "Daily support required to manage day to day life"
                        ], selection: othSeveritySelection, setSelection: setOthSeveritySelection, missed: othSeveritySelectionMissed, setMissed: setOthSeveritySelectionMissed, additionalInfo: "e.g. how are they now?", editable: editable, existingScores: existingScores, questionName: "Other Severity" }),
                    React.createElement(MultiChoice, { question: "Rate of decline caused by any current diagnosis", answers: [
                            "Slow: Gradual decline which is well managed",
                            "",
                            "Medium: Consistent and steady rate of decline which is well managed",
                            "",
                            "Fast: Unstable and unpredictable rate of decline"
                        ], selection: othDeclineSelection, setSelection: setOthDeclineSelection, missed: othDeclineSelectionMissed, setMissed: setOthDeclineSelectionMissed, additionalInfo: "we need to know how likely it is that the SU will require 24hr care within a year due to their condition", editable: editable, existingScores: existingScores, questionName: "Other Decline Rate", bottom: '80px' }))
                : null,
            editable === true ?
                React.createElement(SubmitButton, { inputs: inputs, acsis: props.acsis, reloadScores: reloadScores, reloadValue: scorePing, submitFunction: setSubmitForm })
                : null)));
}
export default RiskScore;
function isExpanded(existingScores, questionName, expansionValue) {
    if (existingScores === null) {
        return false;
    }
    let index = existingScores.findIndex(element => element.name === questionName);
    if (index === -1) {
        return false;
    }
    if (existingScores[index].value === expansionValue) {
        return true;
    }
    return false;
}
