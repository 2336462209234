import * as React from 'react';
import { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { scaleTime, scaleBand, scaleOrdinal } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import './GanttChart.css';
import "./CommonGraph.css";
import moment from 'moment';
export function GanttChart(props) {
    //TODO: Properly assess whether we can move all data queries into a single record
    const d3Container = useRef(null);
    const [firstDraw, setFirstDraw] = useState(true);
    useEffect(() => {
        // We check we have all the data required, else we can't render it correctly, definitely needs a rework to make it more granular
        if (props.data && props.lightData && props.temperatureData && props.doorData && props.applianceData && props.network && props.applianceLocations && d3Container.current) {
            const svg = d3.select(d3Container.current);
            const margin = { left: 150, top: 10, right: 10, bottom: 20 };
            const width = 1400;
            const height = 400;
            // TODO: Replace this with a better update method
            //svg.selectAll('*').remove();
            let startDate, endDate;
            if (props.live) {
                startDate = moment().subtract(6, 'hours').toDate();
                endDate = moment().toDate();
            }
            else {
                startDate = props.timeFrame.startDate.toDate();
                endDate = props.timeFrame.endDate.toDate();
            }
            // Run through the javascript objects and generate dates, we do this for each datatype
            let startDates = [];
            let endDates = [];
            let preparedData = props.data.filter(d => d.location.toLowerCase() != 'fridge').map((d, i) => {
                if (d.startDateTime == null || d.endDateTime == null) {
                    return null;
                }
                let start = new Date(d.startDateTime);
                let end = new Date(d.endDateTime);
                startDates.push(start);
                endDates.push(end);
                return {
                    startDateTime: start,
                    endDateTime: end,
                    location: d.location,
                    duration: d.duration
                };
                // Filter out any nulls
            }).filter(d => d ? true : false);
            // Add a copy of the final datapoint to the end of the input data to make it look 
            if (preparedData.length > 0) {
                preparedData.unshift(JSON.parse(JSON.stringify(preparedData[0]), (k, v) => (k == 'startDateTime' || k == 'endDateTime') ? new Date(v) : v));
                preparedData[0].endDateTime = preparedData[0].startDateTime;
                //preparedData[0].duration = 0;
                preparedData.push(JSON.parse(JSON.stringify(preparedData[preparedData.length - 1]), (k, v) => (k == 'startDateTime' || k == 'endDateTime') ? new Date(v) : v));
                preparedData[preparedData.length - 1].endDateTime = endDate;
                //preparedData[preparedData.length - 1].duration = moment(preparedData[preparedData.length - 1].endDateTime).diff(moment(preparedData[preparedData.length - 1].startDateTime)) / 1000;
            }
            let preparedDoorData = [];
            if (props.doorData) {
                preparedDoorData = props.doorData.map((d, i) => {
                    if (d.startDateTime == null || d.endDateTime == null) {
                        return null;
                    }
                    let start = new Date(d.startDateTime);
                    let end = new Date(d.endDateTime);
                    // TODO: Decide if this is needed for door data.
                    //startDates.push(start);
                    //endDates.push(end);
                    return {
                        startDateTime: start,
                        endDateTime: end,
                        location: d.location,
                        duration: d.duration,
                        measValue: d.measValue
                    };
                }).filter(d => d ? true : false);
            }
            let preparedApplianceData = [];
            if (props.applianceData) {
                preparedApplianceData = props.applianceData.map((d, i) => {
                    if (d.startDateTime == null || d.endDateTime == null) {
                        return null;
                    }
                    let start = new Date(d.startDateTime);
                    let end = new Date(d.endDateTime);
                    // TODO: Decide if this is needed for door data.
                    //startDates.push(start);
                    //endDates.push(end);
                    return {
                        startDateTime: start,
                        endDateTime: end,
                        location: d.location,
                        duration: d.duration,
                        measValue: d.measValue
                    };
                }).filter(d => d ? true : false);
            }
            let preparedLightData = [];
            if (props.lightData) {
                preparedLightData = props.lightData.map((d, i) => {
                    if (d.startDateTime == null || d.endDateTime == null) {
                        return null;
                    }
                    let start = new Date(d.startDateTime);
                    let end = new Date(d.endDateTime);
                    // TODO: Decide if this is needed for light data.
                    //startDates.push(start);
                    //endDates.push(end);
                    return {
                        startDateTime: start,
                        endDateTime: end,
                        location: d.location,
                        duration: d.duration,
                        measValue: d.measValue
                    };
                }).filter(d => d ? true : false);
            }
            let preparedTemperatureData = [];
            if (props.temperatureData) {
                preparedTemperatureData = props.temperatureData.map((d, i) => {
                    if (d.startDateTime == null || d.endDateTime == null) {
                        return null;
                    }
                    let start = new Date(d.startDateTime);
                    let end = new Date(d.endDateTime);
                    // TODO: Decide if this is needed for temperature data.
                    //startDates.push(start);
                    //endDates.push(end);
                    return {
                        startDateTime: start,
                        endDateTime: end,
                        location: d.location,
                        duration: d.duration,
                        measValue: d.measValue
                    };
                }).filter(d => d ? true : false);
            }
            // Print some key debug data
            // TODO: Remove this before go live, or add a debug flag
            let x = scaleTime()
                .domain([startDate, endDate])
                .range([margin.left, width - margin.right])
                .clamp(true);
            // New yEntries to accomodate doors
            //let yArray = d3.map(props.data, d => d.location).keys();            
            let yArray = props.network.nodes.map(d => d.id);
            let yEntries = new Set(yArray);
            //let yEntries = d3.map(props.data, d => d.location).keys();
            let y = scaleBand()
                .domain(yEntries)
                .range([height - margin.bottom, margin.top]);
            //let yColour = scaleOrdinal(Tableau10)
            let yColour = scaleOrdinal(['#f1faee', '#a8dadc'])
                .domain(yEntries);
            let xAxis = (g) => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .transition().duration(1000)
                .call(axisBottom(x).ticks(width / 80).tickSizeOuter(0));
            let yAxis = (g) => g
                .attr("transform", `translate(${margin.left},0)`)
                .call(axisLeft(y).tickSize(0));
            if (firstDraw) {
                let backBar = svg.selectAll("g.backBar").data(yEntries);
                let backBarGroup = backBar.join("g")
                    .classed("backBar", true)
                    .attr("transform", (d) => `translate(0,${y(d)})`);
                backBarGroup.append("rect")
                    .attr("fill", (d) => yColour(d))
                    .attr("x", margin.left)
                    .attr("width", width - margin.right - margin.left)
                    //.attr("y", 5)
                    .attr("height", y.bandwidth())
                    .style('opacity', 0.1);
                backBarGroup.append("line")
                    .attr('x1', margin.left)
                    .attr('x2', width - margin.right)
                    .attr('stroke', '#aaa')
                    .style('stroke-dasharray', '3,3');
            }
            //let tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any> | d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
            if (firstDraw) {
                d3.select("body")
                    .append("div")
                    .classed('graph-tooltip', true)
                    .style("position", "absolute")
                    .style("visibility", "hidden")
                    .style("padding", "5px")
                    .style("border-radius", "3px")
                    .style("background-color", '#fff')
                    .style("color", '#000')
                    .classed("shadow", true);
                //.text("");
            }
            let tooltip = d3.select(".graph-tooltip");
            const barData = svg.selectAll("g.bar")
                .data(preparedApplianceData);
            const bar = barData.join((enter) => enter.append("g")
                .classed("bar", true)
                .append("rect")
                .attr("fill", (d) => {
                switch (d.location) {
                    case 'Bed Mat':
                        return '#264653';
                    case 'Fridge':
                        return '#48cae4';
                    case 'Kettle':
                        return '#e76f51';
                    case 'Microwave':
                        return '#e76f51';
                }
            }) // (d: GanttChartProcessedData) => yColour(d.location))
                .attr("x", (d) => x(d.startDateTime))
                .attr("width", (d) => x(d.endDateTime) - x(d.startDateTime)) // TODO: Get rid of these dirty casts, it moans about the fact it could be undefined after upgrading the @types/d3 package
                .attr("height", y.bandwidth() / 6)
                .attr("y", (d) => {
                let appLocation = Object.entries(props.applianceLocations.appliances).find(a => a[0].split('|')[1] == d.location);
                let loc = appLocation[1].location;
                return y(loc) + (y.bandwidth() / 6) + (d.location == 'Kettle' ? (y.bandwidth() / 6 * 3) : 0);
                /*switch (d.location) {
                    case 'Bed Mat':
                        return y.bandwidth() * 4 + margin.top + y.bandwidth() / 6;
                        //return 100;
                        break;
                    case 'Fridge':
                        return margin.top + y.bandwidth() / 6;
                        //return 100;
                        break;
                    case 'Kettle':
                        return margin.top + (y.bandwidth() / 6) * 4;
                        //return 100;
                        break;
                }*/
            })
                .attr("rx", 3)
                .attr("ry", 3)
                .on("mouseover", function (event, d) { tooltip.style("visibility", "visible"); })
                .on("mousemove", function (event, d) {
                tooltip.style("left", (event.pageX + 20) + "px")
                    .style("top", (event.pageY + 20) + "px")
                    .text(`${d.location} - ${moment(d.startDateTime).format('HH:mm:ss')} to ${moment(d.endDateTime).format('HH:mm:ss')}`);
            })
                .on("mouseout", function (d) { tooltip.style("visibility", "hidden"); }), (update) => {
                update.select('rect')
                    .on("mouseover", function (event, d) { tooltip.style("visibility", "visible"); })
                    .on("mousemove", function (event, d) {
                    tooltip.style("left", (event.pageX + 20) + "px")
                        .style("top", (event.pageY + 20) + "px")
                        .text(`${d.location} - ${moment(d.startDateTime).format('HH:mm:ss')} to ${moment(d.endDateTime).format('HH:mm:ss')}`);
                })
                    .on("mouseout", function (d) { tooltip.style("visibility", "hidden"); })
                    .attr("fill", (d) => {
                    switch (d.location) {
                        case 'Bed Mat':
                            return '#264653';
                        case 'Fridge':
                            return '#48cae4';
                        case 'Kettle':
                            return '#e76f51';
                    }
                }) // (d: GanttChartProcessedData) => yColour(d.location))
                    .transition().duration(1000).attr("x", (d) => x(d.startDateTime))
                    .attr("width", (d) => x(d.endDateTime) - x(d.startDateTime)) // TODO: Get rid of these dirty casts, it moans about the fact it could be undefined after upgrading the @types/d3 package
                    .attr("height", y.bandwidth() / 6)
                    .attr("y", (d) => {
                    let appLocation = Object.entries(props.applianceLocations.appliances).find(a => a[0].split('|')[1] == d.location);
                    let loc = appLocation[1].location;
                    return y(loc) + (y.bandwidth() / 6) + (d.location == 'Kettle' ? (y.bandwidth() / 6 * 3) : 0);
                    /*switch (d.location) {
                        case 'Bed Mat':
                            return y.bandwidth() * 4 + margin.top + y.bandwidth() / 6;
                            //return 100;
                            break;
                        case 'Fridge':
                            return margin.top + y.bandwidth() / 6;
                            //return 100;
                            break;
                        case 'Kettle':
                            return margin.top + (y.bandwidth() / 6) * 4;
                            //return 100;
                            break;
                    }*/
                })
                    .attr("rx", 3)
                    .attr("ry", 3);
                return update;
            }, (exit) => exit.remove());
            let lineFunc = d3.line()
                .x((d) => x(d.endDateTime))
                .y((d) => y(d.location) + y.bandwidth() / 2)
                .curve(d3.curveStepBefore);
            if (firstDraw) {
                svg.append("path")
                    .classed('location-path', true)
                    .attr("fill", "none")
                    .attr("stroke", "#aaa")
                    .attr("stroke-width", 1);
                svg.append("g")
                    .classed('axis', true)
                    .classed('x-axis', true);
                svg.append("g")
                    .classed('axis', true)
                    .classed('y-axis', true);
            }
            d3.select('path.location-path')
                .datum(preparedData)
                .transition().duration(1000)
                .attr("d", lineFunc(preparedData));
            d3.select('g.x-axis')
                .call(xAxis);
            d3.select('g.y-axis')
                .call(yAxis);
            if (props.events != null && props.events.length > 0) {
                let ev = svg.append('g').attr('transform', `translate(${margin.left + x(new Date(props.events[0]))} 5)`).classed('event', true);
                ev.append('path')
                    .attr('d', 'm 0 0 v 30 l 10 -10 h 10 v -20 h -20')
                    .attr("fill", "none")
                    .attr("stroke", "#aaa")
                    .attr("stroke-width", 2)
                    .attr('fill', d => props.events[1] ? '#80b918' : '#faa307')
                    .attr("fill-opacity", 0.6);
            }
            let selectionArea;
            let selectionX = 0;
            const dragstarted = function (event) {
                let mouse = d3.pointer(event, svg.node());
                console.log('dragstarted');
                selectionX = mouse[0];
                selectionArea = svg.append("rect")
                    .attr("x", selectionX)
                    .attr("y", margin.top)
                    .attr("width", 0)
                    .attr("height", height - margin.bottom - margin.top)
                    .attr("fill", "#222222")
                    .attr("fill-opacity", 0.3);
            };
            const dragged = function (event) {
                let mouse = d3.pointer(event, svg.node());
                let diff = mouse[0] - selectionX;
                if (diff >= 0) {
                    selectionArea.attr("width", diff);
                }
                else {
                    let absDiff = Math.abs(diff);
                    selectionArea.attr("x", selectionX - absDiff);
                    selectionArea.attr("width", absDiff);
                }
            };
            const dragended = function (event) {
                let mouse = d3.pointer(event, svg.node());
                selectionArea.remove();
                console.log("dragend", selectionX, mouse[0], mouse[0] - selectionX);
                if (!props.live) {
                    props.setLive(false);
                }
                if (Math.abs(mouse[0] - selectionX) > 20) {
                    if (mouse[0] > selectionX) {
                        props.setTimeFrame({
                            startDate: moment(x.invert(selectionX)), endDate: moment(x.invert(mouse[0]))
                        });
                    }
                    else {
                        props.setTimeFrame({
                            startDate: moment(x.invert(mouse[0])), endDate: moment(x.invert(selectionX))
                        });
                    }
                }
                /*
                props.data = props.data ? props.data.filter(d => {
                    return (new Date(d.endDateTime) > x.invert(selectionX)) && (new Date(d.startDateTime) < x.invert(d3.event.x));
                }) : null;
                */
            };
            //if (!props.live) {
            svg.call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));
            //}
            setFirstDraw(false);
        }
    }, [props.data, props.lightData, props.temperatureData, props.doorData, props.applianceData, props.applianceLocations, props.network, d3Container.current]);
    if (props.data == null) {
        return (React.createElement("div", { className: "gantt-chart-info" },
            React.createElement("p", null, "Loading...")));
    }
    else if (props.data == []) {
        return (React.createElement("div", { className: "gantt-chart-info" },
            React.createElement("p", null, "No data found for the provided timeframe.")));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { className: "gantt-chart", viewBox: "0 0 1450 450", ref: d3Container })));
    }
}
export default GanttChart;
