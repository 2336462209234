import * as React from 'react';
import { useState } from 'react';
import './styles.css';
import { toast } from 'react-toastify';
export function ConsentButton(props) {
    const [hover, setHover] = useState(false);
    let backgroundColor = '#d7d7d7';
    if (hover && props.consent !== true) {
        backgroundColor = '#929292';
    }
    else if (props.consent === true) {
        backgroundColor = '#80c300';
    }
    return (React.createElement("div", { className: "referral__consentButtonContainer corners shadow", style: {
            marginTop: '50px',
            marginBottom: '20px',
            backgroundColor
        }, onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false), onMouseDown: () => {
            if (props.consent !== true) {
                let dependanciesFilled = true;
                for (let i = 0; i < props.dependancies.length; i++) {
                    if (props.dependancies[i] === "") {
                        dependanciesFilled = false;
                    }
                }
                if (dependanciesFilled) {
                    props.setConsent(true);
                    if (props.consentText[0] && props.consentText[0] != "Submit") {
                        toast.success('Consent has been provided, please fill out the remainder of the risk score form below');
                    }
                }
                else {
                    toast.error('Please enter the required information before trying to submit');
                    props.setConsent(false);
                }
            }
        } }, function formatText() {
        let text = [];
        if (props.consentText.length != 1) {
            props.consentText.forEach(x => {
                text.push(React.createElement(React.Fragment, null,
                    React.createElement("h3", { className: "referral__consentButtonText" }, x),
                    React.createElement("br", null)));
            });
        }
        else {
            text.push(React.createElement("h3", { className: "referral__consentButtonText" }, props.consentText[0]));
        }
        return text;
    }()));
}
export default ConsentButton;
