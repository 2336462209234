import * as React from 'react';
import './styles.css';
import { GridComponent } from '../../basic/gridComponent/gridComponent';
import Tile from '../../basic/tile/tile';
import { colourScale5 } from '../../const';
import IndexTile from '../../basic/indexTile/indexTile';
export function ColouredHeader(props) {
    let children = [React.createElement(IndexTile, { header: true, text: props.title })];
    for (let i = 0; i < props.size; i++) {
        children.push(React.createElement(Tile, { header: true, index: i, color: colourScale5[i], editable: false, existingScores: null, questionName: "" }));
    }
    return (React.createElement(GridComponent, { size: props.size !== 0 ? props.size : 1 }, children));
}
export default ColouredHeader;
