import * as React from 'react';
import { Router, Link } from "@reach/router";
import "./ServiceUserSettings.css";
import "../TextSelect.css";
import { MedicationWindowSettings } from '../settingsPages/MedicationWindowSettings';
import RiskScore from '../settingsPages/riskScore/RiskScore';
import RuleConfig from '../settingsPages/ruleConfigWindow/ruleConfigWindow';
;
export function ServiceUserSettings(props) {
    return (React.createElement("div", { style: { height: '100%' } },
        React.createElement("div", { className: "service-user-settings__title" },
            React.createElement("h1", null, "Settings")),
        React.createElement("div", { className: "service-user-settings__content" },
            React.createElement("div", { className: "service-user-settings__tabContainer" },
                React.createElement(Link, { to: `/dashboard/${props.acsisID}/settings/riskScore`, style: { textDecoration: 'none', color: 'black' } },
                    React.createElement("div", { className: "service-user-settings__tab corners shadow" },
                        React.createElement("span", null, "Risk Score"))),
                React.createElement(Link, { to: `/dashboard/${props.acsisID}/settings/rule-config`, style: { textDecoration: 'none', color: 'black' } },
                    React.createElement("div", { className: "service-user-settings__tab corners shadow" },
                        React.createElement("span", null, "Rule config")))),
            React.createElement("div", { style: { height: '100%' } },
                React.createElement(Router, { style: { height: '100%' } },
                    React.createElement(MedicationWindowSettings, { path: "medicationWindows", acsis: props.acsisID }),
                    React.createElement(RiskScore, { path: "riskScore", acsis: props.acsisID }),
                    React.createElement(RuleConfig, { path: "rule-config", acsis: props.acsisID }))))));
}
export default ServiceUserSettings;
