import * as React from 'react';
import './styles.css';
import { GridComponent } from '../../basic/gridComponent/gridComponent';
import Tile from '../../basic/tile/tile';
import { colourScale5 } from '../../const';
import IndexTile from '../../basic/indexTile/indexTile';
export function MultiChoice(props) {
    let children = [React.createElement(IndexTile, { header: false, text: props.question, missed: props.missed, additionalInfo: props.additionalInfo })];
    for (let i = 0; i < props.answers.length; i++) {
        children.push(React.createElement(Tile, { header: false, index: i, text: props.answers[i], selection: props.selection, setSelection: props.setSelection, setMissed: props.setMissed, color: colourScale5[i], editable: props.editable, existingScores: props.existingScores, questionName: props.questionName }));
    }
    return (React.createElement(GridComponent, { size: 5, bottom: props.bottom }, children));
}
export default MultiChoice;
