import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { lowerSliderDrag, lowerSliderDragEnd, upperSliderDrag, upperSliderDragEnd } from './helpers';
function Slider(props) {
    const d3Container = useRef(null);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [minimumDate, setMinimumDate] = useState(moment());
    const [maximumDate, setmaximumDate] = useState(moment());
    const [isInitialised, setInitialised] = useState(false);
    let minimumSpan = props.minimumDays; // In Days?
    let maximumSpan = props.maximumDays; // In Days?
    let svgWidth = 0;
    let svgHeight = 0;
    useEffect(() => {
        if (props.dataInputObject != null) {
            setStartDate(moment(props.dataInputObject.globalMaxDate !== null ? props.dataInputObject.globalMaxDate : undefined));
            // setEndDate(moment().subtract(props.maximumDays * 3, 'days'));
            setEndDate(moment(props.dataInputObject.globalMinDate));
            setMinimumDate(moment(props.dataInputObject.globalMinDate));
            setmaximumDate(moment(props.dataInputObject.globalMaxDate !== null ? props.dataInputObject.globalMaxDate : undefined));
        }
    }, [props.dataInputObject]);
    function drawSlider() {
        if (props.dataInputObject === null) {
            return;
        }
        // Clear and resize the canvas
        const canvas = d3.select(d3Container.current);
        svgWidth = canvas.node().getBoundingClientRect().width;
        svgHeight = canvas.node().getBoundingClientRect().height;
        canvas.selectAll('*').remove();
        const MARGIN = { LEFT: 50, RIGHT: 50, TOP: 15, BOTTOM: 5 };
        const WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        const HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;
        // Is the slider hasnt yet been initialised, set the slider posaitions to their starting points >> will be the base range
        if (isInitialised === false) {
            if (moment().subtract(minimumSpan, 'days') < moment(props.dataInputObject.globalMinDate)) {
                props.setLowerSliderPos(moment(props.dataInputObject.globalMinDate));
            }
            else {
                props.setLowerSliderPos(moment().subtract(minimumSpan, 'days'));
            }
            setInitialised(true);
        }
        let numberOfTicks = WIDTH / 50;
        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
            .attr('id', 'canvas');
        // x scale for time
        let x = d3.scaleTime()
            .domain([endDate.toDate(), startDate.toDate()])
            .range([0, WIDTH]);
        // format the data points array
        let dataIndicators = [];
        for (let i = 0; i < props.dataInputObject.dataIndicators.length; i++) {
            dataIndicators.push([x(moment(props.dataInputObject.dataIndicators[i].startDateTime).toDate()), x(moment(props.dataInputObject.dataIndicators[i].endDateTime).toDate())]);
        }
        let minimumSpanInPixels = x(moment().toDate()) - x(moment().subtract(minimumSpan, 'days').toDate());
        let maximumSpanInPixels = x(moment().toDate()) - x(moment().subtract(maximumSpan, 'days').toDate());
        // BUILD FRAME FOR SLIDERS
        let frame = svg.append('g')
            .attr('position', 'relative');
        frame.append('g')
            .attr("transform", "translate(0,5)")
            .call(d3.axisBottom(x)
            .ticks(numberOfTicks)
            .tickSizeOuter(0))
            .selectAll("text")
            .attr("y", 12)
            .attr("x", 4)
            .attr("transform", "rotate(45)")
            .style("text-anchor", "start");
        frame.append('rect')
            .attr('width', WIDTH + 6)
            .attr('height', 6)
            .attr("transform", "translate(-3,0)")
            .attr('fill', '#aaaaaa')
            .attr('rx', 3);
        appendDataIndicators(dataIndicators, frame, x(endDate), x(startDate));
        // Slider components
        let selectedIndicator = frame.append('rect')
            .attr('width', x(props.upperSliderPos.toDate()) - x(props.lowerSliderPos.toDate()))
            .attr('x', x(props.lowerSliderPos.toDate()))
            .attr('height', 7)
            .attr("transform", "translate(-3,-0.5)")
            .attr('fill', '#94aae1')
            .attr('opacity', 0.5)
            .attr('rx', 3);
        let lowerSlider = frame.append('circle')
            .attr('r', '7')
            .attr("transform", `translate(0,2.5)`)
            .attr("cx", x(props.lowerSliderPos.toDate()))
            .attr('fill', '#f6f6f6')
            .attr('stroke', '#333333')
            .attr('stroke-width', 1.5)
            .attr('class', 'lowerSlider');
        // Handle drag events for the lower slider
        canvas.selectAll('.lowerSlider')
            .call(d3.drag()
            .on('start', () => {
            lowerSlider.attr('fill', '#d0d4d4');
        })
            .on('drag', (event) => { lowerSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, props.upperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH); })
            .on('end', (event) => { lowerSliderDragEnd(event, x, lowerSlider, props.setLowerSliderPos, props.upperSliderPos, props.setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH); }));
        let upperSlider = frame.append('circle')
            .attr('r', '7')
            .attr("transform", "translate(0,2.5)")
            .attr("cx", x(props.upperSliderPos.toDate()))
            .attr('fill', '#f6f6f6')
            .attr('stroke', '#333333')
            .attr('stroke-width', 1.5)
            .attr('class', 'upperSlider');
        // Handle drag events for the upper slider
        canvas.selectAll('.upperSlider')
            .call(d3.drag()
            .on('start', () => {
            upperSlider.attr('fill', '#d0d4d4');
        })
            .on('drag', (event) => { upperSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, props.lowerSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH); })
            .on('end', (event) => { upperSliderDragEnd(event, x, upperSlider, props.setLowerSliderPos, props.lowerSliderPos, props.setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH); }));
        canvas.on('wheel.zoom', (event) => { handleScroll(x, drawSlider, event, minimumDate, maximumDate, startDate, endDate, setStartDate, setEndDate, props.lowerSliderPos, props.upperSliderPos, minimumSpanInPixels, maximumSpanInPixels); });
    }
    useEffect(() => {
        drawSlider();
    });
    useEffect(() => {
        if (isInitialised === false || startDate == null || endDate == null) {
            return;
        }
        if (props.upperSliderPos < props.lowerSliderPos) {
            if (maximumDate.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            }
            else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        }
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') >= props.maximumDays * 24) {
            props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.maximumDays, 'days'));
            setStartDate(props.lowerSliderPos.clone().add(props.maximumDays, 'days'));
        }
        ;
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
            if (maximumDate.diff(props.upperSliderPos, 'hours') < props.minimumDays * 24) {
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            }
            else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        }
        ;
        if (props.lowerSliderPos <= endDate) {
            // todo: SORT THIS
            setEndDate(props.lowerSliderPos);
        }
        ;
        drawSlider();
    }, [props.pingLowerUpdate]);
    useEffect(() => {
        if (isInitialised === false || startDate == null || endDate == null) {
            return;
        }
        if (props.upperSliderPos < props.lowerSliderPos) {
            if (props.upperSliderPos.diff(minimumDate, 'hours') <= props.minimumDays * 24) {
                props.setUpperSliderPos(minimumDate.clone().add(props.minimumDays, 'days'));
                props.setLowerSliderPos(minimumDate);
                setEndDate(minimumDate);
            }
            else {
                props.setLowerSliderPos(props.upperSliderPos.clone().subtract(props.minimumDays, 'days'));
                setEndDate(props.upperSliderPos.clone().subtract(props.minimumDays, 'days'));
            }
        }
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') >= props.maximumDays * 24) {
            props.setLowerSliderPos(props.upperSliderPos.clone().subtract(props.maximumDays, 'days'));
            setStartDate(props.upperSliderPos);
        }
        ;
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
            if (maximumDate.diff(props.upperSliderPos, 'hours') < props.minimumDays * 24) {
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            }
            else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        }
        ;
        if (props.upperSliderPos >= startDate) {
            setStartDate(props.upperSliderPos);
        }
        ;
        drawSlider();
    }, [props.pingUpperUpdate]);
    return (React.createElement("svg", { className: "slider", ref: d3Container }));
}
function handleScroll(x, drawSlider, event, minimumDate, maximumDate, startDate, endDate, setStartDate, setEndDate, lowerSliderPos, upperSliderPos, minimumSpanInPixels, maximumSpanInPixels) {
    if (minimumDate == undefined || maximumDate == undefined) {
        console.log('undefined error');
        return;
    }
    let hourRange = startDate.diff(endDate, 'hours');
    let scrollDirection = 0;
    if (event.deltaY > 0) {
        scrollDirection = 1;
    }
    else if (event.deltaY < 0) {
        scrollDirection = -1;
    }
    if (scrollDirection === 0) {
        return;
    }
    let totalDelta = 0;
    if (hourRange > 8760) { // year >> 3 months
        totalDelta = 2190;
    }
    else if (hourRange > 4380) { // 6 months >> 1 months
        totalDelta = 730;
    }
    else if (hourRange > 2190) { // 3 months >> 10 days
        totalDelta = 240;
    }
    else if (hourRange > 730) { // 1 months >> 3 days
        totalDelta = 72;
    }
    else if (hourRange > 168) { // 1 week >> 1 day
        totalDelta = 24;
    }
    else if (hourRange > 24) { // 1 day >> 3 hours
        totalDelta = 3;
    }
    else {
        totalDelta = 1; // else 1 hour
    }
    let totalDataInPixels = x(moment()) - x(moment().subtract(totalDelta, 'hours'));
    // Twat, your datetimes are the wrong way round, this needs to be sorted
    let { min, max } = scrollHandler(x(minimumDate), x(maximumDate), x(endDate), x(startDate), x(lowerSliderPos), x(upperSliderPos), scrollDirection, totalDataInPixels, minimumSpanInPixels * 2, maximumSpanInPixels * 3);
    setEndDate(moment(x.invert(min)));
    setStartDate(moment(x.invert(max)));
    drawSlider();
}
function scrollHandler(globalMinimum, globalMaximum, localMinimum, localMaximum, selectedMinimum, selectedMaximum, scrollDirection, delta, minimumRange, maximumRange) {
    let selectionCenter = selectedMinimum + ((selectedMaximum - selectedMinimum) / 2);
    let selectedRage = selectedMaximum - selectedMinimum;
    let localRange = localMaximum - localMinimum;
    let localCenter = localMinimum + (localRange / 2);
    let localCenterBufferLower = localCenter - (localRange * 0.1);
    let localCenterBufferUpper = localCenter + (localRange * 0.1);
    let uncheckedLocalMinimumDelta = 0;
    let uncheckedLocalMaximumDelta = 0;
    let localMinimumMultiplier = 1;
    let localMaximumMultiplier = 1;
    let globalMaxRange = globalMaximum - globalMinimum;
    if (scrollDirection === 1) {
        localMinimumMultiplier = 1;
        localMaximumMultiplier = -1;
        if (selectionCenter < localCenterBufferLower) {
            uncheckedLocalMaximumDelta = delta;
        }
        else if (selectionCenter > localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta;
        }
        else if (selectionCenter >= localCenterBufferLower && selectionCenter <= localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta / 2;
            uncheckedLocalMaximumDelta = delta / 2;
        }
        else {
            console.warn('Uncaught on scroll in position catch');
        }
    }
    else {
        localMinimumMultiplier = -1;
        localMaximumMultiplier = 1;
        if (selectionCenter < localCenterBufferLower) {
            uncheckedLocalMinimumDelta = delta;
        }
        else if (selectionCenter > localCenterBufferUpper) {
            uncheckedLocalMaximumDelta = delta;
        }
        else if (selectionCenter >= localCenterBufferLower && selectionCenter <= localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta / 2;
            uncheckedLocalMaximumDelta = delta / 2;
        }
        else {
            console.warn('Uncaught on scroll out position catch');
        }
    }
    if (localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier) > globalMaximum) {
        // If right spills, get overflow, check if it can be added to right, return the maximum amounts
        let maximumDeltaMax = globalMaximum - localMaximum;
        let remainingDelta = uncheckedLocalMaximumDelta - maximumDeltaMax;
        uncheckedLocalMaximumDelta = maximumDeltaMax;
        if (localMinimum + ((uncheckedLocalMinimumDelta + remainingDelta) * localMinimumMultiplier) < globalMinimum) {
            let maximumDeltaMin = localMinimum - globalMinimum;
            uncheckedLocalMinimumDelta = maximumDeltaMin;
        }
        else {
            uncheckedLocalMinimumDelta += remainingDelta;
        }
    }
    else if (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier) < globalMinimum) {
        // If left spills, get overflow, check if it can be added to right, return the maximum amounts
        let maximumDeltaMin = localMinimum - globalMinimum;
        let remainingDelta = uncheckedLocalMinimumDelta - maximumDeltaMin;
        uncheckedLocalMinimumDelta = maximumDeltaMin;
        if (localMaximum + ((uncheckedLocalMaximumDelta + remainingDelta) * localMaximumMultiplier) > globalMaximum) {
            let maximumDeltaMax = globalMaximum - localMaximum;
            uncheckedLocalMaximumDelta = maximumDeltaMax;
        }
        else {
            uncheckedLocalMaximumDelta += remainingDelta;
        }
    }
    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) >= globalMaxRange && scrollDirection === -1) {
        console.log('maximum zoom out reached');
        return {
            min: localMinimum,
            max: localMaximum
        };
    }
    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) <= minimumRange && scrollDirection === 1) {
        console.log('minimum zoom in reached');
        return {
            min: localMinimum,
            max: localMaximum
        };
    }
    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) <= selectedRage * 1.2 && scrollDirection === 1) {
        console.log('zoom in blocked');
        return {
            min: localMinimum,
            max: localMaximum
        };
    }
    console.log(`${globalMinimum} ${localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)} ${localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)} ${globalMaximum}`);
    return {
        min: localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier),
        max: localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)
    };
}
function appendDataIndicators(data, frame, localMin, localMax) {
    for (let i = 0; i < data.length; i++) {
        let start = data[i][0];
        let end = data[i][1];
        let plot = false;
        if (start >= localMin && end <= localMax) {
            plot = true;
        }
        else if (start < localMin && end > localMin && end <= localMax) {
            start = localMin;
            plot = true;
        }
        else if (start >= localMin && start < localMax && end > localMax) {
            end = localMax + 6;
            plot = true;
        }
        else if (start <= localMin && end >= localMax) {
            start = localMin;
            end = localMax + 6;
            plot = true;
        }
        if (plot === true) {
            frame.append('rect')
                .attr('width', (end - start))
                .attr('height', 6)
                .attr('x', start)
                .attr("transform", "translate(-3,0)")
                .attr('fill', '#568f56')
                .attr('rx', 3);
        }
    }
}
export default Slider;
