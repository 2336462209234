import * as React from 'react';
import './ImageCircle.css';
import { Link } from "@reach/router";
export var ImageCircleBorder;
(function (ImageCircleBorder) {
    ImageCircleBorder[ImageCircleBorder["Grey"] = 0] = "Grey";
    ImageCircleBorder[ImageCircleBorder["Green"] = 1] = "Green";
    ImageCircleBorder[ImageCircleBorder["Orange"] = 2] = "Orange";
    ImageCircleBorder[ImageCircleBorder["Red"] = 3] = "Red";
})(ImageCircleBorder || (ImageCircleBorder = {}));
export function ImageCircle(props) {
    let wrapperClass = 'image-circle__wrapper';
    if (props.acsisID != null) {
        return (React.createElement(Link, { to: `/dashboard/${props.acsisID}` },
            React.createElement("div", { className: wrapperClass },
                React.createElement("img", { className: `image-circle__image ' image-circle__wrapper--border-${props.colour != null ? props.colour : "grey"}`, src: props.imageURL, alt: props.altText, style: props.size != null ? { width: props.size, height: props.size, } : undefined }))));
    }
    else {
        return (React.createElement("div", { className: wrapperClass },
            React.createElement("img", { className: `image-circle__image ' image-circle__wrapper--border-${props.colour != null ? props.colour : "grey"}`, src: props.imageURL, alt: props.altText, style: props.size != null ? { width: props.size, height: props.size, } : undefined })));
    }
}
export default ImageCircle;
