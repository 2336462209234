import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/53e92c36-6617-4e71-a989-dd739ad32a4d',
        clientId: '2b9d44eb-03a0-485b-86ee-3e0fd5c1bd01',
        //redirectUri: 'https://qa.datainspiredliving.com'
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};
// Authentication Parameters
const authenticationParameters = {
    scopes: ["openid", "profile", "user.read"
    ]
};
// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
};
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
