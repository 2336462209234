import * as React from 'react';
import './styles.css';
import { defaultColors } from '../../const';
import Tippy from '@tippyjs/react';
import logo from './infoLogo.png';
export function IndexTile(props) {
    const [hover, setHover] = React.useState(false);
    if (props.header === true) {
        return (React.createElement("div", { className: "dashboard-risk-score__index-tile dashboard-risk-score__index-tile--header" },
            React.createElement("span", { className: "dashboard-risk-score__index-tile-text dashboard-risk-score__index-tile-text--header", style: { marginTop: 'auto' } }, props.text)));
    }
    else {
        return (React.createElement(Tippy, { content: props.additionalInfo !== undefined ?
                React.createElement("div", { className: "dashboard-risk-score__index-tile dashboard-risk-score__index-tile--toolTip shadow corners", style: {
                        backgroundColor: defaultColors.index
                    } },
                    React.createElement("span", { className: "dashboard-risk-score__index-tile-text dashboard-risk-score__index-tile-text--tooltip" }, props.additionalInfo))
                : null, placement: "right", duration: [0, 20] },
            React.createElement("div", { className: "dashboard-risk-score__index-tile", style: {
                    backgroundColor: (props.missed === true ? defaultColors.indexMissed : defaultColors.index)
                }, onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) },
                React.createElement("span", { className: "dashboard-risk-score__index-tile-text" }, props.text),
                props.additionalInfo !== undefined ?
                    React.createElement("img", { src: logo, className: "dashboard-risk-score__more-info-logo" })
                    : null)));
    }
}
export default IndexTile;
