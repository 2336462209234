import React, { useState } from 'react';
import ReferralForm from './ReferralForm';
import RiskScoreForm from '../settingsPages/riskScore/RiskScore';
import './ReferralForm.css';
export function ReferralFormParent(props) {
    const [pageState, setPageState] = useState(0);
    const [acsisID, setAcsisID] = useState(null);
    const [cohortAllocation, setCohortAllocation] = useState(-1);
    if (pageState == 0) {
        return (React.createElement(ReferralForm, { setACSIS: setAcsisID, setReferralState: setPageState }));
    }
    else if (pageState == 1) {
        if (acsisID) {
            return (React.createElement("div", { className: "referral__riskContainer corners shadow" },
                React.createElement("div", { className: "referral__consentDocument corners" },
                    React.createElement("h3", { className: "referral__consentDocumentText" },
                        "Please complete the risk score form below in order to to complete your referral",
                        React.createElement("br", null),
                        "If you are unable to complete the risk score form now, it can be accessed through the following link: ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("a", null, `https://datainspiredliving/dashboard/${acsisID}/settings/riskScore`))),
                React.createElement(RiskScoreForm, { acsis: acsisID, setReferralState: setPageState, setCohortAllocation: setCohortAllocation })));
        }
        else {
            return null;
        }
    }
    else if (pageState == 2) {
        return (React.createElement("div", { className: "referral__riskContainer corners shadow" },
            React.createElement("div", { className: "referral__consentDocument corners" },
                React.createElement("h3", { className: "referral__consentDocumentText" },
                    " Your referral is complete",
                    React.createElement("br", null),
                    cohortAllocation ? (cohortAllocation === 0 ? "Resident has been placed into the control group" : cohortAllocation === 1 ? "Resident has been placed into the intervention group" : 'Please contact the Assistive Technology team for the outcome of your referral. AssistiveTechnology@hertfordshire.gov.uk') : 'Could not calculate the residents cohort allocation, please contact the Assistive Technology team at AssistiveTechnology@hertfordshire.gov.uk'))));
    }
    else {
        return null;
    }
}
export default ReferralFormParent;
