import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import './ganttIndicator.css';
export function GanttConductor(props) {
    const d3Container = useRef(null);
    let svgWidth = 0;
    let svgHeight = 0;
    let MARGIN = { LEFT: 5, RIGHT: 5, TOP: 5, BOTTOM: 20 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;
    let timeout = false;
    useEffect(() => {
        let delta = 1000;
        function initDraw() {
            // let resizeStart = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(drawGantt, delta);
            }
        }
        initDraw();
        window.addEventListener('resize', initDraw);
        return () => {
            window.removeEventListener('resize', initDraw);
        };
    }, []);
    function drawGantt() {
        if (props.motionData == null || props.motionData.length == 0) {
            return;
        }
        timeout = false;
        const canvas = d3.select(d3Container.current);
        canvas.selectAll("*").remove();
        let dimensions = canvas.node().getBoundingClientRect();
        svgWidth = dimensions.width;
        svgHeight = dimensions.height;
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;
        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
            .attr('id', 'canvas');
        let domainArray = props.domainArray == null ? [] : props.domainArray;
        if (props.domainArray == null) {
            domainArray = [];
            props.motionData.forEach(record => {
                if (!JSON.stringify(domainArray).includes(record.location)) {
                    domainArray.push(record.location);
                }
            });
        }
        let y = d3.scaleBand()
            .domain(domainArray)
            .rangeRound([0, HEIGHT]);
        var x = d3.scaleTime()
            .domain([Date.parse(props.motionData[0].startDateTime), Date.parse(props.motionData[props.motionData.length - 1].endDateTime)])
            .range([0, WIDTH]);
        props.motionData.forEach((d) => {
            svg.append('rect')
                .attr('x', x(Date.parse(d.startDateTime)))
                .attr('y', y(d.location) + ((y.bandwidth() / 2) - ((y.bandwidth() * 0.25)) / 2))
                .attr('width', x(Date.parse(d.endDateTime)) - x(Date.parse(d.startDateTime)))
                .attr('height', y.bandwidth() * 0.25)
                .attr('fill', "#7e7e7e")
                .attr('opacity', 1)
                .attr('stroke-width', 0)
                .attr('stroke', '#707070')
                .attr("class", "ganttBar");
        });
        let xTicks = svg.append('g')
            .attr("transform", `translate(0,${HEIGHT})`)
            .call(d3.axisBottom(x)
            .tickFormat((x) => moment(x).format(handleTickFormat(props.motionData))));
        xTicks.selectAll('text').attr('font-family', "Montserrat-Regular, Montserrat");
        const defaultSelection = [x(Date.parse(props.motionData[0].startDateTime)), x(Date.parse(props.motionData[props.motionData.length - 1].endDateTime))];
        props.setBrushed([x.invert(defaultSelection[0]), x.invert(defaultSelection[1])]);
        const brush = d3.brushX()
            .extent([[MARGIN.LEFT - 5, 0.5], [WIDTH - MARGIN.RIGHT, HEIGHT - 5]])
            .on("brush", brushed)
            .on("end", x => (brushended(x)));
        const gb = svg.append("g")
            .call(brush)
            .call(brush.move, null);
        function brushed({ selection }) {
            if (selection) {
                svg.property("value", selection.map(x.invert, x).map(d3.utcDay.round));
                svg.dispatch("input");
                // props.setBrushed([x.invert(selection[0]), x.invert(selection[1])])
            }
        }
        function brushended({ selection }) {
            if (selection) {
                console.log('Brushed selection');
                props.setBrushed([x.invert(selection[0]), x.invert(selection[1])]);
            }
        }
    }
    useEffect(() => {
        drawGantt();
    }, [props.domainArray, props.motionData]);
    return (React.createElement("svg", { id: "ganttConductor", ref: d3Container }));
}
export default GanttConductor;
function handleTickFormat(motionData) {
    if (!motionData) {
        return 'MMM DD HH:mm';
    }
    let startObj = moment(motionData[0].startDateTime);
    let endObj = moment(motionData[motionData.length - 1].startDateTime);
    let deltaMinutes = endObj.diff(startObj, 'minute');
    if (deltaMinutes < 14440) {
        return 'MM/DD HH:mm';
    }
    else if (deltaMinutes < 14440 * 3) {
        return 'MMM DD';
    }
    else {
        return 'MMM DD';
    }
}
